import { Component, OnInit,EventEmitter,Output, Input,ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IonModal } from '@ionic/angular';
import SwiperCore, { Pagination } from 'swiper';
@Component({
  selector: 'app-imagen-detail',
  templateUrl: './imagen-detail.component.html',
  styleUrls: ['./imagen-detail.component.scss'],
})
export class ImagenDetailComponent implements OnInit,OnDestroy {
  @ViewChild(IonModal) modal: IonModal;
 // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() isModalOpen: Boolean = false;
  @Input() images: any = [];
  @Output() imageSelected = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<boolean>();
  indexImg: null | number = null;


  constructor() { }

  ngOnInit() {

  }

   snapIndexChange(event: any) {
    const activeIndex = event[0].snapIndex;

    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];

      const assetElement = activeSlide.querySelector('audio') ? activeSlide.querySelector('audio') : activeSlide.querySelector('video');

      if (assetElement) {

        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
   }
  ngOnDestroy(): void {

  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
    this.closeModal.emit(false);
  }

}
