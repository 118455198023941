import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoviesService {

  urlServiceFile = environment.API_SERVICE_FILE_LL;
  apiUrl = environment.API_SERVICE_LL_MOVIES;

  constructor(private httpClient: HttpClient) { }

  /**movies service */

  uploadMovie(data) {
    return this.httpClient.post(`${this.urlServiceFile}/upload-movie`, data);
  }

  saveImage(payload) {
    return this.httpClient.post(`${this.urlServiceFile}/upload-image`, payload);
  }

  getMoviesByAuthor(page, size) {
    const params = {
      page, size
    };
    return this.httpClient.get(`${this.apiUrl}/movies/get-movies-by-author`, { params });
  }

  /** TODO */
  getDownloadsMovie() {
    return this.httpClient.get(`${this.apiUrl}/movies/get-movies-downloads-by-user`);
  }

  addDownloadsMovie(movieId, userId) {
    return this.httpClient.post(`${this.apiUrl}/movies/add-movies-downloads-by-user`, { movieId ,userId});
  }
  /** TODO */

  getMoviesByProfile(profileId, page, size) {
    const params = {
      page, size
    };
    return this.httpClient.get(`${this.apiUrl}/movies/get-movies-by-profile/` + profileId, { params });
  }

  getMoviesByUserAccess( page, size) {
    const params = {
      page, size
    };
    return this.httpClient.get(`${this.apiUrl}/movies/get-by-user-access`, { params });
  }

  getMoviesExclusives( page, size) {
    const params = {
      page, size
    };
    return this.httpClient.get(`${this.apiUrl}/movies/get-movies-exclusives`, { params });
  }

  saveMovie(title, description, enabled, price, image, video, ownerId?, wishId?) {
    const payload = { title, description, enabled, price, image, video, ownerId, wishId };
    return this.httpClient.post(`${this.apiUrl}/movies/save-movie`, payload);
  }
  saveVideoBaseData(title, videoUrl, publicid, exclusiveContent, sizeMb) {
    const payload = {
      title, videoUrl, publicid, exclusiveContent, sizeMb
    };
    return this.httpClient.post(`${this.urlServiceFile}/save-video`, payload);
  }
  addNewUserAccess(movieId, userClientId, userSellerId, transferFee) {
    const payload = { movieId, userClientId, userSellerId, transferFee };
    return this.httpClient.put(`${this.apiUrl}/movies/add-new-user-access`, payload);
  }

  updateMovie(movieId, image, price, enabled, title, description) {
    const payload = { movieId, image, price, enabled, title, description };
    return this.httpClient.put(`${this.apiUrl}/movies/update-movie`, payload);
  }

  addNewDownloadPay(userClientId, transferFee) {
    const payload = { userClientId, transferFee };
    return this.httpClient.post(`${this.apiUrl}/movies/add-new-download-pay`, payload);
  }

  deleteMovie(movieId) {
    return this.httpClient.put(`${this.apiUrl}/delete-movie`, { movieId });
  }

  generateLinkDownloadMovie(videoId) {
    return this.httpClient.get(`${this.urlServiceFile}/generate-download-link/${videoId}`);
  }


}
