import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent implements OnInit ,OnDestroy{

  title = 'GENERAL.TERMS_CONDITIONS';
  buttonAcepptTermsConditions = false;

  subShowButton: Subscription;

  constructor(
    private layoutService: LayoutService
  ) { }


  ngOnInit() {
   this.subShowButton = this.layoutService.getShowButtonApTsConds().subscribe((val: any) => {
      this.buttonAcepptTermsConditions = val;
    });
   }


  ngOnDestroy(): void {
    if (this.subShowButton) {
      this.subShowButton.unsubscribe();
     }
  }
  close() {
  this.layoutService.setShowModalInformation(false);
  }

  aceppt() {
  this.layoutService.setAceppteTermsAndConditions(true);
  this.layoutService.setShowModalInformation(false);
  }
  reject() {
  this.layoutService.setAceppteTermsAndConditions(false);
  this.layoutService.setShowModalInformation(false);
  }

}
