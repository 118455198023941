import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuModelsService {
  private env = environment;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private URL = this.env.API_SERVICE_LL_MENU_MODEL;

  constructor(private httpClient: HttpClient) { }

  getMenuService(userId) {
    return this.httpClient.get(this.URL + '/menu-model/services-menu-user/' + userId);
  }
  getExtraMenuService(userId) {
    return this.httpClient.get(this.URL + '/menu-model/extra-menu-user/' + userId);
  }
  setRequestServiceClient(type, description, time, intensity, extras, userClientId, userSellerId, price, isInternalTransaction) {
    // eslint-disable-next-line max-len
    return this.httpClient.post(this.URL + '/menu-model/request-service', { type, description, time, intensity, extras, userClientId, userSellerId, price, isInternalTransaction });
  }

  setRequestCustomVideo(userClientId, userSellerId, title, description, price, time, extras) {
    // eslint-disable-next-line max-len
    return this.httpClient.post(this.URL + '/menu-model/request-custom-video', { userClientId, userSellerId, title, description, price, time, extras });
  }

  setRequestCustomPack(userClientId, userSellerId, title, description, price, quantityMinImages, extras) {
    // eslint-disable-next-line max-len
    return this.httpClient.post(this.URL + '/menu-model/request-custom-pack', { userClientId, userSellerId, title, description, price, quantityMinImages, extras });
  }

  saveExtraMenu(payload) {
      return this.httpClient.post(this.URL + '/menu-model/save-extras-menu',payload);
  }

  saveServiceMenu(payload) {
      return this.httpClient.post(this.URL + '/menu-model/save-service-menu',payload);
  }
  updateExtraMenu(payload) {
      return this.httpClient.put(this.URL + '/menu-model/update-extra-menu',payload);
  }
  updateServiceMenu(payload) {
      return this.httpClient.put(this.URL + '/menu-model/update-service-menu',payload);
  }

}
