import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss'],
})
export class EditImageComponent implements OnInit {
  @Input() imageForEdit: string | ArrayBuffer = ''; // Imagen recibida como Base64 o ArrayBuffer
  @Input() typeImage = ''; // Tipo de imagen (opcional)
  @Input() fromLogin = false; // ¿Viene del flujo de inicio de sesión?
  @Output() imageEdited = new EventEmitter<File | string>(); // Emite la imagen procesada
  @Output() cancelEditImage = new EventEmitter<void>(); // Cancela la operación

  imagePreview: string | null = null; // Vista previa de la imagen
  user: any = '';

  constructor(private authService: AuthServiceService) {

  }

  ngOnInit(): void {
    this.prepareImage(); // Procesar imagen al inicializar
    this.user = JSON.parse(this.authService.getLocalStorage('user'));

  }

  /**
   * Procesa la imagen recibida para generar una vista previa.
   */
  prepareImage(): void {
    if (this.imageForEdit) {
      if (typeof this.imageForEdit === 'string') {
        this.imagePreview = this.imageForEdit;
      } else if (this.imageForEdit instanceof ArrayBuffer) {
        const blob = new Blob([this.imageForEdit]);
        this.imagePreview = URL.createObjectURL(blob);
      } else {
        console.error('Formato de imagen no compatible.');
      }
    } else {
      console.error('No se recibió ninguna imagen para editar.');
    }
  }

  /**
   * Agrega una marca de agua a la imagen procesada.
   */
  async addWatermarkToCanvas(imageUrl: string, userName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (!context) {
          reject('No se pudo obtener el contexto del lienzo.');
          return;
        }

        canvas.width = image.width;
        canvas.height = image.height;

        context.drawImage(image, 0, 0);

        // Tamaño y posición de la marca de agua
        const sizeRelation = Math.ceil(canvas.width / 15);
        const text = `lust-list.com/model/${userName}`;
        context.font = `${Math.ceil(sizeRelation / 2)}px Arial`;
        context.fillStyle = '#8d8d8d';
        context.textBaseline = 'bottom';

        // Medir texto y ajustar posición
        const textWidth = context.measureText(text).width;
        context.fillText(
          text,
          canvas.width - textWidth - 20,
          canvas.height - 20
        );

        const watermark = new Image();
        watermark.src = 'assets/images/watermark.png';
        watermark.onload = () => {
          context.drawImage(watermark, 10, 10, sizeRelation, sizeRelation);
          const imageWithWatermark = canvas.toDataURL('image/jpeg');
          resolve(imageWithWatermark);
        };

        watermark.onerror = () => {
          reject('No se pudo cargar la imagen de marca de agua.');
        };
      };

      image.onerror = () => {
        reject('No se pudo cargar la imagen base.');
      };
    });
  }

  /**
   * Emite la imagen procesada con la marca de agua.
   */
  async uploadImage(): Promise<void> {
    if (this.imagePreview && typeof this.imageForEdit === 'string') {
      try {
        const watermarkedImage = await this.addWatermarkToCanvas(
          this.imageForEdit,
          this.user.userName
        );
        this.imageEdited.emit(watermarkedImage);
      } catch (error) {
        console.error('Error al agregar la marca de agua:', error);
      }
    } else {
      console.error('No hay imagen para procesar o el formato no es compatible.');
    }
  }

  /**
   * Cancela la operación de carga de imagen.
   */
  cancelEdit(): void {
    this.imagePreview = null;
    this.cancelEditImage.emit();
  }
}




  //     async addWatermarkToCanvas(croppedCanvas, userName): Promise<string> {

  //    return new Promise((resolve, reject) => {
  //      const context = croppedCanvas.getContext('2d');
  //      const watermark = new Image();
  //      watermark.src = 'assets/images/watermark.png';

  //      if (!context) {
  //        reject('No se pudo obtener el contexto del lienzo.');
  //        return;
  //      }

  //      watermark.onload = () => {
  //        const sizeRelation = Math.ceil(croppedCanvas.width / 10);
  //        const text = 'lust-list.com/model/' + userName;

  //        context.font = Math.ceil(sizeRelation / 2) + 'px Arial';
  //        const textWidth = context.measureText(text).width;
  //        context.fillStyle = 'rgba(255, 255, 255, 0.5)';
  //        context.textBaseline = 'bottom';
  //        context.fillText(text, croppedCanvas.width - textWidth - 20, croppedCanvas.height - 20);

  //        context.drawImage(watermark, 10, 10, sizeRelation, sizeRelation);

  //        const image = croppedCanvas.toDataURL('image/jpeg');
  //        resolve(image);
  //      };
  //     });
  //  }
