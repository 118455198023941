import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'app-modal-block-eyelashes',
  templateUrl: './modal-block-eyelashes.component.html',
  styleUrls: ['./modal-block-eyelashes.component.scss'],
})
export class ModalBlockEyelashesComponent implements OnInit {

  constructor(private layoutService : LayoutService) { }

  ngOnInit () { }

  close() {
    this.layoutService.setShowModalBlockPop(false);

  }

   evaluteAdsBlock() {
    location.reload();
  }

}
