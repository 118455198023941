import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { FileService } from 'src/app/shared/services/file/file.service';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { MoviesService } from 'src/app/shared/services/movies/movies.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { SocketService } from '../../services/socket/socket.service';



@Component({
  selector: 'app-movie-frame-detail',
  templateUrl: './movie-frame-detail.component.html',
  styleUrls: ['./movie-frame-detail.component.scss'],
})
export class MovieFrameDetailComponent implements OnInit {

  movie: any;
  myId: any;
  movieSelect: any;
  isModalOpen = false;
  messageSubscription: any;
  isDeviceIOS = false;
  fullScreen = false;
  maxDownloads = [];
  downloading = false;
  myFunds = 0;


  constructor(
    private authService: AuthServiceService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private moviesService: MoviesService,
    private accountsService: AccountsService,
    private socketService: SocketService
  ) { }

  ngOnInit() {
    const user = JSON.parse(this.authService.getLocalStorage('user'));
    this.myId = user._id;
    this.isDeviceIOS = this.authService.isDeviceIOS;
    this.layoutService.getDataModalMovieFrameDetail().subscribe((val: any) => {

      this.movie = val;

    });


    this.accountsService.getMyAccount().subscribe(
      (res: any) => this.myFunds = res.amount,
      (error) => console.error(error)
    );
  }

   validateAccesMoview(movie: any) {

    return movie.usersWhitAccess.includes(this.myId) || this.myId === movie.userAuhthor || this.myId === movie.userAuhthor._id;
   }

   openModalBuyVideo(movie) {
    this.movieSelect = movie;
    this.isModalOpen = true;
    this.messageSubscription= {
      message: this.translateService.instant('MODAL.BUY_VIDEO')
    };
   }

    modalMaxDownload(movie) {
    this.layoutService.setShowModalMaxDownloadPay(true);
    this.layoutService.setDataModalMaxDownloadPay(movie);
  }

  onDownload($event) {
    this.downloading = false;
    const payLoad = {
      open: false
    };
    this.layoutService.setDataNotificationDownloadMovie(payLoad);
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO_FINISH', 2500);
  }
  errorDownload($event) {
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO_ERROR', 2500);
  }



  disabledBotton(id) {
    return this.maxDownloads.find(video => video.movieId === id);
  }

   downloadVideo(id) {
    this.downloading = true;
    this.moviesService.addDownloadsMovie(id).subscribe((data: any) => { });
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO', 3500);
  }



  formatDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${hours} hs ${minutes} min ${remainingSeconds} sg`;
    } else if (minutes > 0) {
      return `${minutes} min ${remainingSeconds} sg`;
    } else {
      return `${remainingSeconds} sg`;
    }
  }


  playFullScreen(url) {

    this.toastService.presentToast('TOAST.BUILDING_PREVIEW', 2500);

    const lastVideo = document.getElementById('last-video');
    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    //video.src = this.transforVideoInPreview(url);
    video.src = this.fileService.transformUrlVideo(url, true);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id = 'last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
      video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

    };
  }
  duration(data) {
    const min = Math.round(data);
    return min;
  }
  close() {
this.layoutService.setShowModalMovieFrameDetail(false);
  }


  confirmBuy() {
    const movie = this.movie.movie.find(mov => mov._id == this.movieSelect._id);
    this.moviesService.addNewUserAccess(movie._id, this.myId, movie.userAuhthor, movie.transferFee).subscribe((res: any) => {
      movie.usersWhitAccess = res.usersWhitAccess;
      this.layoutService.setUpdateStatus();
      this.sendNotification(movie, 'NOTIFICATION.BUY_VIDEO');
    });
  }

    sendNotification(movie, message: string) {
    const notification = {
      userReceiver: movie.userAuhthor || movie.userAuhthor._id,
      userSender: this.myId,
      type: 'movie',
      message,
      contextId: movie._id
    };

    this.socketService.sendNotification(notification);
  }
}
