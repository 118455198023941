import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { Subscription } from 'rxjs';
import { UsersService } from '../../services/users/users.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { ToastService } from '../../services/toast/toast.service';
import { WishService } from '../../services/wish/wish.service';
@Component({
  selector: 'app-modal-clients-info',
  templateUrl: './modal-clients-info.component.html',
  styleUrls: ['./modal-clients-info.component.scss'],
})
export class ModalClientsInfoComponent implements OnInit, OnDestroy{
  imageProfile = '';
  data: any;
  transactionLength = '';
  reportLength = '';
  subIdProfile: Subscription;
  id = '';
  metrics: any;

  constructor(
    private layoutService: LayoutService,
    private userService: UsersService,
    private transacctionService: TransactionsService,
    private toastService: ToastService,
    private wishService: WishService
  ) { }

  ngOnInit() {
    this.subIdProfile = this.layoutService.getShowModalDataClientInfo().subscribe((val: any) => {
      this.id = val;
      if (this.id) {
        this.getDataProfile();
        this.getTransactions();
        this.getReportsLenght();
        this.getAllMetricsClient();
      }
    });
  }

  getDataProfile() {
    this.userService.getDataProfile(this.id).subscribe((val: any) => {
    this.data = val;
  });
  }
  getTransactions() {
    this.transacctionService.listTransactionsLength(this.id).subscribe((val: any) => {
    this.transactionLength = val;
  });
  }
  getReportsLenght() {
    this.userService.getReportByProfileLength(this.id).subscribe((val: any) => {
    this.reportLength = val;
  });
  }


  getAllMetricsClient() {
    this.wishService.getAllMetricsByClient(this.id).subscribe((val: any) => {
      this.metrics = val;

    });
  }


  ngOnDestroy(): void {
    if (this.subIdProfile) {
      this.subIdProfile.unsubscribe();
}
  }
  close() {
    this.layoutService.setShowModalClientInfo(false);
  }

  reportProfile() {
    this.userService.report(this.id,'Perfil cliente reportado').subscribe((res) => {
      this.toastService.presentToast('NOTIFICATION.REPORT_PROFILE', 2000);
  this.layoutService.setShowModalClientInfo(false);
    });
  }
}
