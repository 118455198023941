import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { PublicationService } from '../../services/publication/publication.service';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { LayoutService } from '../../services/layout/layout.service';
import { UsersService } from '../../services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from '../../services/socket/socket.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { ToastService } from '../../services/toast/toast.service';
import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-new-state',
  templateUrl: './new-state.component.html',
  styleUrls: ['./new-state.component.scss'],
})
export class NewStateComponent implements OnInit {
  @ViewChild('inputGallery') inputGallery: ElementRef;
  @ViewChild('inputGalleryImage') inputGalleryImage: ElementRef;
  @ViewChild('inputGalleryAudio') inputGalleryAudio: ElementRef;
  /** refactor captura de videos */
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('recordedAudio') recordAudioElementRef: ElementRef;

  formPublication = new FormGroup({
    image: new FormControl(null),
    video: new FormControl(null),
    audio: new FormControl(null),
  });

  isEditImage = false;
  loading: HTMLIonLoadingElement;
  imageForEdit: ArrayBuffer | string = '';
  isOpenOptionsVideo = false;
  isRecording = false;

  recordVideoElement: any;
  recordAudioElement: any;
  isPlayVideo = false;
  isPlayAudio = false;
  videoTemp: File;
  audioTemp: File;
  isDeviceIOS = false
  videoToCrop: File;
  constructor(
    private loadingCtrl: LoadingController,
    private publicationService: PublicationService,
    private layoutService: LayoutService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private socketService: SocketService,
    private authService: AuthServiceService,
    private toastService: ToastService,
  ) { }


  ngOnInit () {
    this.isDeviceIOS = this.authService.isDeviceIOS
  }

  close () {
    this.layoutService.setShowComponentNewState(false);
  }

  uploadImage(image: string) {
    const imageToUpload = image;
    this.showLoading(true);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', file);

      this.publicationService.saveImage(formData).subscribe((res: any) => {
        this.formPublication.get('image').setValue(res._id);
        this.loading.dismiss();
        this.isEditImage = false;
        this.createState();
      },
        (err) => {
          this.loading.dismiss();
        });
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }



  showLoading(isImage: any) {
    const options = {
      message: isImage ? this.translateService.instant('NOTIFICATION.LOADING_IMAGE') : this.translateService.instant('NOTIFICATION.LOADING_VIDEO'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

  takePicture() {
    const options: ImageOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      //webUseInput: true,
      source: CameraSource.Prompt,
      saveToGallery: false,
    };

    Camera.getPhoto(options).then((image) => {
      const imageUrl = image.base64String;
      const file = this.getFileFromBase64(imageUrl, 'test');
      /** read image load */
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageForEdit = reader.result;
        this.isEditImage = true;
      });
      reader.readAsDataURL(file);
      /** read image load */
    });
  }
  /** video functions */

  clickInputGallery() {
    this.isOpenOptionsVideo = false;
    this.inputGallery.nativeElement.click();
  }

  private getFileFromBase64(string64: string, fileName: string): File {
    const imageContent = atob(string64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

  getVideoFromGallery(event: any) {
    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
    this.videoTemp = null;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.videoToCrop = file;
      this.isPlayVideo = true;
      this.inputGallery.nativeElement.value = null;
    }
  }


  getImageFromGallery(event: any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageForEdit = reader.result;
        this.isEditImage = true;
      });
      reader.readAsDataURL(file);
    }
  }

  getAudioFromGallery (event: any) {
    this.recordAudioElement = this.recordAudioElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.recordAudioElement.src = URL.createObjectURL(file);
      this.isPlayAudio = true;
      this.audioTemp = file
    }
  }

  onSubmitPublish(): void {
    this.createState();
  }

  closePlayVideo() {
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
  }

  playVideo(video: File) {
    this.recordVideoElement.src = URL.createObjectURL(video);
    this.isPlayVideo = true;
  }

  deleteVideo() {
    this.videoTemp = null;
    this.videoToCrop = null;
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
  }

  deleteImage() {
    this.isEditImage = false
  }

  SendVideoIdState (videoId) {
    this.formPublication.get('video').setValue(videoId);
    this.createState();
    this.close();
  }



  createState() {
    const end = new Date();
    end.setHours(end.getHours() + 24);
    const state = {
      ...this.formPublication.value,
      start: new Date(),
      end
    };

    this.usersService.createState(state).subscribe(
      res => { 
        //aqui
        this.socketService.newState(res);
        this.layoutService.setShowComponentNewState(null);
        this.toastService.presentToast('GENERAL.NEW_STATE_PUBLISH', 2000);
      },
      err=>console.error(err)
    );
  }

  clickInputGalleryAudio() {
    this.inputGalleryAudio.nativeElement.click();
  }

  clickInputGalleryImage() {
    this.inputGalleryImage.nativeElement.click();
  }

  deleteAudio() {
    this.audioTemp = null;
    this.recordAudioElement.src = '';
    this.isPlayAudio = false;
  }

  uploadAudio() {
    const formData = new FormData();
    formData.append('file', this.audioTemp);
    this.showLoading(3);
    this.publicationService.saveAudio(formData).subscribe((res: any) => {
      this.formPublication.get('audio').setValue(res._id);
      this.loading.dismiss();
      this.createState();
    },
      (err) => {
        this.loading.dismiss();
      });
  }

}
