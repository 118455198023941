import { HttpRequest } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { ProductService } from '../../services/product/product.service';
import { SocketService } from '../../services/socket/socket.service';
import { ToastService } from '../../services/toast/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {

  @Input() products = [];
  productSelected = null;
  myId = '';
  isModalOpen = false;
  dataModalPay = {};


  idAccountClient ='';
  idAccountSeller ='';
  myFunds;
  page=0;

  modalDetailImagen = false;
  imagenDetail = [];

  subscriptionViewAllStatusUser: Subscription;
  subscriptionListenerNewStates: Subscription;
  usersAllViewStates = [];

  constructor(
    private authService: AuthServiceService,
    private router: Router,
    private accountsService: AccountsService,
    private productService: ProductService,
    private toastService: ToastService,
    private layoutService: LayoutService,
    private socket: SocketService,
    private translateService: TranslateService) { }


  ngOnInit() {

    const user = this.authService.getLocalStorage('user');
    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    this.idAccountClient = userParsed.accountId;
    this.accountsService.getMyAccount().subscribe(
      (res: any) => (this.myFunds = res.amount),
      (error) => console.error(error)
    );

    this.subscriptionViewAllStatusUser = this.layoutService.getNewUserViewAllStates().subscribe((usersAllViewStates: string[]) => {
      this.usersAllViewStates = usersAllViewStates;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptionViewAllStatusUser) {
      this.subscriptionViewAllStatusUser.unsubscribe();
    }
    if (this.subscriptionListenerNewStates) {
      this.subscriptionListenerNewStates.unsubscribe();
    }
  }

  evaluateStates(product) {
    const states = product.states;
    let state = null;
    if(states){
    if (states?.length == 0 || product.userId._id == this.myId){
      state = 'noState';
    } else if (states.every((state) => state.seen.includes(this.myId)) || this.usersAllViewStates.includes(product.userId._id)) {
      state ='allView';
    }
      return state;
      }
  }

  openStateModal(states) {
    this.layoutService.setShowStateModal(states);
  }

  loadData(event){
    this.page++;
    this.productService.getAllProducts(this.page).subscribe(
      (res: any) => {
        this.products = [...this.products, ...res];
        event.target.complete();
      },
      (err) => console.log(err)
    );
  }

  buyProduct(product: any) {
    if (product.userId._id == this.myId) {
      return;
    }

    this.productSelected = product;
    this.isModalOpen = true;

    this.accountsService.getUserAccount(this.productSelected.userId._id
    ).subscribe(
      (res: any) => {

        this.idAccountSeller = res._id;
      },
      (error) => {
        console.error(error);
      }
    );

    this.dataModalPay = {
      title: 'GENERAL.BUY',
      message: this.translateService.instant('MODAL.PRICE')
    };
  }
  modalDetail(index) {
    this.imagenDetail = [];
    this.imagenDetail.push(this.products[index].images[0].imageUrl);
    this.modalDetailImagen = !this.modalDetailImagen;

  }


  pay() {
    this.isModalOpen = false;
      const payload = {
        idAccountSeller: this.idAccountSeller,
        idAccountClient: this.idAccountClient,
        aprovedClient: true,
        aprovedSeller: true,
        amount: this.productSelected.price,
        product: this.productSelected._id,
        type: 3,
        quantity: this.productSelected.stock,
        sellerId: this.productSelected.userId,
      };

    const total = this.productSelected.price;

      if (total <= this.myFunds) {

        this.productService.buyProduct(this.productSelected._id, payload).subscribe(
          (res: any) => {
            const userSellerId = res.sellerId;
            const billingId = res._id;
            this.productService
              .removeQuantityFromStock(this.productSelected._id, {
                quantity: payload.quantity,
              })
              .subscribe(
                () => {
                  const req = {
                    url: this.router.url.substring(0, 12),
                    method: 'POST',
                  } as HttpRequest<string>;
                  this.toastService.presentToastMiddleCheck(req);
                  this.sendNotification(userSellerId, this.myId, billingId);
                  this.isModalOpen = false;
                  const component: DataComponent = {
                    component: 'my-products',
                    contextId: '',
                    reload: true,
                    action: 'close'
                  };
                  this.layoutService.setComponent(component);
                },
                (error: any) => console.error(error)
              );
          },
          (error) => console.error(error)
        );
      } else {
        this.toastService.presentToast('Saldo insuficiente', 3000);
      }

  }



  /**
   * Send a notification when ocurred and action
   *
   * @param userReceiver indicates the user to receive the notification
   * @param userSender indicates the user to send the notification
   */
  sendNotification(userReceiver: string, userSender: string, billingId: string) {
    const notification = {
      userReceiver,
      userSender,
      type: 'product',
      contextId: billingId,
      message: 'NOTIFICATION.YOU_PURCHASED_A_PRODUCT'
    };
    this.socket.sendNotification(notification);
  }

}
