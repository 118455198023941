import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private env = environment;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private URL = this.env.API_SERVICE_LL_TICKET;

  constructor(private httpClient: HttpClient) { }

  getTickets(page, size, byClient: boolean) {
    const params = {
      page, size, byClient
    };

    return this.httpClient.get(this.URL + '/ticket/', { params });
  }

  getTicketDetail(id) {
    return this.httpClient.get(this.URL+ '/ticket/' + id);
  }

  saveTicket(userClientId, userSellerId, services, discount, total) {
    return this.httpClient.post(this.URL+ '/ticket/save-ticket', { userClientId, userSellerId, services, discount, total });
  }

  updateTicket(id, services, discount, total) {
    return this.httpClient.put(this.URL+ '/ticket/update-ticket', { id, services, discount, total });
  }

  finish(id, acepted) {
    return this.httpClient.put(this.URL+ '/ticket/finish', { id, acepted });
  }

  payTicket(id) {
    return this.httpClient.put(this.URL+ '/ticket/pay', { id });
  }

   payTicketExternal(id) {
    return this.httpClient.put(this.URL+ '/ticket/ticket-pay-external', { id });
  }
}
