import { Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { debounceTime } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { ProductService } from '../../services/product/product.service';

@Component({
  selector: 'app-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.scss'],
})
export class MyProductsComponent implements OnInit, OnChanges {

  @Input() reload = null;

  myProducts = [];
  allMyProducts = [];
  allMyVentas = [];
  allMyCompras = [];
  billings = [];
  list = 'productos';

  myId = '';
  page = 0;

  constructor(
    private productService: ProductService,
    private authService: AuthServiceService,
    private renderer: Renderer2,
    private router: Router,
    private layoutService: LayoutService,
    private menu: MenuController,
  ) { }

  ngOnInit() {
    const myUser = this.authService.getLocalStorage('user');
    const myUserParsed = JSON.parse(myUser);
    this.myId = myUserParsed._id;
    if (this.getUserType() !== '2') {
      this.productService.getProducts(this.myId, false, 0).subscribe(
        (res: any[]) => {
          this.myProducts = this.formatProducts(res);
          this.allMyProducts = this.formatProducts(res);
        },
        err => console.log(err)
      );
    } else {
      this.productService.getBuyerBillings(0).subscribe(
        (res: any[]) => {
          this.list = 'compras';
          this.myProducts = this.formatBillingProducts(res);
          this.allMyProducts = this.formatBillingProducts(res);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reload.currentValue) {
      this.page = 0;
      if (this.getUserType() !== '2') {
        this.productService.getProducts(this.myId, false, 0).subscribe(
          (res: any[]) => {
            this.myProducts = this.formatProducts(res);
            this.allMyProducts = this.formatProducts(res);
          },
          err => console.log(err)
        );
      } else {
        this.productService.getBuyerBillings(0).subscribe(
          (res: any[]) => {
            this.myProducts = this.formatBillingProducts(res);
            this.allMyProducts = this.formatBillingProducts(res);
          },
          (error) => {
            console.error(error);
          }
        );
      }
    }
  }

  closeRight() {
    this.menu.close('menu2');
  }

  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  formatProducts(res: any[]) {
    return res.map(item => ({
        product: item,
        image: item.images[0]
      }));
  }

  formatBillingProducts(res: any[]) {
    return res.map(item => ({
        billingId: item._id,
        product: item.products[0],
        image: item.products[0].images[0],
        userClientId: item.userId,
        userSellerId: item.sellerId
      }));
  }

  formatBillingProductsSearch(res: any[]) {
    return res.map(item => ({
        billingId: item._id,
        product: item.products[0],
        image: item.images[0],
      }));
  }

  selectProducts() {
    this.page = 0;
    this.list = 'productos';
    this.myProducts = this.allMyProducts;
  }

  selectVentas() {
    this.page = 0;
    this.productService.getSellerBillings(0).subscribe(
      (res: any[]) => {
        this.list = 'ventas';
        this.myProducts = this.formatBillingProducts(res);
        this.allMyVentas = this.formatBillingProducts(res);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  selectCompras() {
    this.page = 0;
    this.productService.getBuyerBillings(0).subscribe(
      (res: any[]) => {
        this.list = 'compras';
        this.myProducts = this.formatBillingProducts(res);
        this.allMyCompras = this.formatBillingProducts(res);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  loadData(event){
    this.page++;
    if (this.list === 'productos') {
      this.productService.getProducts(this.myId, false, this.page).subscribe(
        (res: any[]) => {
          this.myProducts = [...this.myProducts, ...this.formatProducts(res)];
          //this.allMyProducts = [...this.allMyProducts, ...this.formatProducts(res)]
          event.target.complete();
        },
        err => console.log(err)
      );
    }
    if (this.list === 'ventas') {
      this.productService.getSellerBillings(this.page).subscribe(
        (res: any[]) => {
          this.myProducts = [...this.myProducts, ...this.formatBillingProducts(res)];
          //this.allMyVentas = [...this.allMyVentas, ...this.formatBillingProducts(res)]
          event.target.complete();
        },
        (error) => {
          console.error(error);
        }
      );
    }
    if (this.list === 'compras') {
      this.productService.getBuyerBillings(this.page).subscribe(
        (res: any[]) => {
          this.myProducts = [...this.myProducts, ...this.formatBillingProducts(res)];
          //this.allMyCompras = [...this.allMyCompras, ...this.formatBillingProducts(res)]
          event.target.complete();
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  onIntersection(
    {
      target,
      visible,
    }: {
      target: HTMLElement;
      visible: boolean;
    },
    product?: any
  ): void {
    if (visible) {
      let urlCover = target.dataset.src;
      if (product.product && product.product.images[0] && product.product.images[0].imageUrl) {
        urlCover = product.product.images[0].imageUrl;
      } else {
        urlCover = product.image.imageUrl;
      }
      if (urlCover) {
        this.renderer.setStyle(
          target,
          'background-image',
          `url(${urlCover})`
        );
      }
    } else {
      this.renderer.removeStyle(target, 'background-image');
    }
  }

  onSearch(e: any) {
    const searchbar = e.target as HTMLInputElement;
    if (searchbar.value) {
      this.searchItem(searchbar.value);
    } else {
      this.onClearSearch();
    }
  }

  searchItem(item) {
    if (this.list === 'productos') {
      this.productService
        .searchProducts(item)
        .pipe(debounceTime(500))
        .subscribe((res: any) => {
          this.myProducts = this.formatProducts(res);
        });
    }
    if (this.list === 'ventas') {
      this.productService
        .searchSellerBillings(item)
        .pipe(debounceTime(500))
        .subscribe((res: any) => {
          this.myProducts = this.formatBillingProductsSearch(res);

        });
    }
    if (this.list === 'compras') {
      this.productService
        .searchBuyerBillings(item)
        .pipe(debounceTime(500))
        .subscribe((res: any) => {
          this.myProducts = this.formatBillingProductsSearch(res);
        });
    }
  }

  onClearSearch() {
    if (this.list === 'productos') {
      this.myProducts = this.allMyProducts;
    }
    if (this.list === 'ventas') {
      this.myProducts = this.allMyVentas;
    }
    if (this.list === 'compras') {
      this.myProducts = this.allMyCompras;
    }
  }

  editProduct(item) {

    if (this.list === 'productos') {

      const component: DataComponent = { component: 'my-products', contextId: item.product._id, reload: false, action: 'open' };
      this.layoutService.setComponent(component);
    }

    if (this.list === 'ventas' || this.list === 'compras') {
      const component = item.billingId;
      this.layoutService.setShowModelBillingDetail(true);
      this.layoutService.setDataBillingDetail(component);
     }

    //this.closeRight()
  }
}
