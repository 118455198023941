export enum ServiceType {
  squirt ='Squirt',
  dildo = 'Dildo',
  vibrator = 'Vibrator',
  dirty_talk = 'Dirty talk',
  spitting = 'Spitting',
  edging = 'Edging',
  feet = 'Feet',
  worshiping = 'Worshiping',
  spanking = 'Spanking',
  submission = 'Submission',
  role_plays = 'Role Plays',
  pooping = 'Pooping',
  peeing = 'Peeing',
  bondage = 'Bondage',
  anal = 'Anal',
  cuckolding = 'Cuckolding',
  sph = 'SPH',
  joi = 'JOI',
  bg_or_gg = 'B/G or G/G'
}