import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  ElementRef

} from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { PublicationService } from '../../services/publication/publication.service';
import { UsersService } from '../../services/users/users.service';
import SwiperCore, { Pagination } from 'swiper';
import { LayoutService } from '../../services/layout/layout.service';
import { ToastService } from '../../services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { HttpRequest } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../services/file/file.service';
import { Subscription } from 'rxjs';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-frame-total-screen',
  templateUrl: './frame-total-screen.component.html',
  styleUrls: ['./frame-total-screen.component.scss'],
})
export class FrameTotalScreenComponent implements OnInit, OnChanges, OnDestroy {
  @Input() image: any = {};
  @Input() isWishDetail = false;
  @Output() closeFrame = new EventEmitter<any>();
  @Output() likeStatus = new EventEmitter<any>();
  @Output() openComments = new EventEmitter<any>();
  @Output() imageDeleted = new EventEmitter<string>();
  @Output() openEditPublication = new EventEmitter<any>();
  @ViewChild('gallery') gallery: ElementRef;


  detailImage = false;

  maxHeight = 0;

  myId = '';
  userId = '';
  priceForSubscription = 0;
  sharedSubscription = 0;

  frameExplorer = false;

  moreOptionOpen = false;
  confirmDeleteOpen = false;
  showPub = false;
  modalOptionsImage = false;
  messageSubscription = {};

  isModalOpen = false;
  isModalReport = false;
  typeReport = '';
  indexImg: null | number = null;
  linkRedirect: string;
  copyActive = false;
  sharePublication = false;

  wishDetailImageProfile = '';
  wishDetailUserName = '';
   isDeviceIOS = false;
  fullScreen = false;
  activeUsers: any = [];
  activeUserSubscription: Subscription;
  susbscriptionDataImageFrame: Subscription;
  aspectoRatio = 16 / 9;
  widthMax = 300;

  private env = environment;

  constructor(
    private router: Router,
    private authService: AuthServiceService,
    private usersService: UsersService,
    private publicationService: PublicationService,
    private layoutService: LayoutService,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private fileService: FileService

  ) {}

  ngOnInit() {
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const user = this.authService.getLocalStorage('user');
    if (window.innerWidth > 1020) {
      this.widthMax = 265;
    }

    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    if (userParsed.contentManagerId) {
      this.myId = userParsed.contentManagerId;
    }
    this.layoutService.getShowModalFrameDetail().subscribe((val) => {
      this.detailImage = val;
    });
    if (this.detailImage) {
      this.setDataImageModal();
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.activeUserSubscription = this.usersService.getUsersActive().subscribe((user) => {
      this.activeUsers = user;
    });
  }

  isActive(id: string) {
    return this.activeUsers.find(userActive => userActive.user === id)?.active;
  }

  setDataImageModal() {
    this.susbscriptionDataImageFrame = this.layoutService.getDataFrameImage().subscribe((data) => {
      const urlCompleta = window.location.href;
      // eslint-disable-next-line max-len
      if (urlCompleta.includes('explorer') || urlCompleta.includes('profile') || urlCompleta.includes('home') || urlCompleta.includes('lust-list')) {
        if (data.userId !== this.myId) {
          this.usersService.registerViewPublication(this.myId, data.userId, data._id).subscribe();
        }
      }


      if (data.explorer) {
        this.frameExplorer= data.explorer;
        this.userId  = data.userId;
      } else {
        this.userId = data.userId?._id ? data.userId?._id : data?.userAuhthorId?._id;
         }
        if (data._id) {
          data.id = data?._id;
          delete data?._id;
        }
      this.image = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image.currentValue) {
      this.getMeta(this.image.url);
      if (this.image.userId) {
        this.userId = this.image.userId._id ? this.image.userId._id : this.image.userId;
        this.usersService
          .getQuotizationSubscription(this.userId)
          .subscribe((res: any) => {
            this.priceForSubscription = res.subscriptionPrice;
            this.sharedSubscription = res.share;
            this.messageSubscription = {
              title: 'Subscribirte',
              message:
                `El precio de la subscripcion a este generador de contenido al mes ${this.priceForSubscription}$ ` +
                `lo correspondiente a lo que correspondiente a pagar por el mes en curso seria ${this.sharedSubscription}`,
            };
          });
        if (this.isWishDetail) {

            this.wishDetailImageProfile = this.image?.userId?.imageProfile?.imageUrl;
            this.wishDetailUserName = this.image?.userId?.userName;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.image = {};
    if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
    if (this.susbscriptionDataImageFrame) {
      this.susbscriptionDataImageFrame.unsubscribe();
    }
  }

  showComment() {
    this.openComments.emit(true);
    if (this.detailImage) {
      this.layoutService.setShowModalCommentFrame(true);
      this.layoutService.setDataCommentFrame(this.image);
    }
  }
  close() {
    this.image = null;
    this.closeFrame.emit(false);
    this.layoutService.setShowModalFrameDetail(false);
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }

  getMeta(url: string) {
    const img = new Image();
    img.src = url;
    img.onload = () => (this.maxHeight = img.height);
  }

  setFavoriteStatus(image: any) {
    this.likeStatus.emit(image);
  }

  navigateTo() {
    this.router.navigate(['/profile/', this.userId]);
    this.layoutService.setShowModalFrameDetail(false);
    if (this.image.userId !== this.myId) {
       this.usersService.registerViewProfileByPublication(this.myId, this.userId, this.image._id).subscribe();
    }
  }

  /**
   * Abre el modal
   */
  onOpenModal() {
    this.moreOptionOpen = true;
  }

  // openModalOffert(publication) {
  //   const offerData = {
  //     userClientId: this.myId,
  //     userSellerId: publication?.userId,
  //     publication,
  //     amount: 0,
  //     comments: []
  //   };
  //   this.layoutService.setDataNewOffer(offerData);
  //   this.layoutService.setShowNewOffer(true);
  //   this.close();
  // }

  /**
   * Envia por output la imagen seleccionada
   *
   * @param image La imagen donde va a cambiar el estado de like
   */
  changeFavoriteStatus(image) {
    if (image.publicationLikes.includes(this.myId)) {
      this.publicationService.dislike(image.id).subscribe((res: any) => {
        image.likes = res?.likes;
        image.publicationLikes = res?.publicationLikes?.usersId;
      });
    } else {
      this.publicationService.like(image.id).subscribe((res: any) => {
        image.likes = res?.likes;
        image.publicationLikes = res?.publicationLikes?.usersId;
      });
    }
  }


   playFullScreenIos(url) {

    const lastVideo = document.getElementById('last-video');

    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    video.src = this.fileService.transformUrlVideo(url);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id='last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
     video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

     };
  }


  editPublication() {
    if (this.image) {
      const data = { publication: this.image };
      this.layoutService.setDataWishComponentNewPublication(data);
      this.layoutService.setShowComponentNewPublication(true);
      this.layoutService.setShowModalFrameDetail(false);
      this.layoutService.setDataTitleAddPublication('PUBLICATION.EDIT_PUBLICATION');
      this.layoutService.setDataEditPublication('edit');

    }
  }

  /**
   * Elimina la publicacion y cierra el modal
   */
  deletePublication() {
    this.closeModal();
    this.deletePublications(this.image.id);
  }
    /**
     * Abre el modal para confirmar si desea eliminar
     */
  onConfirmDelete() {
  this.moreOptionOpen = false;
   this.confirmDeleteOpen = true;
  }

    deletePublications(id: string) {
      this.publicationService.deletePublication(id).subscribe(() => {
        this.layoutService.setIdDeleteImage(id);
        this.layoutService.setActiveDeleteImage(true);
        this.layoutService.setShowModalFrameDetail(false);
      });
  }

  openModalMetrics(publication) {
    this.publicationService.getMetricsPublication(publication.id).subscribe((val: any) => {
      const data = {
        from: 'gallery-uniform',
        data: val
      };
      this.layoutService.setShowModalMetrics(true);
      this.layoutService.setDataModalMetrics(data);
    });
  }

  /**
   * Cierra completamente los modales para ver más opciones y confirmar el eliminar publicacion
   */
  closeModal() {
    this.confirmDeleteOpen = false;
    this.moreOptionOpen = false;
  }

  isOneImage(image) {

    return image?.images?.length === 1 && image?.videos?.length === 0;
  }

  openModalOptions() {
    this.modalOptionsImage = !this.modalOptionsImage;
  }

  openModalReportPublication() {
    this.modalOptionsImage=false;
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'report-publication';
  }
 getImageVideo(video) {
    return video.videoUrl.replace('webm', 'webp').replace('mp4', 'webp').replace('mov', 'webp');
  }


  transformVideo(video) {
    if (video) {
      const data = this.fileService.transformUrlVideo(video.videoUrl);
    return data;
    }
  }

  getWidthGallery(): number {
    return this.gallery?.nativeElement?.offsetWidth ? this.gallery?.nativeElement?.offsetWidth : this.widthMax;
  }

  openModalHiddenPublication() {
    this.modalOptionsImage=false;
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'hidden-publication';

  }

    getSafeLink(url): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

   confirmModal() {
    if (this.isModalReport)  {
      switch (this.typeReport) {
        case 'report-publication':
          this.reportPublication();
          break;
        case 'hidden-publication':
          this.hiddenPublication();
          break;

        default:
          break;
      }
    }
   }


  showModalCopy() {
    this.modalOptionsImage=false;
    this.sharePublication = true;
    this.linkRedirect = this.env.urlFront + 'preview/' + this.image.id;
    }

    closeSharedPublicationModal() {
    this.sharePublication = false;
    this.moreOptionOpen = false;
  }

    reportPublication() {
    this.usersService.report(this.userId, 'undefined', this.image.id).subscribe((res) => {

      this.toastService.presentToast('NOTIFICATION.REPORT_PUBLICATION', 2000);

      this.moreOptionOpen = false;
      this.isModalOpen = false;
      setTimeout(() => {
        this.openModalHiddenPublication();
      }, 500);
    });
    }


  hiddenPublication() {
    this.usersService.excludePublication(this.image.id).subscribe((res) => {

      this.toastService.presentToast('NOTIFICATION.HIDDEN_PUBLICATION', 2000);

      this.moreOptionOpen = false;
      this.isModalOpen = false;
      this.isModalReport = false;
    });
  }

    copySharedProfile(value) {
    this.copyActive = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
     this.toastService.presentToast('NOTIFICATION.COPIED_CLIPBOARD', 2000);
  }


  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  getImageAudio(publication) {

    let image = publication.userId
      .imageProfile.imageUrl;

    if (publication.images.length > 0) {
      image = publication.images[0].imageUrl;
    }

    if (publication.videos.length > 0) {
      image = publication.videos[0].videoUrl.replace(/\.[^/.]+$/, '.webp');
    }

    return image;
  }


  snapIndexChange(event: any) {
    const activeIndex = event[0].snapIndex;

    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];

      const assetElement = activeSlide?.querySelector('audio') ? activeSlide.querySelector('audio') : activeSlide?.querySelector('video');

      if (assetElement) {

        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
  }
}
