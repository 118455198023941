import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { LayoutService } from '../../services/layout/layout.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';
import { computeShrinkWidth } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-modal-payment-subscription-plataform',
  templateUrl: './modal-payment-subscription-plataform.component.html',
  styleUrls: ['./modal-payment-subscription-plataform.component.scss'],
})
export class ModalPaymentSubscriptionPlataformComponent implements OnInit, OnDestroy {

  title = 'Version Pro';
  paySub = null;
  subscriptionDataModalPaymentSubsPlataform: Subscription;
  isModel = false;
  welcome = false;
  linkNowPayment: string = null;
  priceSubPlataform = null;
  constructor(
    private layoutService: LayoutService,
    private userService: UsersService,
    private transactionService: TransactionsService,
    private authService: AuthServiceService,
    private router: Router,


  ) {  }


  ngOnInit() {
    this.userService.getPriceSubByPlataformLustList().subscribe(res => {
      this.priceSubPlataform = res[0].amount;
    });
    this.subscriptionDataModalPaymentSubsPlataform = this.layoutService.getDataModalPaymentSubsPlataform().subscribe((val: any) => {
      if (val?.welcome) {
        this.welcome = val.welcome;
      }
      if (val?.paySub) {
      this.paySub=val.paySub;
      }
      if (val?.type === 'client') {
       this.title = '';
      }
      if (val?.type === 'model') {
        this.isModel = true;
       this.title = '';
     }
    });

  }

  ngOnDestroy(): void {
    if (this.subscriptionDataModalPaymentSubsPlataform) {
      this.subscriptionDataModalPaymentSubsPlataform.unsubscribe();
    }
  }
  close() {
    if (this.welcome) {
      this.authService.removeLocalStorage('welcome');
    }
    this.layoutService.setShowModalPaymentSubsPlataform(false);

  }
  noClose() {
    this.layoutService.setShowModalPaymentSubsPlataform(true);
  }

  paySubscriptionByFounds() {
    this.userService.subscribeToPlatformByInternatFound().subscribe((res) => {
      this.layoutService.setShowModalPaymentSubsPlataform(false);
     });
  }

  // paySubscriptionByInvoiceCripto() {
  //   this.transactionService.generateInvoiceCriptoPaymentToSubscriptionPayment().subscribe((res: any) => {
  //     this.linkNowPayment = res.invoice_url;
  //     window.open(res.invoice_url, '_blank');
  //   });
  // }


  goto() {
    this.router.navigate(['lust-list']);
    this.close();
  }


  goToDeposit() {
    this.router.navigate(['deposit']);
    this.close();
  }
}
