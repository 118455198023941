import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MoviesService } from '../../services/movies/movies.service';
import { ToastService } from '../../services/toast/toast.service';
import { PublicationService } from '../../services/publication/publication.service';

@Component({
  selector: 'app-uploap-large-video-cd',
  templateUrl: './uploap-large-video-cd.component.html',
  styleUrls: ['./uploap-large-video-cd.component.scss'],
})
export class UploapLargeVideoCdComponent implements OnInit {

  @ViewChild('inputGallery') inputGallery: ElementRef;
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @Output() videoPreview = new EventEmitter<any>();
  @Output() movieSaved = new EventEmitter<any>();
  recordVideoElement: any;
  isPlayVideo = false;
  videoTemp: File;
  sectionsToUpload = 12;

  env = environment;
  uploadView = false;

  progressValue = 0;
  isShortVideo = false;



  constructor(private toastService: ToastService, private moviesService: MoviesService, private publicationService: PublicationService) { }

  ngOnInit() {}

  processFile(): void {
    // Set your cloud name and unsigned upload preset here:
    const CLOUD_NAME = this.env.CLOUD_NAME;
    const CLOUD_UPLOAD_PRESET = this.env.CLOUD_UPLOAD_PRESET;

    const POST_URL =
      'https://api.cloudinary.com/v1_1/' + CLOUD_NAME + '/auto/upload';

    const XUniqueUploadId = String(+new Date());
    this.uploadView = true;
    const file = this.videoTemp;
    this.deleteVideo();
    const self = this;

    processFileChunks(file);

    function processFileChunks(file: Blob): void {
      const size = file.size;
      const sliceSize = 6000000;
      let start = 0;

      loop();

      function loop(): void {
        let end = start + sliceSize;

        if (end > size) {
          end = size;
        }
        const s = slice(file, start, end);
        send(s, start, end - 1, size);
        if (end < size) {
          start += sliceSize;
          setTimeout(loop, 3);
        }
      }
    }

    function send(
      piece: Blob,
      start: number,
      end: number,
      size: number
    ): void {

      const formdata = new FormData();
      formdata.append('file', piece);
      formdata.append('cloud_name', CLOUD_NAME);
      formdata.append('upload_preset', CLOUD_UPLOAD_PRESET);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', POST_URL, false);
      xhr.setRequestHeader('X-Unique-Upload-Id', XUniqueUploadId);
      xhr.setRequestHeader(
        'Content-Range',
        'bytes ' + start + '-' + end + '/' + size
      );

      xhr.onload =  ()  => {
        setData(xhr.responseText);
      };

      function setData(responseText) {

        self.updateProgress();
        const data = JSON.parse(responseText);
        if (data.done && data.secure_url) {
          self.uploadVideo(data, file);
        }
      }


      xhr.send(formdata);
    }

    function noop(): void { }

    function slice(file: Blob, start: number, end: number): Blob {
      const sliceFunc =
        (file as any).mozSlice ||
        (file as any).webkitSlice ||
        (file as any).slice ||
        noop;

      return sliceFunc.call(file, start, end);
    }
  }

  deleteVideo() {
    this.videoTemp = null;
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
  }

  clickInputGallery() {
    this.toastService.presentToast('NOTIFICATION.ONLY_FORMAT_MP4', 3000);
    this.inputGallery.nativeElement.click();
  }

  getVideoFromGallery(event: any) {
    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const inputElement = event.target as HTMLInputElement;
      const file = event.target.files[0];
      const fileSizeInBytes = file.size;
      const sliceSize = 6000000;
      this.sectionsToUpload = Math.ceil(fileSizeInBytes / sliceSize);

      if (fileSizeInBytes < 20000000) {
        this.isShortVideo = true;
      }

      const maxSizeInBytes = 1000 * 1024 * 1024;
      if (fileSizeInBytes > maxSizeInBytes) {
        this.toastService.presentToast('PUBLICATION.TOAS_MAX_HEIGHT_LAST_VIDEO', 3000);
        inputElement.value = '';
      } else {
        this.recordVideoElement.src = URL.createObjectURL(file);
        this.recordVideoElement.onloadedmetadata = () => {
          const durationInSeconds = this.recordVideoElement.duration;
          const minDurationInSeconds = 60;

          if (durationInSeconds < minDurationInSeconds) {
            this.toastService.presentToast('PUBLICATION.TOAS_MIN_LARGE_VIDEO', 3000);

            inputElement.value = '';
          } else {
            this.isPlayVideo = true;
            this.videoTemp = file;
          }
        };

      }

    }
  }

  uploadShortVideo(videoTemp) {

    const formData = new FormData();
    formData.append('file', videoTemp);

    this.publicationService.saveVideo(formData).subscribe((res) => {
      this.movieSaved.emit(res);
      this.videoPreview.emit(videoTemp);
      this.toastService.presentToast('PUBLICATION.VIDEO_SAVED_SUCCESSFULLY', 2000);
    });
    this.deleteVideo();
    this.uploadView = false;
  }

  uploadVideo(videoData, video) {

    this.moviesService.uploadMovie(videoData).subscribe((res) => {
      this.movieSaved.emit(res);
      this.videoPreview.emit(video);
      this.toastService.presentToast('PUBLICATION.VIDEO_SAVED_SUCCESSFULLY', 2000);
    });
    this.deleteVideo();
    this.uploadView = false;

  }

  updateProgress() {
    const fractionAdd = Math.ceil(100 / this.sectionsToUpload);
    this.progressValue = this.progressValue + fractionAdd;

    if (this.progressValue > 100) {
      this.progressValue = 100;
    }

    if (this.progressValue == 100) {
      setTimeout(() => {
        this.uploadView = false;
        this.toastService.presentToast('PUBLICATION.VIDEO_SAVED_SERVER', 2000);
      }, 2000);
    }

  }


}
