import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../services/users/users.service';
import { LayoutService } from '../../services/layout/layout.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';

@Component({
  selector: 'app-attributes-modal',
  templateUrl: './attributes-modal.component.html',
  styleUrls: ['./attributes-modal.component.scss'],
})
export class AttributesModalComponent implements OnInit, OnChanges {


  @Input() modalType = '';
  form = new FormGroup({
    gender: new FormControl(''),
    piel: new FormControl(''),
  });

  myUser: any = '';

  constructor(
    private usersService: UsersService,
    private layoutService: LayoutService,
    private authService: AuthServiceService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modalType.currentValue) {
      const user = JSON.parse(this.authService.getLocalStorage('user'));
      if (changes.modalType.currentValue === 'preferences' && user.preferences) {
        for (const [key, value] of Object.entries(user.preferences)) {
          this.form.get(key).setValue(value);
        }
      }
      if (changes.modalType.currentValue === 'attributes' && user.attributes) {
        for (const [key, value] of Object.entries(user.attributes)) {
          this.form.get(key).setValue(value);
        }
      }
    }
  }

  ngOnInit() {
    this.getDataProfile();

  }

  close() {
    this.layoutService.setShowComponentAttributes(false, '');
  }

  getDataProfile() {
    const user = this.authService.getLocalStorage('user');
    this.myUser = JSON.parse(user);
  }

  updateAttributes() {
    this.usersService
      .updateUser({ attributes: this.formatFormValue() })
      .subscribe((res: any) => {

        this.close();
      });
  }

  updatePreferences() {
    this.usersService
      .updateUser({ preferences: this.formatFormValue() })
      .subscribe((res: any) => {
        this.close();
      });
  }

  formatFormValue() {
    const formValue = {};
    for (const [key, value] of Object.entries(this.form.value)) {
      if (value) {
        formValue[key] = value;
      }
    }
    return formValue;
  }

}
