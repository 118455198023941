import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private env = environment;
  private url: string = this.env.API_SERVICE_LL_NOTIFICATION + '/notifications';

  constructor(private httpClient: HttpClient) { }

  getNotifications(page?: number, size?: number) {
    let params = {};

    if (page != undefined && size != undefined) {
      params = {
        page,
        size
      };
    }
    return this.httpClient.get(this.url, { params });
  }

  createNotification(payload: any) {
    return this.httpClient.post(this.url, payload);
  }

  updateNotification(payload: any) {
    return this.httpClient.put(this.url + '/read', payload);
  }
}
