import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

export interface Message {
  chatId?: string;
  userId?: string;
  message?: string;
  roomId?: string;
  imageUrl?: string;
  videoUrl?: string;
  audioUrl?: string;
  createdAt?: string;
  stateRef?: any;
  wishRef?: any;
  offertRef?: any;
  billingRef?: any;
  stopSexting?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  messageServer: any;
  playSound = false;

  constructor(private socket: Socket) { }

  public receiverNotifcations(userId: string) {
    return this.socket.emit('notifications', userId);
  }

  public sendNotification(data: any) {
    return this.socket.emit('new-notification', data);
  }

  public sendNotificationMultiple(data: any) {
    return this.socket.emit('new-multiple-notification', data);
  }

  public getNewNotifications() {
    return new Observable((observer) => {
      this.socket.on('new-notification', (notification) => {
        //this.playSoundNotification.next(true);

        this.playSoundNotification();

        observer.next(notification);
      });
    });
  }

  public getNewMultipleNotifications() {
    return new Observable((observer) => {
      this.socket.on('new-multiple-notification', (notification) => {
        observer.next(notification);
      });
    });
  }


  public createRoomChat(roomId: string) {
    return this.socket.emit('join', roomId);
  }

  public sendMessage(message: Message) {
    return this.socket.emit('new-message', message);
  }

  public sendMessageAdmin(message: Message) {
    return this.socket.emit('new-message-admin', message);
  }

  public getMessage() {
    return new Observable((observer) => {
      this.socket.on('new-message', (message) => {
        observer.next(message);
      });
    });
  }

  public getMessageAdmin() {
    return new Observable((observer) => {
      this.socket.on('new-message-admin', (message) => {
        observer.next(message);
      });
    });
  }

  //list chats
  public getNewMessagesByUser() {
    return new Observable((observer) => {
      this.socket.on('new-message-user', (message) => {
        observer.next(message);
      });
    });
  }

  // Meting
  public callStart(channel, calling, ready?, time?) {
    return this.socket.emit('call-start', { channel, calling, ready, time });
  }

  public listenerCallStart(channel: string) {
    return new Observable((observer) => {
      this.socket.on('call-start', (information) => {
        if (information.channel === channel) {
          observer.next(information);
        }
      });
    });
  }

  public userIntoRoom(channel, userId, userInRoom) {
    return this.socket.emit('user-into-room', { channel, userId, userInRoom });
  }

  public listenerUserIntoRoom(channel: string) {
    return new Observable((observer) => {
      this.socket.on('user-into-room', (information) => {
        if (information.channel === channel) {
          observer.next(information);
        }
      });
    });
  }

  public getUserActives() {
    return new Observable((observer) => {
      this.socket.on('user-actives', (users) => {
        observer.next(users);
      });
    });
  }

  public newState(data: any) {
    return this.socket.emit('new-state', data);
  }

  public listenerNewStatesMySubscriptions() {
    return new Observable((observer) => {
      this.socket.on('new-state', (state) => {
        observer.next(state);
      });
    });
  }

  playSoundNotification() {
    if (this.playSound === false) {
      const audioPlayer: any = document.getElementById('notification-sound');
      audioPlayer.play();
      this.playSound = true;
      setTimeout(() => {
        this.playSound = false;
      }, 1000);
    }
  }
}
