import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly serverUrl = environment.API_SERVICE_LL_AUTH;

  constructor(
    private toastController: ToastController,
    private translate: TranslateService
  ) {}

  /**
   * Send a toast
   *
   * @params msg Message
   * @params duration Duration of toast
   */
  async presentToast(msg: string, duration: number) {
    const toast = await this.toastController.create({
      message: this.translate.instant(msg),
      duration,
      position: 'bottom',
      cssClass: 'custom-toast check-toast',
    });

    await toast.present();
  }

  async presentToastMiddleError(msg: string, duration: number) {
    const toast = await this.toastController.create({
      message: this.translate.instant(msg),
      duration,
      position: 'bottom',
      icon: 'close',
      cssClass: 'custom-toast error-toast',
    });

    await toast.present();
  }

  async presentToastMiddleSendInformation(request: HttpRequest<any>) {
    const msg = this.selectMessageToastForToastRequest(
      request.url,
      request.method
    );

    if (msg) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 1000,
        position: 'bottom',
        icon: 'time-outline',
        cssClass: 'custom-toast send-toast',
      });
      await toast.present();
    }
  }

  async presentToastMiddleCheck(request: HttpRequest<string>) {
    const msg = this.selectMessageToastForToastCheck(
      request.url,
      request.method
    );
    if (msg) {
      const toast = await this.toastController.create({
        message: msg,
        duration: 1500,
        position: 'bottom',
        icon: 'checkmark-circle-outline',
        cssClass: 'custom-toast check-toast',
      });

      await toast.present();
    }
  }

  private selectMessageToastForToastRequest(url: string, method: string) {
    let message: string;

    switch (method) {
      case 'POST':
        switch (url) {
          case this.serverUrl + '/auth/login':
            message = this.translate.instant('TOAST.SEND.LOGIN');
            break;

          default:
            break;
        }

        break;
      case 'PUT':
        break;
      case 'DELETE':
        break;
      default:
        break;
    }
    return message;
  }

  /**
   * Selecciona un mensaje para el toast segun el método y la url
   *
   * @param url dirección donde ocurre la acción
   * @param method metodo POST, PUT or DELETE
   * @returns el mensaje seleccionado para el toast
   */
  private selectMessageToastForToastCheck(url: string, method: string): string {
    let message: string;

    if (method === 'POST') {
      const messageMap = new Map<string, string>([
        [
          `${this.serverUrl}/auth/login`,
          this.translate.instant('TOAST.CHECK.LOGIN'),
        ],
        [
          `/auth/login/code`,
          this.translate.instant('TOAST.CHECK.AUTH.RECOVERY_PASSWORD'),
        ],
        [`/chats-list`, this.translate.instant('TOAST.CHECK.WISH.CREATE')],
        [
          `/profile/publication`,
          this.translate.instant('TOAST.CHECK.PUBLICATION.CREATE'),
        ],
        [`/folder`, this.translate.instant('TOAST.CHECK.PUBLICATION.CREATE')],
        [`/buy-product`, this.translate.instant('TOAST.CHECK.PRODUCT.CREATE')],
      ]);
      message = messageMap.get(url);
    }

    if (method === 'PUT') {
      const messageMap = new Map<string, string>([
        [
          `/auth/login/recovery`,
          this.translate.instant('TOAST.CHECK.AUTH.UPDATED_PASSWORD'),
        ],
        [
          `/profile/publication`,
          this.translate.instant('TOAST.CHECK.PUBLICATION.UPDATE'),
        ],
        [
          `/profile/subscribe`,
          this.translate.instant('TOAST.CHECK.SUBSCRIBE.UPDATE'),
        ],
        [`/folder`, this.translate.instant('TOAST.CHECK.PUBLICATION.UPDATE')],
        [`/buy-product`, this.translate.instant('TOAST.CHECK.PRODUCT.UPDATE')],
      ]);
      message = messageMap.get(url);
    }

    if (method === 'DELETE') {
      const messageMap = new Map<string, string>([
        [
          `/profile/publication`,
          this.translate.instant('TOAST.CHECK.PUBLICATION.DELETE'),
        ],
        [`/folder`, this.translate.instant('TOAST.CHECK.PUBLICATION.DELETE')],
        [`/buy-product`, this.translate.instant('TOAST.CHECK.PRODUCT.DELETE')],
      ]);
      message = messageMap.get(url);
    }
    return message;
  }
}
