import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

/**Clase servicio para crear metodos para formatos de fechas */
export class DateService {
  private dateNow = new Date();
  constructor(private translate: TranslateService) {}

  /**
   * Envia un date con formato de publicacion
   *
   * @param dateCreatedAt Fecha en que fue creado el elemento
   * @returns la fecha del elemento en formato "Se ha publicado hace..."
   */
 getDatePublish(dateCreatedAt: Date | string): string {
  this.dateNow = new Date();
  const date = new Date(dateCreatedAt);

  const differenceMin = this.getDifferenceMinute(date);
  const differenceHour = this.getDifferenceHour(date);
  const differenceDay = this.getDifferenceDay(date);

  const publishTranslate = this.translate.instant('PUBLISHED.VALUE');
  const secondsTranslate = this.translate
    .instant('PUBLISHED.SECONDS')
    .toLocaleLowerCase();
  let createdAt = `${publishTranslate} ${secondsTranslate}`;

  if (differenceMin > 0 && differenceMin < 60) {
    const minTranslate = this.translate
      .instant('PUBLISHED.MINUTE')
      .toLocaleLowerCase();
    const minsTranslate = this.translate
      .instant('PUBLISHED.MINUTES')
      .toLocaleLowerCase();
    const min =
      differenceMin === 1
        ? `${differenceMin} ${minTranslate}`
        : `${differenceMin} ${minsTranslate}`;
    createdAt = `${publishTranslate} ${min}`;
  }

  if (differenceHour > 0 && differenceHour < 24) {
    const hourTranslate = this.translate
      .instant('PUBLISHED.HOUR')
      .toLocaleLowerCase();
    const hoursTranslate = this.translate
      .instant('PUBLISHED.HOURS')
      .toLocaleLowerCase();
    const hour =
      differenceHour === 1
        ? `${differenceHour} ${hourTranslate}`
        : `${differenceHour} ${hoursTranslate}`;
    createdAt = `${publishTranslate} ${hour}`;
  }

  if (differenceDay > 0 && differenceDay < 7) {
    const dayTranslate = this.translate
      .instant('PUBLISHED.DAY')
      .toLocaleLowerCase();
    const daysTranslate = this.translate
      .instant('PUBLISHED.DAYS')
      .toLocaleLowerCase();
    const days =
      differenceDay === 1
        ? `${differenceDay} ${dayTranslate}`
        : `${differenceDay} ${daysTranslate}`;
    createdAt = `${publishTranslate} ${days}`;
  }

  if (differenceDay >= 7) {
    const day = String(date.getDate()); // Cambiado getDay a getDate
    const month = String(date.getMonth() + 1);
    const year = String(date.getFullYear());
    const dateTranslate = this.translate.instant('PUBLISHED.DATE');
    createdAt = `${dateTranslate} ${day}/${month}/${year}`;
  }

  return createdAt.toString();
}

 //ultima conexion
    getDatelastConection(dateCreatedAt: Date | string): string {
    this.dateNow = new Date();
    const date = new Date(dateCreatedAt);

    const differenceMin = this.getDifferenceMinute(date);
    const differenceHour = this.getDifferenceHour(date);
    const differenceDay = this.getDifferenceDay(date);

    const publishTranslate = this.translate.instant('PUBLISHED.CONEC');
    const secondsTranslate = this.translate
      .instant('PUBLISHED.SECONDS')
      .toLocaleLowerCase();
    let createdAt = `${publishTranslate} ${secondsTranslate}`;

    if (differenceMin > 0 && differenceMin < 60) {
      const minTranslate = this.translate
        .instant('PUBLISHED.MINUTE')
        .toLocaleLowerCase();
      const minsTranslate = this.translate
        .instant('PUBLISHED.MINUTES')
        .toLocaleLowerCase();
      const min =
        differenceMin === 1
          ? `${differenceMin} ${minTranslate}`
          : `${differenceMin} ${minsTranslate}`;
      createdAt = `${publishTranslate} ${min}`;
    }

    if (differenceHour > 0 && differenceHour < 24) {
      const hourTranslate = this.translate
        .instant('PUBLISHED.HOUR')
        .toLocaleLowerCase();
      const hoursTranslate = this.translate
        .instant('PUBLISHED.HOURS')
        .toLocaleLowerCase();
      const hour =
        differenceHour === 1
          ? `${differenceHour} ${hourTranslate}`
          : `${differenceHour} ${hoursTranslate}`;
      createdAt = `${publishTranslate} ${hour}`;
    }

    if (differenceDay > 0 && differenceDay < 7) {
      const dayTranslate = this.translate
        .instant('PUBLISHED.DAY')
        .toLocaleLowerCase();
      const daysTranslate = this.translate
        .instant('PUBLISHED.DAYS')
        .toLocaleLowerCase();
      const days =
        differenceDay === 1
          ? `${differenceDay} ${dayTranslate}`
          : `${differenceDay} ${daysTranslate}`;
      createdAt = `${publishTranslate} ${days}`;
    }

    if (differenceDay >= 7) {
      const day = String(date.getDay());
      const month = String(date.getMonth() + 1);
      const year = String(date.getFullYear());
      const dateTranslate = this.translate.instant('PUBLISHED.DATE');
      createdAt = `${dateTranslate} ${day}/${month}/${year}`;
    }

    return createdAt.toString();
  }

  /**
   *  Obtiene la diferencia entre dos fechas
   *
   * @param date1 fecha de creación
   * @param date2 fecha actual
   * @returns la diferencia en dias
   */
  getDifferenceDay(date: Date): number {
    const difference = this.dateNow.getTime() - date.getTime();
    // 1000 = MS // 60 = S // 60 = h // 24 = D
    return Math.round(difference / (1000 * 60 * 60 * 24));
  }

  /**
   * Obtiene la diferencia entre dos horas
   *
   * @param date1 fecha de creación
   * @param date2 fecha actual
   * @returns
   */
  getDifferenceHour(date: Date): number {
    const difference = this.dateNow.getTime() - date.getTime();
    // 1000 = MS // 3600 = 60*60 = Seg*Min
    return Math.round(difference / (1000 * 3600));
  }

  /**
   * Obtiene la diferencia entre dos minutos
   *
   * @param date1 fecha de creación
   * @param date2 fecha actual
   * @returns
   */
  getDifferenceMinute(date: Date): number {
    const difference = this.dateNow.getTime() - date.getTime();
    // 1000 = MS // 60 = S
    return Math.abs(Math.floor(difference / (1000 * 60)));
  }





  formatDateToCustomString(dateString) {
  const today = new Date();
  const date = new Date(dateString);

  // Función para formatear una fecha como 'dd/mm/yy'
  const formatDate = (d) => {
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  // Comprobar si la fecha es hoy
  if (date.toDateString() === today.toDateString()) {
    return  'PUBLISHED.TODAY';;
  }

  // Comprobar si la fecha es ayer
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  if (date.toDateString() === yesterday.toDateString()) {
    return 'PUBLISHED.YESTERDAY';
  }

  // Comprobar si la fecha es posterior a una semana
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);
  if (date > oneWeekAgo) {
    const daysOfWeek = ['WEEK.SUNDAY', 'WEEK.MONDAY', 'WEEK.TUESDAY', 'WEEK.WEDNESDAY', 'WEEK.THURSDAY', 'WEEK.FRIDAY', 'WEEK.SATURDAY'];
    return daysOfWeek[date.getDay()];
  }

  // Si ninguna de las condiciones anteriores se cumple, formatear la fecha como 'dd/mm/yy'
  return formatDate(date);
}
}
