import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {  Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import {
  LayoutService,
} from '../../services/layout/layout.service';

import { PublicationService } from '../../services/publication/publication.service';

import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { debounceTime } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { Publication } from 'src/app/core/models/publication.model';
;
import { Subscription } from 'rxjs';

import { UsersService } from '../../services/users/users.service';

import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
})
export class HeaderNotificationsComponent implements OnInit {
  @Input() title = 'Home';
  @Input() iconHeader = '../../../../assets/icon/Icons-Lust - Home Menu-03.svg';
  @Input() showCreatePub = true;
  @Input() styleChange = false;
  @Input() isExplorer = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onShowEditProduct = new EventEmitter();
  @Output() sendSearchByExplorer = new EventEmitter();


  notifications: any[] = [];
  isOpenNotification = false;
  unreadNotification: Notification[] = [];
  userIconNotification = 'assets/icon/usuario-30.png';
  userIcon = 'assets/icon/usuario-30.png';
  isUserVisitor = true;

  publicationsSearched = [];
  usersSearched = [];
  isSearchBarEmpty = true;
  subscriptionsNotifications: Subscription;
  subscriptionsNotificationsMultiple: Subscription;

  showBtnFloat= false;
  iconFloat: string;

  showRoutMarket = '';

  page = 0;
  size = 10;
  user: any;

  typeUser = '';

  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private authService: AuthServiceService,
    private menu: MenuController,
    private publicationService: PublicationService,
    private userService: UsersService,
    private toastService: ToastService,
  ) {}


  ngOnInit() {
    const userTypeNumber = this.authService.getLocalStorage('userType');
    this.user = JSON.parse(this.authService.getLocalStorage('user')) ;
    if (userTypeNumber && Number(userTypeNumber) === 1) {
      this.isUserVisitor = false;
    }
    this.showRoutMarket = this.router.url;
    this.typeUser = this.userService.getMyTypeUser();
    if (this.typeUser === '1' && this.showRoutMarket === '/products-list') {
      this.showBtnFloat = true;
      this.iconFloat = '../../../../assets/icon/Icons-Lust - NewProduct Profile Negative.svg';
    }
    if (this.typeUser === '1' && this.showRoutMarket !== '/products-list') {
       this.showBtnFloat = true;
      this.iconFloat = 'assets/icon/Icons-Lust - New post_Mesa de trabajo 1.svg';
    }
  }







    navigateTo(path: string, id?: string) {

    if (id) {
      this.router.navigate([`/${path}/${id}`]);
    } else {
      this.router.navigate([`/${path}`]);
    }
  }

  showNewProduct() {
    this.onShowEditProduct.emit(true);
  }

  openLeft() {
    this.menu.open('menu');
  }

  openRight() {
    this.menu.open('menu2');
  }

  setShowComponentNewPublication() {
    this.layoutService.setDataTitleAddPublication('PUBLICATION.NEW_PUBLICATION');
    this.layoutService.setShowComponentNewPublication(true);

    this.publicationService.getAvailableNewPubllicaction().subscribe((res: any) => {
      if (!res) {
        this.toastService.presentToast('TOAST.CHECK.PUBLICATION.NO_AVAILABLE', 3000);
        this.layoutService.setShowComponentNewPublication(false);
      }
    });
  }








  showPreferences() {
    this.layoutService.setShowComponentAttributes(true, 'preferences');
  }




  onSearch(event: Event) {
    const searchbar = event.target as HTMLInputElement;


    if (this.isExplorer) {
      this.sendSearchByExplorer.emit(searchbar.value);
    } else {
      if (searchbar.value) {
        this.searchItem(searchbar.value);
      } else {
        this.onClearSearch();
      }
    }
  }

  searchItem(item: any) {
    this.isSearchBarEmpty = false;
    this.publicationService
      .searchPublication(item)
      .pipe(debounceTime(500))
      .subscribe((res: any) => {
        this.searchPublication(res);
        this.searchUser(res);
      });
  }

  searchPublication(publications: Publication[]) {
    this.publicationsSearched = publications
      .filter(
        (publication) =>
          publication.hasOwnProperty('title') && publication.images?.length
      )
      .map((publication) => ({
        ...publication,
        imageUrl: publication.images[0].imageUrl,
        userId: {
          ...publication.userAuhthorId,
          name: publication.userAuhthorId.name,
        },
      }));
  }

  searchUser(users: User[]) {
    this.usersSearched = users
      .filter((user) => user.hasOwnProperty('name'))
      .map((user) => ({
        ...user,
        url: !user.imageProfile ? [this.userIcon] : user.imageProfile.imageUrl,
      }));
  }

  onClearSearch() {
    this.publicationsSearched = [];
    this.usersSearched = [];
    this.isSearchBarEmpty = true;
  }




  actionBtnFloat() {
    if (this.typeUser === '1' && this.showRoutMarket !== '/products-list') {
      this.setShowComponentNewPublication();
    }
    if (this.typeUser === '1' && this.showRoutMarket === '/products-list') {
      this.showNewProduct();
    }
  }

}
