import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerifyGuardGuard } from './guard/verify-guard.guard';


const routes: Routes = [
    {
    path: '',
    redirectTo: 'verify',
    pathMatch: 'full'
  },
  {
    path: 'verify',
    loadChildren: () => import('./init/init.module').then( m => m.InitPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canLoad: [VerifyGuardGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canLoad: [VerifyGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
