import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { SwiperComponent } from 'swiper/angular';
import { UsersService } from '../../services/users/users.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import {
  SocketService,
  Message,
} from 'src/app/shared/services/socket/socket.service';
import { ChatsService } from '../../services/chats/chats.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-state-modal',
  templateUrl: './state-modal.component.html',
  styleUrls: ['./state-modal.component.scss'],
})
export class StateModalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('swiper') swiper?: SwiperComponent;
  @Input() statesId = [];

  private videosActive = new BehaviorSubject<string[]>([]);
  private videosActive$ =
    this.videosActive.asObservable();

  stateIndex = 0;

  mylikes = [];
  myid = '';
  idRoom = '';
  userId = '';
  userImage;
  states = [];
  progressBarClass = '';
  progressValue: number[] = [];
  statesCount = 1;
  intervalId: any;
  indexBar = 0;
  advanceState = true;
  sequencesOpenStates: [][] = [];
  indexSequencesOpenStates = 0;
  subscriptionSequencesOpenStates: Subscription;
  actuallyVideoUrl = '';
  videoPlayId;
  isDeviceIOS = false;
  videosActiveSubscription: Subscription;
  height: string;


  subscriptionPlatform = false;
  subscriptionSubscriptionPlatform: Subscription;
  constructor(
    private layoutService: LayoutService,
    private usersService: UsersService,
    private authService: AuthServiceService,
    private socket: SocketService,
    private chatService: ChatsService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.myid = this.authService.getLocalStorage('userId');
  }

  ngOnDestroy(): void {
    if (this.subscriptionSequencesOpenStates) {
       this.subscriptionSequencesOpenStates.unsubscribe();
    }
    if (this.videosActiveSubscription) {
       this.videosActiveSubscription.unsubscribe();
    }

    if (this.subscriptionSubscriptionPlatform) {
      this.subscriptionSubscriptionPlatform.unsubscribe();
    }

  }

  ngOnInit() {
    this.height = this.calculateHeight();
    this.isDeviceIOS = this.authService.isDeviceIOS;
    this.subscriptionSequencesOpenStates = this.layoutService.getStateOpeningSequence().subscribe((sequencesOpenStates) => {
      this.sequencesOpenStates = sequencesOpenStates;
      this.indexSequencesOpenStates = 0;
    });

    this.subscriptionSubscriptionPlatform = this.layoutService.getSubscriptionPlatform().subscribe((val) => {
      this.subscriptionPlatform = val;
    });

   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.statesId.currentValue.length > 0) {
      this.initModalState(changes.statesId.currentValue);
    }
  }


  initModalState(states) {
    const statesId = states.map(state => state._id);
    this.usersService.getStatesByIds(statesId).subscribe((res: any[]) => {
      this.states = res;
      this.userId = this.states[0].userId._id;

      this.initialiceProgressBar();
      this.progressBarClass = `progress-bar-${this.states.length}`;
      this.chatService.getChat(this.userId, 0, 1).subscribe((res: any) => {
        this.idRoom = res._id;
      });
    });
  }

  initialiceProgressBar() {
    this.progressValue = new Array(this.states.length).fill(0);
    const indexStateNoView = this.states.findIndex((element) => !element.seen.includes(this.myid));

    this.indexBar = indexStateNoView === -1 ? 0 : indexStateNoView;
    const isLast = this.indexBar == (this.states.length - 1);
    this.seeState(this.states[this.indexBar], isLast);
    this.selectState(this.indexBar);
  }

  iLike(state) {
    return state.likes.usersId.includes(this.myid);
  }

  closeStates() {
    this.layoutService.setShowStateModal([]);
    this.layoutService.setStateOpeningSequence([]);
    this.videosActive.next([]);
    if (this.videosActiveSubscription) {
      this.videosActiveSubscription.unsubscribe();
    }
    clearInterval(this.intervalId);
  }

  calculateHeight() {
    return 'height: ' + window.innerHeight + 'px';
  }

  startProgress(duration): void {
    duration = duration * 1000;

    this.intervalId = setInterval(() => {
      this.changeDetectorRef.detectChanges();
      if (this.progressValue[this.indexBar] < 101) {
        if (this.advanceState) {
          this.progressValue[this.indexBar]++;

        }
      } else {
        if (this.isDeviceIOS && this.states[this.indexBar].video) {
          return;
        }
        if (this.indexBar < this.progressValue.length - 1) {
          this.indexBar++;
          this.selectState(this.indexBar);
        } else {
          this.stopInterval();
        }
      }

    }, duration /100);
  }

  stopAdvanceState() {
    this.advanceState = false;
    if (this.videoPlayId) {
      const video: any = document.getElementById(this.videoPlayId);
      video.pause();
    }
  }

  startAdvanceState() {
    this.advanceState = true;
    if (this.videoPlayId) {
      const video: any = document.getElementById(this.videoPlayId);
      video.play();
    }
  }

  stopInterval() {
    clearInterval(this.intervalId);

    if (this.sequencesOpenStates.length > 0) {
      const statesForView = this.sequencesOpenStates[this.indexSequencesOpenStates];
      if (statesForView) {
        this.initModalState(statesForView);
        this.indexSequencesOpenStates++;
      } else {
        this.closeStates();
      }

    } else {
      this.closeStates();
    }
  }

  onSlideChange(e) {
    this.selectState(e[0].activeIndex);
  }



  selectState(index) {

    if (this.swiper && this.swiper.swiperRef && this.swiper.speed) {

      if (this.videosActiveSubscription) {
        this.videosActiveSubscription.unsubscribe();
      }

      this.indexBar = index;
      this.swiper?.swiperRef?.slideTo(index);
      const isLast = index == (this.states.length - 1);
      for (let i = 0; i < this.progressValue.length; i++) {
        this.progressValue[i] = (i < index) ? 100 : 0;
      }
      this.changeDetectorRef.detectChanges();

      this.seeState(this.states[this.stateIndex], isLast);

      if (this.videoPlayId) {
        const video: any = document.getElementById(this.videoPlayId);
        video.pause();
        video.currentTime = 0;
      }

      if (this.states[index].image) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
        this.startProgress(10);
      }

      if (this.states[index].video) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }

        this.advanceState = false;

        if (this.videosActive.getValue().includes(this.videoPlayId) && this.videoPlayId != this.states[index].video._id) {
          this.videoPlayId = this.states[index].video._id;
          const duration = this.states[index].video.duration;
          const video: any = document.getElementById(this.videoPlayId);
          if (video && !this.isDeviceIOS) {
            video.play();

            this.startProgress(duration);
            this.advanceState = true;
          }
        } else {
          this.videoPlayId = this.states[index].video._id;
          this.videosActiveSubscription = this.videosActive$.subscribe((videosActives: any[]) => {
            if (videosActives.includes(this.videoPlayId) && videosActives[videosActives.length - 1] == this.videoPlayId) {
              const duration = this.states[index].video.duration;
              const video: any = document.getElementById(this.videoPlayId);
              if (video && !this.isDeviceIOS) {

                video.play();
                this.startProgress(duration);
                this.advanceState = true;
              }
            }
          });
        }
      }

      if (this.states[index].audio) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
        this.startProgress(5);
      }

    }
  }

  activateVideos() {

  }

  videoLoad(event, videoId) {
    const videos = this.videosActive.getValue();
    videos.push(videoId);
    this.videosActive.next(videos);
  }

  like(state){
    this.usersService.likeState(state._id).subscribe(
      res => {
        state.likes = res[0].likes;
      }, err => console.error(err)
    );
  }

  dislike(state){
    this.usersService.dislikeState(state._id).subscribe(
      res => {
        state.likes = res[0].likes;
      }, err => console.error(err)
    );
  }

  calculateWidthProgresBar() {
    let statesQty = this.states.length;
    statesQty == 0 ? statesQty = 1 : statesQty;
    const percent = 100 / statesQty;
    this.progressBarClass = `width: ${percent}%`;

  }

  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  send(msg: any, state): void {
    if (!this.subscriptionPlatform && this.getUserType() === '2') {
      this.closeStates();
      const data = {
        type: 'client'
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
      this.layoutService.setShowModalPaymentSubsPlataform(true);

      return;
    }
    const newMessage: Message = {
      chatId: this.idRoom,
      message: msg.value,
      roomId: this.idRoom,
      userId: this.myid,
      stateRef: state._id
    };
    msg.value = '';
    this.socket.sendMessage(newMessage);
    this.sendNotification('message');
  }

  sendNotification(type: string): void {
    const notification = {
      userReceiver: this.userId,
      userSender: this.myid,
      type,
    };
    this.socket.sendNotification(notification);
  }

  goToProfile() {
    this.closeStates();
    this.router.navigate(['/profile/' + this.userId]);
  }

  deleteState(state) {
    this.usersService.deleteState(state._id).subscribe(res => {
      this.closeStates();
    });
  }

  openModalWish(userAuthorId) {
    this.stopAdvanceState();
    const wishData = {
      userId: userAuthorId._id ? userAuthorId._id : userAuthorId
    };
    this.layoutService.setDataWishComponentNewWish(wishData);
    this.layoutService.setShowComponentNewWish(true);
  }

  seeState(state, isLast?: boolean) {
    if (isLast) {
      this.layoutService.setNewUserViewAllStates(state.userId
        ._id);
    }

    if (this.myid !== this.userId) {
      this.usersService.seeState(state._id).subscribe();
    }
  }

  transformUrlVideoStates(value, resolution?) {

    if (value.endsWith('.mp4') || value.endsWith('.webm') || value.endsWith('.ogg')) {

      value = this.transformCloudinaryVideoUrl(value, resolution);
      if (this.authService.isDeviceIOS) {
        if (value.includes('.webm')) {
          return value.replace('.webm', '.mp4');
        }
        return value;
      } else {
        return value.replace('.mp4', '.webm').replace('.ogg', '.webm');
      }
    } else {

      return value;
    }
  }

  transformCloudinaryVideoUrl(inputUrl, resolution?) {

    let transformation;
    if (window.outerWidth > 640) {
      transformation = `ar_${resolution ?resolution : '16:9'},c_fill,q_auto,w_500`;
    } else {
      transformation = `ar_${resolution ?resolution : '16:9'},c_fill,q_auto,w_400`;
    }
    const parts = inputUrl.split('/upload/');

    if (parts.length === 2) {
      const transformedUrl = `${parts[0]}/upload/${transformation}/${parts[1]}`;
      return transformedUrl.replace('.mp4', '.webm');
    } else {
      return inputUrl;
    }
  }

}
