import { AdsService } from 'src/app/shared/services/ads/ads.service';
import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'app-modal-block-ads',
  templateUrl: './modal-block-ads.component.html',
  styleUrls: ['./modal-block-ads.component.scss'],
})
export class ModalBlockAdsComponent implements OnInit {

  constructor(private layoutService: LayoutService , private adsService: AdsService ) { }

  ngOnInit() { }

  close() {
    this.layoutService.setShowModalBlockAds(false);

  }

  evaluteAdsBlock () {
    location.reload()
  }

}
