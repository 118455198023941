import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SocketService } from '../../services/socket/socket.service';
import { WishService } from '../../services/wish/wish.service';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { MenuController } from '@ionic/angular';
import { ToastService } from '../../services/toast/toast.service';
import { FileService } from '../../services/file/file.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wish-detail',
  templateUrl: './wish-detail.component.html',
  styleUrls: ['./wish-detail.component.scss'],
})
export class WishDetailComponent implements OnInit, OnChanges {
  @Input() wishId: string | any = '';
  @Input() isClient = false;
  @Output() openState = new EventEmitter<boolean>();
  @Output() changeStatusWish = new EventEmitter();

  comentaryOfSeller = new FormControl('');
  comentaryOfClient = new FormControl('');
  wish: any;

  publicationSelected: any = '';
  movieSelected: any = '';
  myId = '';
  frameDetail =false;

  myIsClient = false;
  isDeviceIOS = false;
  isMobile = false;
  fullScreen = false;

  openModalQualify = false;
  isWishReview = false;

  formQualify = new FormGroup({
    userClientId: new FormControl(''),
    userSellerId: new FormControl(''),
    wish: new FormControl(''),
    rating: new FormControl(1),
    comentary: new FormControl('')
  });
  constructor(
    private wishService: WishService,
    private socket: SocketService,
    private layoutService: LayoutService,
    private authService: AuthServiceService,
    private menu: MenuController,
    private toastService: ToastService,
    private fileService: FileService,
    private router: Router

  ) {}

  ngOnInit() {
    if (window.outerWidth < 640) {
      this.isMobile = true;
    }
    this.isDeviceIOS = this.authService.isDeviceIOS;
    this.myId = this.authService.getLocalStorage('userId');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes;
    if (change.wishId) {
      this.getWish();
    }
  }


  //agregando lo iconos al header

  iconWish(icon: any) {
    let response = 'assets/icon/nuevodeseov2.svg';
    switch (icon) {
      case 1:
        response = 'assets/icon/nuevodeseov2.svg';
        break;
      case 2:
        response = 'assets/icon/deseoaceptado.svg';
        break;
      case 3:
        response = 'assets/icon/deseorechazado.svg';
        break;
      case 4:
        response = 'assets/icon/deseosatifeschov2.svg';
        break;
      case 5:
        response = 'assets/icon/deseorechazado.svg';
        break;
         case 6:
        response = 'assets/icon/deseoconcedido.svg';
        break;
      case 7:
        response = 'assets/icon/deseorechazado.svg';
        break;
    }
    return response;
  }



  userWish(user: string) {
    let response: string;
    switch (user) {
      case 'Seller':
        response = 'WISHES.DETAIL.USER.SELLER';
        break;
      case 'Client':
        response = 'WISHES.DETAIL.USER.CLIENT';
        break;
      case 'Admin':
        response = 'WISHES.DETAIL.USER.ADMIN';
        break;

      default:
        response = 'WISHES.DETAIL.USER.SELLER';
        break;
    }
    return response;
  }

  getWish() {
    this.wishService.getWish(this.wishId).subscribe((wish: any) => {
      if (wish?.status?.status == 4 || wish?.status?.status == 5) {
        this.wishService.getReviewByWish(this.wishId).subscribe((response: any) => {
          if (response) {
            this.isWishReview = true;
          }
        });
      }
      this.wish = wish;
      this.myIsClient = this.myId === this.wish.userClientId._id;
    });
  }

  close() {
    this.openState.emit(false);
  }

  closeModalWishDetail() {
    this.layoutService.setShowModalWishDetail(false);
  }

  deny() {
    const payload = {
      wishId: this.wish._id,
      accept: false,
      comment: this.comentaryOfSeller.value,
    };
    this.wishService.acceptOrRejectWish(payload).subscribe((res) => {
      this.getWish();
      const message = 'NOTIFICATION.YOUR_WISH_DENIED';
      this.sendNotification(this.wish.userClientId, this.wish.userSellerId, message);
      this.layoutService.setUpdateStatus();
    });
  }

  openComponentAddPublication(wishId, ownerId, quantityMinImages?) {
    const wishNewPublication = true;
    let data = { wishId, ownerId, quantityMinImages, wishNewPublication };
    const dataSavePublicationSave = JSON.parse(this.authService.getLocalStorage('publicationForWish'));
    if (data) {
          if (data?.ownerId && data?.wishId) {
            const dataPublication = {
              ownerId: data.ownerId,
              wishId: data.wishId
            };
            this.authService.insertLocalStorage('publicationForWish', JSON.stringify(dataPublication));
            this.layoutService.setComponentNewPublication(data);
            data = dataSavePublicationSave;
            this.router.navigate(['new-publication']);
            this.layoutService.setShowModalWishDetail(false);
          }
        } else {
          if (dataSavePublicationSave) {
            this.layoutService.setComponentNewPublication(data);
            data = dataSavePublicationSave;
            this.router.navigate(['new-publication']);
          }
        }
  }

  openModalQualifyModal() {
    this.openModalQualify = true;
  }

  accept() {
    if (this.wish.title == 'Sexting' || this.wish.title == 'videoCalls' || this.wish.title == 'GFE') {
      const payload = {
        wishId: this.wishId,
        accept: true,
        comment: '',
        isSexting: true,
        chatReasson: this.wish.title
      };
      this.wishService.acceptOrRejectWish(payload).subscribe(res => {
        this.layoutService.setUpdateListwish();
        this.layoutService.setUpdateStatus();
        this.goToChat();
        this.layoutService.setShowModalWishDetail(false);
      });
      return;
    }

    // Case videoCustom
    if (this.wish.title == 'videoCustom') {
      this.showModalAddNewVideos(this.wish._id, this.wish.userClientId, this.wish.time);
      return;
    }

    // Case default - pack
    if (!this.wish.service) {
      this.openComponentAddPublication(this.wish._id, this.wish.userClientId, this.wish?.quantityMinImages);
      return;
    }
  }

  showModalAddNewVideos(wishId, ownerId, time) {
    const data = { wishId, ownerId, time };
    this.layoutService.setDataWishComponentNewMovie(data);
    this.layoutService.setDataShowModalAddNewVideos(true);
  }

  acceptClient(response: boolean) {
    const payload = {
      wishId: this.wish._id,
      accept: response,
      comment: this.comentaryOfClient.value,
      transactionId: this.wish.transaction,
    };
    this.wishService
      .accepOrRejectForClientWish(payload)
      .subscribe(() => {
        this.getWish();
        const message = response ? 'NOTIFICATION.CLIENT_SATISFIED' : 'NOTIFICATION.CLIENT_DISSATISFIED';
        this.sendNotification(this.wish.userSellerId, this.wish.userClientId, message);
        this.layoutService.setUpdateStatus();
      });
  }

  closeFrame() {
    this.publicationSelected = '';
    this.movieSelected = '';
    this.frameDetail= false;
  }

  selectPublication(publication: any) {
this.frameDetail= true;

    const image = {
          _id: publication.userSellerId,
          url: this.imagePublication(publication.publication),
          title: publication.title,
          description: publication.description,
          images: publication.publication.images,
          videos: publication.publication.videos,
          audios: publication.publication.audios,
          userId: publication.userSellerId
        };
      this.publicationSelected = image;
  }

  selectMovie(movie: any) {
this.frameDetail= true;
    this.playFullScreen(movie.video.videoUrl);
  }

  playFullScreen(url) {

    this.toastService.presentToast('TOAST.BUILDING_PREVIEW', 2500);

    const lastVideo = document.getElementById('last-video');
    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    video.src = this.fileService.transformUrlVideo(url);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id = 'last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
      video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

    };
  }

  imagePublication(publication) {
    if (publication.images[0]?.imageUrl) {
      return publication.images[0]?.imageUrl;
    }

    if (publication.videos[0]) {
      return this.getImagePreviewOfVideo(publication.videos[0]?.videoUrl);
    }

    if (publication.audios[0]) {
      return 'assets/icon/Icons-Lust - microphone-01.svg';
    }
  }

  getImagePreviewOfVideo(videoUrl) {
    return videoUrl.replace(/\.[^/.]+$/, '.webp');
  }

isUserAllowedToSeeButtons(): boolean {
  const isClient = this.wish?.userClientId?._id === this.myId;
  const isSeller = this.wish?.userSellerId?._id === this.myId;
  const isStatusAllowed = this.wish?.status?.status !== 7 && this.wish?.status?.status !== 4;

  // Permitir solo si el usuario es el cliente y el estado es permitido
  return isClient && isStatusAllowed;
}

isPublicationOrServiceAllowed(): boolean {
  const isPublicationAllowed = this.wish?.publication && this.wish?.status?.status !== 5;
  const isMovieAllowed = this.wish?.movie && this.wish?.status?.status !== 5;
  const isServiceAllowed = this.wish?.service && this.wish?.status?.status === 2;
  return isPublicationAllowed || isMovieAllowed || isServiceAllowed;
}



  sendNotification(userReceiver: string, userSender: string, message: string) {
    const notification = {
      userReceiver,
      userSender,
      type: 'wish',
      message,
      contextId: this.wishId
    };
    this.socket.sendNotification(notification);
  }

  goToChat() {
    const component: DataComponent = {
      component: 'chat',
      contextId: this.myIsClient ? this.wish.userSellerId._id : this.wish.userClientId._id,
      wishRef: this.wish
    };
    this.layoutService.setChatRoom(component);
    localStorage.setItem('chatRoomData', JSON.stringify(component));
    this.router.navigate(['chat-room']);
     this.layoutService.setShowModalWishDetail(false);
    if (this.wish.title === 'Sexting') {
    localStorage.setItem('chatRoomData', JSON.stringify(component));
    this.router.navigate(['chat-room']);
    this.wishService.openSextingByWish(this.wish._id).subscribe();
    }
  }




  sendQualify() {
    this.formQualify.get('userClientId').setValue(this.wish.userClientId._id);
    this.formQualify.get('userSellerId').setValue(this.wish.userSellerId._id);
    this.formQualify.get('wish').setValue(this.wish._id);

    const review = this.formQualify.value;
    this.wishService.addReviewWish(review.userClientId, review.userSellerId, review.rating, review.comentary, review.wish).subscribe(() => {
      this.openModalQualify = false;
      this.isWishReview = true;
    });
  }

}
