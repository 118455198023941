import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { QueryList, ViewChildren } from '@angular/core';
declare let videojs: any;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy ,OnChanges{
  @ViewChild('target', { static: true }) target: ElementRef;
  @ViewChildren(VideoPlayerComponent) videoPlayers!: QueryList<VideoPlayerComponent>;

  @Input() fluid: boolean;
  @Input() controls: boolean;
  @Input() autoplay: boolean;
  @Input() aspectRatio: string;
  @Input() sourceSrc: any;
  @Input() sourceType: string;
  @Input() poster: string;
  @Input() height: number;
  @Input() width: number;
  @Input() muteVideo: boolean;
  @Input() indexVideo;
  @Input() isVisible: boolean; // Nuevo Input para controlar visibilidad
  @Input() frameAuto: boolean;
  currentVideoIndex: number | null = null;
  isPlaying = false; // Nuevo estado de reproducción
  showPlayButton = true; // Controla la visibilidad del botón de reproducción
  playVideo = false;
  pauseVideo = false;
  observer: IntersectionObserver;
  progress = 0;
  currentTime = 0;
  duration = 0;
  isDragging = false;
  wasPlaying = false;
  player: any;

  constructor() {}


  ngOnInit() {
    this.setupObserver();
    if (this.frameAuto) {
      this.isVisible = true;
      this.muteVideo=true;
    }
    this.player = videojs(this.target.nativeElement, {
      fluid: this.fluid,
      controls: false,
      loop: true,
      autoplay: false,
      aspectRatio: this.aspectRatio,
      sources: [{ src: this.sourceSrc[0].videoUrl, type: this.sourceType }],
      poster: this.poster,
      height: this.height,
      width: this.width,
      volume: 1,
      nativeControlsForTouch: false,
      muted: true, // Iniciar en mute
      bigPlayButton: true,
      controlBar: {
        volumePanel: { inline: false },
      },
    });

    this.player.on('loadedmetadata', () => {
      this.duration = this.player.duration();
    });

    this.player.on('timeupdate', () => {
      if (!this.isDragging && !isNaN(this.duration) && this.duration > 0) {
        this.currentTime = this.player.currentTime();
        this.progress = (this.currentTime / this.duration) * 100;
      }
    });

    window.addEventListener('mousemove', this.onDrag.bind(this));
    window.addEventListener('mouseup', this.stopDrag.bind(this));
    window.addEventListener('touchmove', this.onDrag.bind(this));
    window.addEventListener('touchend', this.stopDrag.bind(this));
  }
  ngOnChanges() {
    if (this.player) {
      this.player.muted(this.muteVideo);
      if (this.isVisible) {
        this.player.play();
      } else {
        this.player.pause();
      }
      // Reproduce automáticamente y oculta el botón si FrameAuto es true
      if (this.frameAuto) {
        this.isVisible = true;
        this.player.play();
      }
    }
  }

 setupObserver() {
  this.observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (this.player.paused()) {  // Solo llama a play si está pausado
            this.playVideo = false;
            this.player.autoplay(true);
            this.player.play();
            this.pausePrevious();
          }
        } else {
          if (!this.player.paused()) {  // Solo llama a pause si está en reproducción
            this.playVideo = true;
            this.player.autoplay(false);
            this.player.pause();
          }
        }
      });
    },
    { threshold: 0.5 }
  );
  if (this.target && this.target.nativeElement) {
    this.observer.observe(this.target.nativeElement);
  }
}


  toggleMute() {
    this.muteVideo = !this.muteVideo;
    this.player.muted(this.muteVideo);
  }

  startDrag(event: MouseEvent | TouchEvent) {
    this.isDragging = true;
    this.wasPlaying = !this.player.paused();
    this.player.pause();
    this.onDrag(event);
  }

  onDrag(event: MouseEvent | TouchEvent) {
    if (!this.isDragging) {return;}

    const progressContainer = document.querySelector('.progress-container') as HTMLElement;
    const rect = progressContainer.getBoundingClientRect();
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const newTime = ((clientX - rect.left) / rect.width) * this.duration;

    if (!isNaN(newTime) && newTime >= 0 && newTime <= this.duration) {
      this.progress = ((clientX - rect.left) / rect.width) * 100;
      this.player.currentTime(newTime);
    }
  }

  stopDrag() {
    if (this.isDragging) {
      this.isDragging = false;
      if (this.wasPlaying) {
        this.player.play();
      }
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener('mousemove', this.onDrag.bind(this));
    window.removeEventListener('mouseup', this.stopDrag.bind(this));
    window.removeEventListener('touchmove', this.onDrag.bind(this));
    window.removeEventListener('touchend', this.stopDrag.bind(this));
  }

   controlVideo() {
    if (this.player.paused()) {
      this.player.play();
      this.isVisible = true;
    } else {
      this.player.pause();
      this.isVisible = false;
    }
  }

  // Método para pausar el video anterior
  pausePrevious() {
    const videos = document.querySelectorAll('.video-player-content video');
    videos.forEach((video) => {
      if (video !== this.player.el()) {
        (video as HTMLVideoElement).pause();
      }
    });
  }
}
