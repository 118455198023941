import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Transaction } from 'src/app/core/models/transaction.model';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { LayoutService } from '../../services/layout/layout.service';
import { SocketService } from '../../services/socket/socket.service';
import { ToastService } from '../../services/toast/toast.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { WishService } from '../../services/wish/wish.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-new-wish',
  templateUrl: './new-wish.component.html',
  styleUrls: ['./new-wish.component.scss'],
})
export class NewWishComponent implements OnInit, OnDestroy {
  wishForm = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    price: new FormControl('10.00', Validators.required),
  });

  cashAvailable: number | null = null;

  myId = '';
  wishUsersData: any = {};
  subscription: Subscription;
  isDeviceIOS = false;
  wishId: any;

  constructor(
    private wishService: WishService,
    private accountsService: AccountsService,
    private transactionsService: TransactionsService,
    private authService: AuthServiceService,
    private layoutService: LayoutService,
    private socket: SocketService,
    private toastService: ToastService,
    private router: Router,
    private menu: MenuController,
  ) {}

  get wishFormPrice() {
    return this.wishForm.get('price').value;
  }

  ngOnInit() {
    const myUser = this.authService.getLocalStorage('user');
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const myUserParsed = JSON.parse(myUser);
    this.myId = myUserParsed._id;
    this.myCash();
    this.subscription = this.layoutService
      .getDataWishComponentNewWish()
      .subscribe((wishData: any) => (this.wishUsersData = wishData));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ionViewWillLeave(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.layoutService.setShowComponentNewWish(false);
    if (this.layoutService.isBeforeChat) {
      this.menu.open('menu2');
      this.layoutService.isBeforeChat = false;
    }
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Eliminar caracteres no permitidos y mantener solo un punto
    inputValue = inputValue.replace(/[^\d.]/g, '');
    const parts = inputValue.split('.');
    if (parts.length > 1) {
      inputValue = parts[0] + '.' + parts.slice(1).join('');
    }

    // Asegurar que no haya ceros a la izquierda, excepto para el caso "0."
    if (inputValue.length > 1 && inputValue.startsWith('0')) {
      inputValue = inputValue.replace(/^0+/, '');
    }
    if (inputValue === '0') {
      inputValue = '1';
    }

    // Actualizar el valor del FormControl con el valor filtrado
    this.wishForm.get('price').setValue(inputValue, { emitEvent: false });
  }

  /**
   * Registra una transacción para crear un deseo
   */
  registerTransaction(): void {
    if (this.hasUserSufficientAmount()) {
      const payload = {
        idSeller: this.wishUsersData.userId,
        idClient: this.myId,
        aprovedClient: true,
        aprovedSeller: true,
        amount: parseFloat(this.wishFormPrice),
        product: {},
        type: 5,
      };
      this.transactionsService
        .registerTransactionFrozen(payload)
        .subscribe((transaction: Transaction) => {
          this.createWish(transaction._id);
          this.layoutService.setUpdateStatus();
        }
        );
    } else {
      this.toastService.presentToastMiddleError(
        'TOAST.ERROR.INSUFFICIENT_BALANCE',
        3000
      );
    }
  }

  /**
   * Verifica si el usuario tiene el monto suficiente en la cuenta
   *
   * @returns Si el monto es mayor al valor del deseo, devuelve verdad. En caso contrario es falso
   */
  hasUserSufficientAmount(): boolean {
    return parseFloat(this.wishFormPrice) <= this.cashAvailable;
  }

  /**
   * Crea un deseo y envia una notificación
   *
   * @param transactionId Id de la transacción
   */
  createWish(transactionId: string): void {
    if (this.hasUserSufficientAmount) {
      const wish = {
        ...this.wishForm.value,
        transaction: transactionId,
        userSellerId: this.wishUsersData.userId,
      };
      this.wishService.createWish(wish).subscribe((res: any) => {
        this.wishForm.get('title').reset();
        this.wishForm.get('description').reset();
        this.wishForm.get('price').reset();
        if (res) {
        this.sendNotification('wish', res._id);
        }
        const req = {
          url: this.router.url,
          method: 'POST',
        } as HttpRequest<string>;
        this.toastService.presentToastMiddleCheck(req);
      });
      this.close();
    }
  }

  myCash(): void {
    this.accountsService.getMyCash().subscribe((myCash: any) => {
      this.cashAvailable = myCash.cash;
    });
  }

  /**
   * Envia una notificación al usuario realizar una acción
   *
   * @param type Indica el tipo de la notificación (Chat, Deseo, Producto)
   */
  sendNotification(type: string, contextId: string): void {
    const notification = {
      userReceiver: this.wishUsersData.userId,
      userSender: this.myId,
      type,
      message: 'WISHES.DETAIL.CREATOR.REQUEST',
      contextId
    };
    this.socket.sendNotification(notification);
  }
}
