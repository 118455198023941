import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

declare let videojs: any;

@Component({
  selector: 'app-video-ad-vast',
  templateUrl: './video-ad-vast.component.html',
  styleUrls: ['./video-ad-vast.component.scss'],
})
export class VideoAdVastComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() skip = new EventEmitter<boolean>();
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

  showButtonSkip = false;
  isMobile = false;
  uniqueId: string;
  indexInterval: any;

  constructor() {
    this.isMobile = window.outerWidth < 640;
  }

  ngOnInit() {
    this.uniqueId = 'vid' + new Date().getTime();
  }

  ngAfterViewInit() {
    this.indexInterval = setInterval(() => {
      if (this.videoElement) {
        this.initializeVideo();
        clearInterval(this.indexInterval);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    clearInterval(this.indexInterval);
  }

  initializeVideo() {
    try {
      const videoElement = this.videoElement.nativeElement;
      const vid = videojs(videoElement);

      vid.muted(true);
      vid.play();

      setTimeout(() => {
        this.showButtonSkip = true;
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  }

  clickSkipVideo() {
    const videoElement = this.videoElement.nativeElement;
    videoElement.remove();
    this.skip.emit();
    this.showButtonSkip = false;
  }
}
