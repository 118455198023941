import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthModule } from './features/auth/auth.module';
import { AuthGuardGuard } from './features/auth/guard/auth-guard.guard';
import { HomeGuard } from './features/auth/guard/home.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'lust-list',
    pathMatch: 'full',
  },
  {
     path: 'folder',
     loadChildren: () =>
       import('./features/folder/folder.module').then((m) => m.FolderPageModule),
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => AuthModule),
    canLoad: [HomeGuard],
  },
  {
    path: 'profile/:id',
    loadChildren: () =>
      import('./features/profile/profile.module').then(
        (m) => m.ProfilePageModule
      ),
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'model/:username',
    loadChildren: () =>
      import('./features/profile/profile.module').then(
        (m) => m.ProfilePageModule
      ),
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'profile-preview/:id/:preview',
    loadChildren: () =>
      import('./features/profile/profile.module').then(
        (m) => m.ProfilePageModule
      )
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomePageModule),
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'lust-list',
    loadChildren: () => import('./features/lust-list/lust-list.module').then(m => m.LustListPageModule),
    canLoad: [AuthGuardGuard],
  },
    {
      path: 'finances/:id',
      loadChildren: () =>
        import('./features/finances/finances.module').then(
          (m) => m.FinancesPageModule
        ),
      canLoad: [AuthGuardGuard],
    },
    {
      path: 'finances',
   loadChildren: () =>
        import('./features/finances/finances.module').then(
          (m) => m.FinancesPageModule
        ),
      canLoad: [AuthGuardGuard],
    },
   {
     path: 'deposit',
      loadChildren: () =>
        import('./features/deposit/deposit.module').then(
          (m) => m.DepositPageModule
       ),
      canLoad: [AuthGuardGuard],
   },
   {
     path: 'withdraw',
     loadChildren: () =>
       import('./features/withdraw/withdraw.module').then(
         (m) => m.WithdrawPageModule
       ),
  canLoad: [AuthGuardGuard],
   },

  {
    path: 'profile-incognito',
    loadChildren: () =>
      import('./features/profile-incognito/profile-incognito.module').then(
        (m) => m.ProfileIncognitoPageModule
      ),
    canLoad: [AuthGuardGuard],
  },

   {
     path: 'transfer/:id',
     loadChildren: () =>
       import('./features/transfer/transfer.module').then(
         (m) => m.TransferPageModule
       ),
     canLoad: [AuthGuardGuard],
   },
   {
     path: 'transfer',
     loadChildren: () =>
     import('./features/transfer/transfer.module').then(
        (m) => m.TransferPageModule
       ),
     canLoad: [AuthGuardGuard],
   },

  {
    path: 'chats-list',
    loadChildren: () =>
      import('./features/chats-list/chats-list.module').then(
        (m) => m.ChatsListPageModule
      ),
    canLoad: [AuthGuardGuard],
  },
  {
     path: 'wish-list',
     loadChildren: () => import('./features/wish-list/wish-list.module').then(m => m.WishListPageModule),
     canLoad: [AuthGuardGuard],
   },
   {
     path: 'billing-list',
    loadChildren: () => import('./features/billing-list/billing-list.module').then(m => m.BillingListPageModule),
     canLoad: [AuthGuardGuard],
   },
   {
     path: 'payment-list',
     loadChildren: () => import('./features/payment-list/payment-list.module').then(m => m.PaymentListPageModule),
     canLoad: [AuthGuardGuard],
   },
  {
    path: 'chat-with-admin',
    loadChildren: () => import('./features/chat-with-admin/chat-with-admin.module').then(m => m.ChatWithAdminPageModule),
    canLoad: [AuthGuardGuard],
  },


   {
     path: 'community',
     loadChildren: () => import('./features/community/community.module').then(m => m.CommunityPageModule),
     canLoad: [AuthGuardGuard],
   },
   //Ocultando para nuevas versiones
  //  {
  //   path: 'products-list',
  //   loadChildren: () => import('./features/products-list/products-list.module').then( m => m.ProductsListPageModule)
  //  },
  //  {
  //    path: 'meeting/:channel',
  //    loadChildren: () => import('./features/meeting/meeting.module').then( m => m.MeetingPageModule)
  //  },
  //  {
  //    path: 'calendar/:userId',
  //    loadChildren: () => import('./features/calendar/calendar.module').then( m => m.CalendarPageModule)
  //  },
     //Ocultando para nuevas versiones
  {
    path: 'preview/:publicationId',
    loadChildren: () => import('./features/preview/preview.module').then( m => m.PreviewPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsPageModule),
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'explorer',
    loadChildren: () => import('./features/explorer/explorer.module').then(m => m.ExplorerPageModule),
    canLoad: [AuthGuardGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsPageModule),
      canLoad: [AuthGuardGuard],
  },
  {
    path: 'new-publication',
    loadChildren: () => import('./features/new-publication/new-publication.module').then(m => m.NewPublicationPageModule),
      canLoad: [AuthGuardGuard],
  },
  {
    path: 'chat-room',
    loadChildren: () => import('./features/chat-room/chat-room.module').then(m => m.ChatRoomPageModule),
      canLoad: [AuthGuardGuard],
  },
  {
    path: 'request-menu-model',
    loadChildren: () => import('./features/request-menu-model/request-menu-model.module').then(m => m.RequestMenuModelPageModule),
    canLoad: [AuthGuardGuard],
  },
];

const config: ExtraOptions = {
  useHash: false,
};
@NgModule({
  imports: [
    //RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    RouterModule.forRoot(routes, config)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
