import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LayoutService } from '../../services/layout/layout.service';
import { UsersService } from '../../services/users/users.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { ToastService } from '../../services/toast/toast.service';
import { Transaction } from 'src/app/core/models/transaction.model';
import { WishService } from '../../services/wish/wish.service';
import { SocketService } from '../../services/socket/socket.service';
import { Router } from '@angular/router';
import { HttpRequest } from '@angular/common/http';
import { AccountsService } from '../../services/accounts/accounts.service';
import { ServiceType } from '../../enums/services';

@Component({
  selector: 'app-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss'],
})
export class ServiceModalComponent implements OnInit, OnChanges {
  @Input() service = null;

  serviceImgs = {
    [ServiceType.squirt]: '../../../../../assets/wishesicon/Squirt-02.svg',
    [ServiceType.dildo]: '../../../../../assets/wishesicon/Dildo-02.svg',
    [ServiceType.vibrator]: '../../../../../assets/wishesicon/Vibrator-02.svg',
    [ServiceType.dirty_talk]: '../../../../../assets/wishesicon/DirtyTalk-02.svg',
    [ServiceType.spitting]: '../../../../../assets/wishesicon/Spitting-02.svg',
    [ServiceType.edging]: '',
    [ServiceType.feet]: '../../../../../assets/wishesicon/Feet-02.svg',
    [ServiceType.worshiping]: '../../../../../assets/wishesicon/Worship-02.svg',
    [ServiceType.spanking]: '../../../../../assets/wishesicon/Spanking-02.svg',
    [ServiceType.submission]: '../../../../../assets/wishesicon/Submission-02.svg',
    [ServiceType.role_plays]: '../../../../../assets/wishesicon/Roles-Play-02.svg',
    [ServiceType.pooping]: '../../../../../assets/wishesicon/Popping-02.svg',
    [ServiceType.peeing]: '../../../../../assets/wishesicon/Peeing-02.svg',
    [ServiceType.bondage]: '',
    [ServiceType.anal]: '../../../../../assets/wishesicon/Anal-02.svg',
    [ServiceType.cuckolding]: '../../../../../assets/wishesicon/cuckolding.svg',
    [ServiceType.sph]: '../../../../../assets/wishesicon/SPH-02.svg',
    [ServiceType.joi]: '../../../../../assets/wishesicon/JOI-02.svg',
    [ServiceType.bg_or_gg]: ''
  };

  wishForm = new FormGroup({
    description: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    enable: new FormControl(),
  });

  cashAvailable: number | null = null;

  constructor(
    private layoutService: LayoutService,
    private userService: UsersService,
    private transactionsService: TransactionsService,
    private toastService: ToastService,
    private wishService: WishService,
    private socket: SocketService,
    private router: Router,
    private accountsService: AccountsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes.service.currentValue;
    if (currentValue) {
      this.wishForm.get('description').setValue(currentValue.description);
      this.wishForm.get('price').setValue(currentValue.price);
      this.wishForm.get('enable').setValue(currentValue.enable);
    }
  }

  ngOnInit() {
    this.myCash();

  }

  test(){
    const service = {
      title: this.service.title,
      ...this.wishForm.value
    };
    this.userService.setService({ service }).subscribe(
      (res: any) => {
        this.close();
        this.layoutService.setShowComponentServiceModal({ reload: true });
      },
      err => console.error(err)
    );
  }

  close(){
    this.layoutService.setShowComponentServiceModal(null);
  }

  registerTransaction(): void {
    if (this.hasUserSufficientAmount()) {
      const payload = {
        idSeller: this.service.sellerId,
        idClient: this.service.clientId,
        aprovedClient: true,
        aprovedSeller: true,
        amount: this.service.price,
        product: {},
        type: 5,
      };
      this.transactionsService
        .registerTransactionFrozen(payload)
        .subscribe((transaction: Transaction) =>
          this.createWish(transaction._id)
        );
    } else {
      this.toastService.presentToastMiddleError(
        'TOAST.ERROR.INSUFFICIENT_BALANCE',
        3000
      );
    }
  }

  hasUserSufficientAmount(): boolean {
    return this.service.price <= this.cashAvailable;
  }

  createWish(transactionId: string): void {
    const wish = {
      title: this.service.title,
      description: this.service.description,
      price: this.service.price,
      transaction: transactionId,
      userSellerId: this.service.sellerId,
    };
    this.wishService.createWish(wish).subscribe((res: any) => {
      this.sendNotification('wish', res._id);
      const req = {
        url: this.router.url,
        method: 'POST',
      } as HttpRequest<string>;
      this.toastService.presentToastMiddleCheck(req);
    });
    this.close();
  }

  sendNotification(type: string, contextId: string): void {
    const notification = {
      userReceiver: this.service.sellerId,
      userSender: this.service.clientId,
      type,
      message: 'WISHES.DETAIL.CREATOR.REQUEST',
      contextId
    };
    this.socket.sendNotification(notification);
  }

  myCash(): void {
    this.accountsService.getMyCash().subscribe((myCash: any) => {
      this.cashAvailable = myCash.cash;
    });
  }

}
