import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private env = environment;

  constructor(private httpClient: HttpClient) { }

  getProduct(productId){
    return this.httpClient.get(
      this.env.apiAuth + '/products/' + productId
    );
  }

  getBilling(billingId){
    return this.httpClient.get(
      this.env.apiAuth + '/products/billing/' + billingId
    );
  }

  getSellerBillings(page){
    return this.httpClient.get(
      this.env.apiAuth + '/products/seller-billings?page='+page
    );
  }

  getBuyerBillings(page){
    return this.httpClient.get(
      this.env.apiAuth + '/products/buyer-billings?page='+page
    );
  }

  getProducts(userId, enabled, page){
    return this.httpClient.get(
      this.env.apiAuth + '/products/products-user?userId='+userId+'&enabled='+enabled+'&page='+page
    );
  }

  getAllProducts(page){
    return this.httpClient.get(
      this.env.apiAuth + '/products?page='+page
    );
  }

  saveProduct(product) {
    return this.httpClient.post(
      this.env.apiAuth + '/products',
      product
    );
  }

  saveImage(payload) {
    return this.httpClient.post(
      this.env.API_SERVICE_FILE_LL + '/upload-image',
      payload
    );
  }

  addCommentProduct(comment, id) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/add-comment/' + id,
      comment
    );
  }

  buyProduct(productId, payload) {
    return this.httpClient.post(
      this.env.apiAuth + '/products/buy-product/' + productId,
      payload
    );
  }

  removeQuantityFromStock(productId, payload) {
    return this.httpClient.post(
      this.env.apiAuth + '/products/sell-product/' + productId,
      payload
    );
  }

  markProductAsSended(billingId, payload) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/send-product/' + billingId,
      payload
    );
  }

  markProductAsArrived(billingId, comments) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/arrive-product/' + billingId,
      comments
    );
  }

  markProductAsReceived(billingId, comments) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/receive-product/' + billingId,
      comments
    );
  }

  clientAccording(billingId, payload) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/client-according/' + billingId,
      payload
    );
  }

  liberateFunds(payload) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/liberate-amount-frozen',
      payload
    );
  }

  editProduct(productId, payload) {
    return this.httpClient.put(
      this.env.apiAuth + '/products/update/' + productId,
      payload
    );
  }

  deleteProduct(productId) {
    return this.httpClient.delete(
      this.env.apiAuth + '/products/' + productId
    );
  }

  searchProducts(value) {
    return this.httpClient.get(
      this.env.apiAuth + '/products/search/'+value
    );
  }

  searchSellerBillings(value) {
    return this.httpClient.get(
      this.env.apiAuth + '/products/search-seller-billings/'+value
    );
  }

  searchBuyerBillings(value) {
    return this.httpClient.get(
      this.env.apiAuth + '/products/search-buyer-billings/'+value
    );
  }
}
