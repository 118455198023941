import { Pipe, PipeTransform } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';

@Pipe({
  name: 'formatAssets'
})
export class FormatAssetsPipe implements PipeTransform {
  constructor(private authService: AuthServiceService) {

  }

  transform(value: string): string {

    // si es un archivo local
    if (value.includes('/assets/')) {
      return value;
    }

    if (value.endsWith('.jpg') || value.endsWith('.jpeg') || value.endsWith('.png')) {

      value = this.transformCloudinaryImageUrl(value);
      return value.replace('.jpg', '.webp').replace('.jpeg', '.webp').replace('.png', '.webp');
    } else if (value.endsWith('.mp4') || value.endsWith('.webm') || value.endsWith('.ogg')) {
      value = this.transformCloudinaryVideoUrl(value);
      if (this.authService.isDeviceIOS) {
        if (value.includes('.webm')) {
          return value.replace('.webm', '.mp4');
        }
        return value;
      } else {
        return value.replace('.mp4', '.webm').replace('.ogg', '.webm');
      }
    } else {

      return value;
    }
  }


  transformCloudinaryVideoUrl (inputUrl) {
    const transformation = "ar_16:9,c_fill,q_auto";
    const parts = inputUrl.split("/upload/");

    if (parts.length === 2) {
      const transformedUrl = `${parts[0]}/upload/${transformation}/${parts[1]}`;
      return transformedUrl.replace(".mp4", ".webm");
    } else {
      return inputUrl;
    }
  }

  transformCloudinaryImageUrl (inputUrl) {
    let transformation;
    if (window.outerWidth > 640) {
       transformation = "q_auto/f_avif/c_thumb,w_600/dpr_auto";
    } else {
      transformation = "q_auto/f_avif/c_thumb,w_400/dpr_auto";
    }

    const parts = inputUrl.split("/upload/");

    if (parts.length === 2) {
      const transformedUrl = `${parts[0]}/upload/${transformation}/${parts[1]}`;
      return transformedUrl;
    } else {
      return inputUrl;
    }
  }

}
