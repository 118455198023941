import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'app-modal-metrics',
  templateUrl: './modal-metrics.component.html',
  styleUrls: ['./modal-metrics.component.scss'],
})
export class ModalMetricsComponent implements OnInit {
  metricsSuscribedByPublication: any;
  metricsViewProfileByPublication: any;
  metricsFollowedByPublication: any;
  metricsViewProfile: any;
  metricsViewPublication: any;
  userMorefrec: any;

  typeFrom: any;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
 this.getDataModal();
  }
  getDataModal() {
    this.layoutService.getDataModalMetrics().subscribe((val: any) => {
      this.typeFrom = val.from;
      this.userMorefrec = val.userMorefrec;
      val.data.forEach(element => {
        if (element.type === 'SUBSCRIBED_BY_PUBLICATION') {
           this.metricsSuscribedByPublication = element;
        }
        if (element.type === 'VIEW_PROFILE_BY_PUBLICATION') {
          if (element.publication) {
           this.metricsViewProfileByPublication = element;
          }
        }
        if (element.type === 'FOLLOWED_BY_PUBLICATION') {
           this.metricsFollowedByPublication = element;
        }
        if (element.type === 'VIEW_PROFILE') {
           this.metricsViewProfile = element;
        }
        if (element.type === 'VIEW_PUBLICATION') {
           this.metricsViewPublication = element;
         }
      });
    });
  }

  selectImage(image: any) {
    const publication = {
    _id: image.publication._id,
    title: image.publication.title,
    description: image.publication.description,
    images: image.publication.images,
    videos: image.publication.videos,
      audios: image.publication.audios,
    comments: image.publication.comments,
    userId: image.publication.userAuhthorId
};

    this.layoutService.setShowModalMetrics(false);
     this.layoutService.setShowModalFrameDetail(true);
     this.layoutService.setDataFrameImage(publication);
  }

  closeModal() {
    this.layoutService.setShowModalMetrics(false);
  }

}
