import { Component, EventEmitter, Input, OnInit, Output, ViewChild,ElementRef} from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { PublicationService } from '../../services/publication/publication.service';

import SwiperCore, { Pagination } from 'swiper';
import { LayoutService } from '../../services/layout/layout.service';
import { environment } from 'src/environments/environment';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../services/file/file.service';

SwiperCore.use([Pagination]);

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  @Input() images = [];
  @Output() imageSelected = new EventEmitter<any>();
  @Output() openCommentsComponent = new EventEmitter<boolean>(false);
  @Output() scrollDown = new EventEmitter<boolean>(false);
  @Input() idProfile = '';
  @ViewChild('comments') comments;
  @ViewChild('gallery') gallery: ElementRef;

  publicationToComment;
  openComments = false;
  myId = '';

  moreOptionOpen = false;
  confirmDeleteOpen = false;
  indexImg: null | number = null;
  optionOtherProfileModal = false;
  sharePublication = false;
  imgSelected: any = undefined;
  linkRedirect: string;
  copyActive = false;
  isDeviceIOS = false;
  fullScreen = false;

  typeReport = 'report-publication';
    isModalOpen = false;
  isModalReport = false;
  titleModal = '';
    aspectoRatio = 16 / 9;

    private env = environment;

  constructor(
    private publicationService: PublicationService,
    private authService: AuthServiceService,
    private layoutService: LayoutService,
    private toastService: ToastService,
    private usersService: UsersService,
     private sanitizer: DomSanitizer,
     private fileService: FileService
  ) { }

  ngOnInit() {
     this.isDeviceIOS = this.authService.isDeviceIOS;
    const user = this.authService.getLocalStorage('user');
    if (user) {
      const userParsed = JSON.parse(user);
      this.myId = userParsed._id;
    }
  }

  calculateWidth() {
    const width = (window.innerWidth * 0.9);
    return 'max-width: ' + width + 'px;' + 'width: ' + width + 'px';
  }


  selectImage(image: any) {
    // if (image.images.length === 0 &&
    //       image.audios.length === 0 &&
    //       image.videos.length > 0) {
    //   image?.videos.forEach((data: any) => {
    //   this.playFullScreenIos(data.videoUrl);
    //   });
    // }
    if (image.videos.length > 0) {
      this.imageSelected.emit(image);
    }
    // else if (image?.audios?.length > 0) {
    // this.imageSelected.emit(image);
    // }
    else if (image?.images?.length > 0) {
    this.imageSelected.emit(image);
    }
  }

   openModalMetrics(publication) {
    this.publicationService.getMetricsPublication(publication.id).subscribe((val: any) => {
      const data = {
        from: 'gallery-uniform',
        data: val
      };
      this.layoutService.setShowModalMetrics(true);
      this.layoutService.setDataModalMetrics(data);
    });
  }
  openModalComments(publication) {
    this.layoutService.setShowModalCommentFrame(true);
    this.layoutService.setDataCommentFrame(publication);
  }

  changeFavoriteStatus(image) {
    if (image.publicationLikes.includes(this.myId)) {
      this.publicationService
        .dislike(image.id)
        .subscribe((res: any) => {
          image.likes = res.likes;
          image.publicationLikes = res.publicationLikes.usersId;
        });
    } else {
      this.publicationService
        .like(image.id)
        .subscribe((res: any) => {
          image.likes = res.likes;
          image.publicationLikes = res.publicationLikes.usersId;
        });
    }
  }

  closeComments(status) {
    this.openComments = status;
    this.openCommentsComponent.emit(status);
  }

     getSafeLink(url): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  transformVideo(video) {
    if (video) {
    return this.fileService.transformUrlVideo(video.videoUrl);
    }
   }

    getWidthGallery(): number {
    return this.gallery?.nativeElement?.offsetWidth ? this.gallery?.nativeElement?.offsetWidth : 300;
  }

    getImageAudio(publication){

    let image = publication.userId
      .imageProfile.imageUrl;

    if (publication.images.length > 0) {
      image = publication.images[0].imageUrl;
    }

    if (publication.videos.length > 0) {
      image = publication.videos[0].videoUrl.replace(/\.[^/.]+$/, '.webp');
    }

    return image;
    }

  convertirArchivo(url) {
    // Comprobar si la URL termina en ".mp4" para identificar un archivo de video
    if (url.endsWith('.mp4') || url.endsWith('.mov')) {
    // Convertir la URL del video en una URL de conversión a GIF
    const transformation = 'ar_16:9,c_fill,q_auto,eo_5,so_0';
    const parts = url.split('/upload/');

    if (parts.length === 2) {
       url = `${parts[0]}/upload/${transformation}/${parts[1]}`;
    }
    const gifUrl = url.replace('.mp4', '.gif').replace('.mov', '.gif');
    return gifUrl;
  } else {
    // Si no es un archivo de video, devolver la URL original
    return url;
  }
  }
  /*
  getImageVideo(publication){
  let image = publication.userId
      .imageProfile.imageUrl;

    if (publication.images.length > 0) {
      image = publication.images[0].imageUrl;
    }

    return image;
  }*/

  getImageVideo(video) {

    return video.videoUrl.replace('webm', 'webp').replace('mp4', 'webp').replace('mov', 'webp');
  }

  playFullScreenIos(url) {

    const lastVideo = document.getElementById('last-video');

    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    video.src = this.fileService.transformUrlVideo(url);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id='last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
     video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

     };
  }



  optionsMyGallery(index) {
    this.indexImg = index;
    this.moreOptionOpen = !this.moreOptionOpen;
  }

  editPublication(index) {
     if (this.images) {
      const data = { publication: this.images[index] };
      this.layoutService.setDataWishComponentNewPublication(data);
      this.layoutService.setShowComponentNewPublication(true);
      this.layoutService.setDataTitleAddPublication('PUBLICATION.EDIT_PUBLICATION');

    }

  }
  onConfirmDelete(index) {
  this.indexImg = index;
  this.moreOptionOpen = false;
  this.confirmDeleteOpen = true;

  }
  deletePublication(index) {
     this.optionsModalDelete();
    this.deletePublications(this.images[index].id);


  }

  deletePublications(id: string) {
    this.publicationService.deletePublication(id).subscribe(() => {
      this.layoutService.setIdDeleteImage(id);
      this.layoutService.setActiveDeleteImage(true);
      });
  }

  closeModal() {
 this.moreOptionOpen = false;
  }

  optionsModalDelete() {
    this.confirmDeleteOpen = false;
  }

  optionsOtherProfile(index) {
    this.indexImg = index;
    this.imgSelected = this.images[index];
    this.optionOtherProfileModal = !this.optionOtherProfileModal;

  }

  openModalReportPublication() {
      this.isModalOpen = true;
     this.isModalReport = true;
    this.typeReport = 'report-publication';
  this.optionOtherProfileModal = false;

   }

  openModalHiddenPublication() {
         this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'hidden-publication';
     this.optionOtherProfileModal = false;

   }
   showModalCopy() {
     this.sharePublication = true;
     this.optionOtherProfileModal = false;
    this.linkRedirect = this.env.urlFront + 'preview/' + this.imgSelected.id;
   }

    closeSharedPublicationModal() {
    this.sharePublication = false;
    this.moreOptionOpen = false;
    }
  reportPublication() {
      this.usersService.report(this.imgSelected.userId._id, 'undefined', this.imgSelected.id).subscribe((res) => {
       this.toastService.presentToast('NOTIFICATION.REPORT_PUBLICATION', 2000);
       this.closeModal();
         this.optionOtherProfileModal = false;
       this.isModalOpen = false;
       setTimeout(() => {
         this.openModalHiddenPublication();
       }, 500);
     });

  }
  hiddenPublication() {
     this.usersService.excludePublication(this.imgSelected.id).subscribe((res) => {
      this.images = this.images.filter(image => image.id !== this.imgSelected.id);
      this.toastService.presentToast('NOTIFICATION.HIDDEN_PUBLICATION', 2000);
      this.closeModal();
        this.optionOtherProfileModal = false;
      this.isModalOpen = false;
      this.isModalReport = false;
    });
  }

  copySharedProfile(value) {
    this.copyActive = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.presentToast('NOTIFICATION.COPIED_CLIPBOARD', 2000);
    this.sharePublication = false;

  }


  confirmModal() {
      switch (this.typeReport) {

        case 'report-publication':
          this.reportPublication();
          break;
        case 'hidden-publication':
          this.hiddenPublication();
          break;
        default:
          break;
      }
    }

  snapIndexChange(event: any) {
    const activeIndex = event[0].snapIndex;

    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];

      const assetElement = activeSlide.querySelector('audio') ? activeSlide.querySelector('audio') : activeSlide.querySelector('video');

      if (assetElement) {

        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
  }

  onScroll() {
    this.scrollDown.emit(true);
  }

}
