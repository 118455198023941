import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { LoadingController } from '@ionic/angular';
import { ProductService } from '../../services/product/product.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
})
export class CreateProductComponent implements OnInit {
  @Output() openState = new EventEmitter();

  imagesLoad: any[] = [];
  loading: HTMLIonLoadingElement;

  productForm = new FormGroup({
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
    stock: new FormControl('', Validators.required),
    images: new FormControl([], Validators.required),
  });

  constructor(
    private loadingCtrl: LoadingController,
    private productService: ProductService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  takePicture() {
    const options: ImageOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      //webUseInput: true,
      source: CameraSource.Prompt,
      saveToGallery: false,
    };

    Camera.getPhoto(options).then((image) => {
      this.showLoading();

      const imageUrl = image.base64String;

      const file = this.getFileFromBase64(imageUrl, 'test');
      const formData = new FormData();

      formData.append('file', file);

      this.productService.saveImage(formData).subscribe((res: any) => {
        const newImage = {
          id: res._id,
          url: res.imageUrl,
        };
        const images = this.productForm.get('images').value;
        images.push(newImage.id);
        this.productForm.get('images').setValue(images);
        this.imagesLoad.push(newImage);

        this.loading.dismiss();
      },
        (err) => {
          this.loading.dismiss();
        });
    });
  }

  showLoading() {
    const options = {
      message: this.translateService.instant('NOTIFICATION.LOADING_IMAGE'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

  close() {
    this.openState.emit(false);
  }

  createProduct() {
    if (this.productForm.valid) {
      this.productService.saveProduct(this.productForm.value).subscribe();
      this.close();
    }
  }

  private getFileFromBase64(string64: string, fileName: string): File {
    const imageContent = atob(string64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }
}
