import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, OnDestroy,OnInit,HostListener } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { UsersService } from '../../services/users/users.service';



@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges, OnDestroy, OnInit{



  @ViewChild(IonModal) modal: IonModal;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() isModalOpen: Boolean = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() closeExternal: Boolean = false;
  @Input() content: any = {
    message: 'GENERAL.SUBSCRIPTION_VALUE'
  };
  @Input() title: string;
  @Input() icon = 'assets/icon/Icons Lust List - Pro YourList.svg';
  @Input() price: any = 30;
  @Input() myCash: any = 0;
  @Input() unSubscribe = false;
  @Input() isModalReport =false;
  @Input() typeReport = 'report-profile';
  @Input() isDebt = false;
  @Input() dataMenu: any;

  @Output() closeModal = new EventEmitter<boolean>();
  @Output() confirmModal = new EventEmitter<boolean>();
  @Output() externalPayment = new EventEmitter<boolean>();
  @Output() confirmPayIMenu = new EventEmitter<any>();
  @Output() confirmPayEMenu = new EventEmitter<any>();
  @Output() reasonReportData = new EventEmitter<any>();

  buttonEnabled = false;

  typeReasonReport = [];
  reasonReport = '';


  name: string;
  selectedCity: '';
  reportData: any = {
    img: 'assets/icon/Icons Lust List - Denunciar Perfil.svg',
    title: 'REPORT.REPORT_PROFILE_TITLE',
    info:'REPORT.REPORT_PROFILE_CONTENT'
  };

  constructor(
    private userService: UsersService
  ) {

  }

   @HostListener('document:keydown.escape', ['$event'])
  handleEscape() {
    this.cancel();
  }

  ngOnInit(): void {
    this.getReason();
  }

  ngOnDestroy(): void {
    this.isModalReport = false;
    this.typeReport = '';
  }


  getReason() {
    this.userService.getReasonsReport().subscribe((val: any) =>
    {
      this.typeReasonReport = val.reasons;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const change: any = changes;
    if (change.content?.currentValue) {
      this.buttonEnabled = true;
    }
    if (change.typeReport?.currentValue) {
      switch (this.typeReport) {
        case 'report-profile':
          this.reportData = {
            img: 'assets/icon/Icons Lust List - Denunciar Perfil.svg',
            title: 'REPORT.REPORT_PROFILE_TITLE',
            info: 'REPORT.REPORT_PROFILE_CONTENT'
          };
          break;

        case 'report-publication':
          this.reportData = {
            img: 'assets/icon/Icons Lust List - Denunciar Publicacion.svg',
            title: 'REPORT.REPORT_PUBLICATION_TITLE',
            info: 'REPORT.REPORT_PUBLICATION_CONTENT'
          };
          break;
        case 'hidden-publication':
          this.reportData = {
            img: 'assets/icon/Icons Lust List - Ocultar Post.svg',
            title: 'REPORT.HIDDEN_PUBLICATION_TITLE',
            info: 'REPORT.HIDDEN_PUBLICATION_CONTENT'
          };
          break;
          case 'unsuscribe':
          this.reportData = {
            img: 'assets/icon/Icons Lust List - Denunciar Perfil.svg',
            title: 'REPORT.CANCEL_SUB',
            info: 'REPORT.UNSUSCRIBE'
          };
          break;

        default:
          break;
      }
    }
  }

  externalPaymentModel() {
    this.externalPayment.emit(true);
    this.cancel();
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
    this.closeModal.emit(false);
    this.content = '';
  }

  confirm() {
    this.reasonReportData.emit(this.reasonReport);
    this.modal.dismiss(this.name, 'confirm');
    this.confirmModal.emit(false);
  }

  confirmMenuModelPayInternal() {
    this.confirmPayIMenu.emit(this.dataMenu);
  }
  confirmMenuModelPayExternal() {
    this.confirmPayEMenu.emit(this.dataMenu);
  }


}
