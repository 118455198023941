import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { FolderService } from '../../services/folder/folder.service';
import { LayoutService } from '../../services/layout/layout.service';
import { PublicationService } from '../../services/publication/publication.service';
import { ServiceOffertService } from '../../services/serviceOffert/service-offert.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { LoadingController } from '@ionic/angular';
import { UsersService } from '../../services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-folder-column',
  templateUrl: './folder-column.component.html',
  styleUrls: ['./folder-column.component.scss'],
})
export class FolderColumnComponent implements OnInit {
  nameAuthors = [];
  authors = [];
  filteredAuthors = [];
  authorSelected: any = 'all';
  user: any = null;
  quantityPost: any = '';
  quantitySubs: any = '';

  isShowOffert = false;
  offerts: any[] = [];
  myId = '';
  showOptions = false;

  showSeletImageProfile = false;
  isEditImage = false;
  typeImageToUpload = '';
  imageForEdit: ArrayBuffer | string = '';
  imageUrlProfile = '';
  loading: HTMLIonLoadingElement;

  constructor(
    private publicationService: PublicationService,
    private folderService: FolderService,
    private authService: AuthServiceService,
    private offertService: ServiceOffertService,
    private layoutService: LayoutService,
    private loadingCtrl: LoadingController,
    private usersService: UsersService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.myId = this.authService.getLocalStorage('userId');
    const userToParse = this.authService.getLocalStorage('user');
    this.user = JSON.parse(userToParse);
    this.publicationService.getMyGallery(0).subscribe(
      (res: any[]) => {
        this.quantityPost = res.length;
        res.forEach((img: any) => {
          if (!this.nameAuthors.includes(img.userAuhthorId.name)) {
            this.nameAuthors.push(img.userAuhthorId.name);
            this.authors.push({
              name: img.userAuhthorId.name,
              image: img.userAuhthorId.imageProfile?.imageUrl,
              id: img.userAuhthorId._id
            });
          }
        });
        this.filteredAuthors = this.authors;
        this.getSubscription();
      },
      (err) => {
        console.error(err);
      }
    );

    // this.offertService.getOfferts().subscribe((offerts: any[]) => {
    //   this.offerts = offerts;
    // });
  }

  //obtiene todas las subscriptiones del perfil
  getSubscription() {
    this.usersService.getSubscriptions().subscribe(data => {
      this.quantitySubs = data.length;
    });
  }

  /**
   * Obtiene el tipo de usuario (creador o usuario)
   */
  getUserType() {
    return this.authService.getLocalStorage('userType');
  }

  setAuthor(name: string, selected: any) {
    this.authorSelected = selected;
    this.folderService.setAuthor(name);
  }

  onSearch(event: any) {
    const value = event.target.value;
    if (value) {
      this.filteredAuthors = this.authors.filter((author: any) =>
        author.name.includes(value)
      );
    } else {
      this.filteredAuthors = this.authors;
    }
  }

  selectOffert(offert) {
    this.layoutService.setDataNewOffer(offert);
    this.layoutService.setShowNewOffer(true);
  }

  takePicture() {
    const options = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
    };
    this.typeImageToUpload = 'square';
    Camera.getPhoto(options).then((image) => {
      const imageUrl = image.base64String;
      const file = this.getFileFromBase64(imageUrl, 'test');
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageForEdit = reader.result;
        this.isEditImage = true;
      });
      reader.readAsDataURL(file);
    });
  }

  uploadImage(imageToUpload: string) {
    this.showLoading();
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });
      const formData = new FormData();
      formData.append('file', file);

      this.addImageProfile(formData);
      this.isEditImage = false;
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }

  showLoading() {
    const options = {
      message: this.translateService.instant('NOTIFICATION.LOADING_IMAGE'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

  /** Cambiar imagen del perfil */
  addImageProfile(imageData: FormData) {
    this.usersService
      .changeImageProfile(imageData)
      .subscribe((res: any) => {
        this.imageUrlProfile = res.imageProfile.imageUrl;
        this.user.imageProfile = res.imageProfile;
        this.authService.insertLocalStorage('user',JSON.stringify(this.user));
        this.loading.dismiss();
      },
        (err) => {
          this.loading.dismiss();
        });
  }



  private getFileFromBase64(string64: string, fileName: string): File {
    const imageContent = atob(string64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

}
