import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthServiceService } from '../service/auth-service.service';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ToastService } from 'src/app/shared/services/toast/toast.service';


export const retryCount = 3;
//export const retryWaitMilliSeconds = 1000;
@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  private env = environment;
  private urlAuth = this.env.API_SERVICE_LL_AUTH;
  constructor(
    private authService: AuthServiceService,
    private toastService: ToastService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getLocalStorage('token');
    const urlRefresh = this.urlAuth + '/auth/refresh-token';

    const isRegister = JSON.parse(this.authService.getLocalStorage('isRegister'));

    if (isRegister?.isRegister) {
      return next.handle(req);
    }

    if (req.url.includes('api.cloudinary')) {
      return next.handle(req);
    }

    /** toast request */
    if (req.method === ('POST' || 'PUT' || 'DELETE')) {
      this.toastService.presentToastMiddleSendInformation(req);
    }

    const headers = req.clone({
      headers: req.headers.set('x-access-token', `${token}`),
    });

    return next.handle(headers).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          /** Toast response */
          if (req.method === ('POST' || 'PUT' || 'DELETE')) {
            this.toastService.presentToastMiddleCheck(req);
          }
        }
      }),
      catchError((error) => {
        if (window.location.href.includes('/auth/login/add-data-profile/') || window.location.href.includes('profile-preview') || window.location.href.includes('preview')) {
          return;
        }
        if (error.url === urlRefresh) {
          this.authService.closeSession();
          return throwError(error);
        }

        if (error.status === 0) {
          this.toastService.presentToastMiddleError(
            'TOAST.ERROR.CONNECTION',
            3000
          );
        }

        if (error.status === 401) {
          return this.authService
            .refreshToken()
            .pipe(switchMap(() => next.handle(headers)));
        }

        if (error.status === 400) {
          this.toastService.presentToastMiddleError('TOAST.ERROR.PARAMS', 3000);
        }

        if (error.status === 402) {
          this.toastService.presentToastMiddleError(error.error, 3000);
        }

        if (error.status === 500) {
          this.toastService.presentToastMiddleError('TOAST.ERROR.FATAL', 3000);
        }

        return throwError(error);
      })
    );
  }
}
