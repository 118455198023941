import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  private env = environment;
  private urlAccount = this.env.API_SERVICE_LL_ACCOUNT;
  private urlModelExternalPayment = this.env.API_SERVICE_LL_MODEL_EXTERNAL_PAYMENT;
  private urlTransaction = this.env.API_SERVICE_LL_TRANSACTIONS;

  constructor(private http: HttpClient, private auth: AuthServiceService) {}

  myAccount() {
    return this.http.get(this.urlAccount + '/account/my-account');
  }

  getNumberAccount(userId: string) {
    return this.http.get(
      this.urlAccount + '/account/number-account/' + userId
    );
  }

  listTransactions(page = 0, size = 10) {
    return this.http.get(this.urlTransaction + `/transaction/list?page=${page}&size=${size}`);
  }
  listTransactionsLength(userId) {
    return this.http.get(this.urlTransaction + '/transaction/list-transaction-length/'+ userId );
  }

  registerTransaction(payload: any) {
    return this.http.post(this.urlTransaction + '/transaction/register', payload);
  }

  registerTransactionForUserIds(payload: any) {
    return this.http.post(
      this.urlTransaction + '/transaction/register-for-usersIds',
      payload
    );
  }

  registerFormForWithdrawal(payload: any) {
     return this.http.post(
      this.urlTransaction + '/transaction/register-withdrawal',payload
    );
  }

  registerTransactionFrozen(payload: any) {
    return this.http.post(
      this.urlTransaction + '/transaction/register-frozen',
      payload
    );
  }

  registerPay(payload: any) {
    return this.http.post(
      this.urlTransaction + '/transaction/register-manual-pay',
      payload
    );
  }

  requestPayment(payload: any) {
    return this.http.post(this.urlTransaction + '/transaction/payment', payload);
  }

  getPaymentMethods() {
    return this.http.get(this.urlTransaction + '/transaction/payment-methods');
  }

  getUserPayments() {
    return this.http.get(this.urlTransaction + '/transaction/user-payments');
  }

  saveVoucher(payload) {
    return this.http.post(`${this.env.urlServiceFile}/image-no-save`, payload);
  }

  generateInvoiceCriptoPayment(amount) {
    return this.http.post(this.urlTransaction +'/transaction/create-invoice-to-payment-cripto', {amount});
  }

  //AQUI
  generateInvoiceCriptoPaymentToSubscriptionPayment() {
    return this.http.post(this.urlTransaction +'/transaction/create-invoice-to-payment-cripto-to-subscription-platform', {});
  }

  getWithdrawals(page, size) {
    const params = {
      page, size
    };
    return this.http.get(this.urlTransaction + '/transaction/get-withdrawal', { params });
  }

  getAllTransacctionExternal(page,size){
      const params = {
      page, size
    };
    return this.http.get(this.urlModelExternalPayment + '/model-external-payment/my-all-transaction-external' , { params });
  }
}
