import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  LoginAuth,
  registerSteps,
  RegisterTypeForm,
  ResetPassword,
  UpdatePassword,
  ValidateToken,
  VerifyAccount,
} from 'src/app/core/models/auth.model';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {

  pageLoaded = false;

  isDeviceIOS = false;

  auth = new BehaviorSubject<boolean>(false);
  auth$ = this.auth.asObservable();

  user = new BehaviorSubject<any>(null);
  user$ = this.user.asObservable();

  stepsRegister = new BehaviorSubject<registerSteps>(null);
  stepsRegister$ = this.stepsRegister.asObservable();

  registerDocs = new BehaviorSubject<string[]>([]);
  registerDocs$ = this.registerDocs.asObservable();

  registerProfile = new BehaviorSubject<string[]>([]);
  registerProfile$ = this.registerProfile.asObservable();

  showComponentAddPublication = new BehaviorSubject<boolean>(false);
  showComponentAddPublication$ =
    this.showComponentAddPublication.asObservable();

  private env = environment;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private URL = this.env.API_SERVICE_LL_AUTH + '/auth';
  private urlServiceFile = this.env.serviceFile;

  constructor(private http: HttpClient, private router: Router, private layoutService: LayoutService,) {}

 login(payload: LoginAuth): Observable<LoginAuth> {
    return this.http.post<LoginAuth>(this.URL + '/login', payload);
}

  async handleLogin(payload: LoginAuth) {
  try {
    const response = await this.login(payload).toPromise();
    // Maneja la respuesta exitosa aquí
    console.log('Login successful:', response);
  } catch (error) {
    // Maneja el error aquí
    console.error('Error during login:', error.message || error);
    // Puedes mostrar un mensaje de error en la UI
  }
}


  register(payload) {
    const language = localStorage.getItem('language');
    payload.user.lang = language;
    return this.http.post(this.URL + '/register', payload);
  }

  verifyAccount(payload: VerifyAccount) {
    return this.http.post<VerifyAccount>(this.URL + '/verify-account', payload);
  }

  resetPassword(payload: ResetPassword) {
    return this.http.post<ResetPassword>(this.URL + '/reset-password', payload);
  }

  validateToken(payload: ValidateToken) {
    return this.http.post<ValidateToken>(this.URL + '/validate-token', payload);
  }

  updatePassword(payload: UpdatePassword) {
    return this.http.post<UpdatePassword>(
      this.URL + '/update-password',
      payload
    );
  }

  registerDocument(payload) {
    return this.http.post(this.urlServiceFile + '/upload-image', payload);
  }

  registerAddDocuments(payload) {
    return this.http.put(this.URL + '/register-add-documents', payload);
  }

  initSession(data: LoginAuth) {
    const token = data.token;
    const refreshToken = data.refresh_token;
    const userType = data.user.type.type;
    const userId = data.user._id;
    this.setUser(data.user);
    this.insertLocalStorage('token', token);
    this.insertLocalStorage('refresh_token', refreshToken);
    this.insertLocalStorage('userType', userType);
    this.insertLocalStorage('userId', userId);
    this.insertLocalStorage('init_session', true);
    this.removeLocalStorage('token-temp');
    this.removeLocalStorage('register-state');
    this.removeLocalStorage('isRegister');
    this.auth.next(true);
  }

  isAuthenticated() {
    const registerState = JSON.parse(
      this.getLocalStorage('register-state')
    );
    const token = this.getLocalStorage('token');
    if (token && !registerState?.setImageProfileClient) {
      if (!this.auth.getValue()) {
        this.auth.next(true);
      }
      return true;
    } else {
      if (this.auth.getValue()) {
        this.auth.next(false);
      }
      return false;
    }
  }

  isVerify() {
    return this.getLocalStorage('verify');
  }

  refreshToken() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const data = { refresh_token: this.getLocalStorage('refresh_token') };
    return new Observable((observer) => {
      this.http
        .post(this.URL + '/refresh-token', data)
        .subscribe((resp: any) => {
          this.insertLocalStorage('token', resp.token);
          this.insertLocalStorage('refresh_token', resp.refresh_token);
          observer.next();
          observer.complete();
        });
    });
  }

closeSession() {
  this.auth.next(false);

  // Remover datos principales de sesión
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('userType');
  localStorage.removeItem('userId');

  // Remover tokens temporales y estados de registro si existen
  if (localStorage.getItem('token-temp')) {
    localStorage.removeItem('token-temp');
    localStorage.removeItem('register-state');
    localStorage.removeItem('isRegister');
  }

  // Remover deseos específicos si existen
  if (localStorage.getItem('publicationForWish')) {
    localStorage.removeItem('publicationForWish');
  }

  if (localStorage.getItem('movieForWish')) {
    localStorage.removeItem('movieForWish');
  }

  // Remover datos relacionados con el chat
  localStorage.removeItem('chatRoomData'); // Datos completos del chat
  localStorage.removeItem('chatRoomId');   // ID del chat actual

  // Remover claves adicionales específicas
  localStorage.removeItem('language');
  localStorage.removeItem('lastSuccessfullAd');
  localStorage.removeItem('totalCalls');
  localStorage.removeItem('totalCallsTimeout');

  // Limpieza específica de la selección de reuniones
  this.layoutService.setMeetingSelect(null);

  // Redirigir al usuario a la pantalla de login
  this.router.navigate(['/auth/login']);
}


  /* LocalStorage*/
  insertLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getLocalStorage(key: string) {
    //JSON.parse(localStorage.getItem(key))
    return localStorage.getItem(key);
  }

  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  getStepRegister() {
    return this.stepsRegister$;
  }

  setStepRegister(step: registerSteps) {
    this.stepsRegister.next(step);
  }

  getRegisterDocs() {
    return this.registerDocs$;
  }

  setRegisterDocs(docs) {
    this.registerDocs.next(docs);
  }

  getRegisterProfile() {
    return this.registerProfile$;
  }

  setRegisterProfile(docs) {
    this.registerProfile.next(docs);
  }

  getShowComponentAddPublication() {
    return this.showComponentAddPublication$;
  }

  setShowComponentAddPublication(show: boolean) {
    this.showComponentAddPublication.next(show);
  }

  getTokenForAddDataProfile(userId) {
    return this.http.get(
      this.URL + '/get-token-by-register-data-profile/' + userId
    );
  }

  loginForce(token: string) {
    return this.http.get(this.URL + '/login-force', { params: { token } });
  }

  createAccountToUserClient(userId) {
    return this.http.post(this.URL + '/create-account-client', {userId});
  }

  setUser(user) {
    const userValue = JSON.stringify(user);
    this.insertLocalStorage('user', userValue);
    this.user.next(user);
  }

  getUser() {
    return this.user$;
  }

    deleteUserProfile(payload) {
      return this.http.post(this.URL + '/delete-user', payload);
  }
  // userId, email, account, name, userName, typeUser


  /** este */
  reSendEmailVerification( email?) {
    return this.http.post(this.URL + '/resend-email-verification', { email } );
  }
}
