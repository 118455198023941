import { Component, OnInit, AfterViewInit ,Renderer2, ViewChild,ElementRef} from '@angular/core';

@Component({
  selector: 'app-ads-banner-terras',
  templateUrl: './ads-banner-terras.component.html',
  styleUrls: ['./ads-banner-terras.component.scss'],
})
export class AdsBannerTerrasComponent implements AfterViewInit {
   @ViewChild('adContainer', { static: false }) adContainer: ElementRef;
  constructor(private renderer: Renderer2) {

  }

    ngAfterViewInit(): void {
    this.loadAdScript();
    }

loadAdScript(): void {
  const script3 = this.renderer.createElement('script');
  script3.type = 'text/javascript';
  script3.text = `
    atOptions = {
      'key' : '0156b59be529b346cf7932d9001841c3',
      'format' : 'iframe',
      'height' : 600,
      'width' : 160,
      'params' : {}
    };
  `;
  this.renderer.appendChild(this.adContainer.nativeElement, script3);

  const script4 = this.renderer.createElement('script');
  script4.type = 'text/javascript';
  script4.src = '//sillinesswoollen.com/0156b59be529b346cf7932d9001841c3/invoke.js';
  this.renderer.appendChild(this.adContainer.nativeElement, script4);

  setTimeout(() => {
    const adIframe = this.adContainer.nativeElement.querySelector('iframe');
    if (adIframe) {
      adIframe.style.width = '160px';
      adIframe.style.height = '600px';
    }
  }, 2000);
}




}
