// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiAuth: 'http://localhost:3001/api/v1',
  apiUrlSocket: 'https://chat-notification.dev.lust-list.com',
  agoraAppId: '0f4de9c380b64bb6ac28cc12e99a821e',
  urlFront: 'https://lust-list.com/',
  urlServiceFile: 'https://service-files-dev.lust-list.com/api/v1',
  serviceFile: 'https://service-file.lust-list.com',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLOUD_NAME: 'dj918veyp',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CLOUD_UPLOAD_PRESET: 'eaauwnl1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_FILE_LL: 'https://files.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_AUTH: 'https://auth.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_TICKET: 'https://ticket.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_PAYMENT_METHOD: 'https://payment-method.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_ADS: 'https://ad.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_BLACKLIST: 'https://blacklist.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_ACCOUNT: 'https://account.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_REPORT:'https://report.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_MOVIES: 'https://movies.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_MENU_MODEL: 'https://menu-model.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_GET_SUBSCRIPTION: 'https://subscription.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_CHAT: 'https://chat-notification.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_MODEL_EXTERNAL_PAYMENT: 'https://model-external-payment.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_PUBLICATION: 'https://publications.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_WISH: 'https://wish.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_NOTIFICATION: 'https://chat-notification.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_USER: 'https://user-server.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_METRICS: 'https://metrics.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_STATE: 'https://states.dev.lust-list.com/api/v1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  API_SERVICE_LL_TRANSACTIONS: 'https://transaction.dev.lust-list.com/api/v1',


};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
