import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { ProductService } from '../../services/product/product.service';
import { SocketService } from '../../services/socket/socket.service';

@Component({
  selector: 'app-billing-detail',
  templateUrl: './billing-detail.component.html',
  styleUrls: ['./billing-detail.component.scss'],
})
export class BillingDetailComponent implements OnInit, OnChanges {

  @Input() billingId: any;
  @Output() open = new EventEmitter<boolean>();
  @Output() changeStatusBilling = new EventEmitter();

  comentaryOfSeller = new FormControl('');
  comentaryOfClient = new FormControl('');
  billing: any;

  address: any = null;

  isGeneratorModalOpen = false;
  isClientModalOpen = false;

  myAccountId;
  sellerAccountId;

  myUser: any;


  userId = '';

  constructor(
    private productService: ProductService,
    private socket: SocketService,
    private accountsService: AccountsService,
    private authService: AuthServiceService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit() {
    this.accountsService.getMyAccount().subscribe(
      (res: any) => this.myAccountId = res._id,
      (error) => console.error(error)
    );
    const user = this.authService.getLocalStorage('user');
    this.myUser = JSON.parse(user);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.billingId) {
      this.getBilling();
    }
  }

  close() {
   this.layoutService.setShowModelBillingDetail(false);
  }

  getBilling() {
    this.productService.getBilling(this.billingId).subscribe((billing: any) => {
      this.userId = this.myUser._id != billing?.sellerId ? billing?.sellerId : billing?.userId;
      this.billing = billing;
    });
  }

  markProductAsSended() {
    const payload = {
      address: this.address,
      comments: this.comentaryOfSeller.value
    };
    this.productService.markProductAsSended(this.billing._id, payload).subscribe(
      (res) => {
        this.isGeneratorModalOpen = false;
        this.address = null;
        this.comentaryOfSeller.reset();
        this.getBilling();
      },
      (error) => {
        console.error(error);
      }
    );
    const message = 'NOTIFICATION.SEND_PRODUCT';
    if (this.myUser.contentManagerId) {
      this.sendNotification(this.billing.userId, this.myUser.contentManagerId, message);
    } else {
      this.sendNotification(this.billing.userId, this.myUser._id, message);
    }
  }

  markProductAsArrived() {
    this.productService.markProductAsArrived(this.billing._id, { comments: this.comentaryOfSeller.value }).subscribe(
      (res) => {
        this.isGeneratorModalOpen = false;
        this.comentaryOfSeller.reset();
        this.getBilling();
      },
      (error) => {
        console.error(error);
      }
    );
    const message = 'NOTIFICATION.PRODUCT_ARRIVED';
    if (this.myUser.contentManagerId) {
      this.sendNotification(this.billing.userId, this.myUser.contentManagerId, message);
    } else {
      this.sendNotification(this.billing.userId, this.myUser._id, message);
    }
  }

  markProductAsReceived() {
    this.productService.markProductAsReceived(this.billing._id, { comments: this.comentaryOfClient.value }).subscribe(
      (res) => {
        this.isClientModalOpen = false;
        this.comentaryOfClient.reset();
        this.getBilling();
      },
      (error) => {
        console.error(error);
      }
    );
    const message = 'NOTIFICATION.RECEIVED_PRODUCT';
    this.sendNotification(this.billing.sellerId, this.myUser._id, message);
  }

  clientAccording(isAccording) {
    const payload = {
      accept: isAccording,
      close: !isAccording,
      comments: this.comentaryOfClient.value
    };
    this.productService.clientAccording(this.billing._id, payload).subscribe(
      (res) => {
        this.isClientModalOpen = false;
        this.comentaryOfClient.reset();
        this.getBilling();
        if (isAccording) {
          this.getSellerAccountId(this.billing.sellerId);
        }
      },
      (error) => {
        console.error(error);
      }
    );
    const message = isAccording ? 'NOTIFICATION.SATISFIED_WHIT_PRODUCT' :  'NOTIFICATION.DISSATISFIED_WHIT_PRODUCT';
    this.sendNotification(this.billing.sellerId, this.myUser._id, message);
  }

  getSellerAccountId(sellerId) {
    this.accountsService
      .getUserAccount(sellerId)
      .subscribe(
        (res: any) => this.liberateFunds(res._id),
        (error) => console.error(error)
      );
  }

  liberateFunds(sellerAccountId) {
    const payload = {
      accountClientId: this.myAccountId,
      accountSellerId: sellerAccountId,
      transactionId: this.billing.payment
    };
    this.productService
      .liberateFunds(payload)
      .subscribe((res) => console.log(res), (error) => console.error(error));
  }

  // Send a notification when ocurrs and action
  // @params userReceiver indicates the user to receive the notification
  // @params userSender indicates the user to send the notification
  sendNotification(userReceiver: string, userSender: string, message: string) {
    const notification = {
      userReceiver,
      userSender,
      type: 'product',
      contextId: this.billing._id,
      message
    };
    this.socket.sendNotification(notification);
  }

  addressValue(event) {
    this.address = event.detail.value;
  }


  goToChat() {
    const component: DataComponent = {
      component: 'chat',
      contextId: this.userId,
      billingRef: this.billing
    };
    this.layoutService.setChatRoom(component);
  }
}
