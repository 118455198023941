import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IonContent, LoadingController, MenuController, ScrollDetail, ViewDidEnter } from '@ionic/angular';
import { ChatsService } from 'src/app/shared/services/chats/chats.service';
import { AccountsService } from 'src/app/shared/services/accounts/accounts.service';
import {
  SocketService,
  Message,
} from 'src/app/shared/services/socket/socket.service';

import { TransactionsService } from 'src/app/shared/services/transactions/transactions.service';
import { AuthServiceService } from '../../../features/auth/service/auth-service.service';
import { Account } from 'src/app/core/models/account.model';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { UsersService } from '../../services/users/users.service';
import { Subscription, timeout } from 'rxjs';
import { DateService } from '../../services/utils/date.service';
import { Router, NavigationEnd } from '@angular/router';
import { Camera, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';
import { PublicationService } from '../../services/publication/publication.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from '../../services/file/file.service';

import { HttpRequest } from '@angular/common/http';
import { ToastService } from 'src/app/shared/services/toast/toast.service';


interface Cash {
  cash: number;
}

interface MyUser {
  _id: string;
  name: string;
  imageProfile: string;
  totalMoney: number;
}

@Component({
  selector: 'app-chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss'],
})
export class ChatRoomComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('body') body: IonContent;
  @ViewChild('containerHeader') containerHeader: HTMLElement | any;
  @ViewChild('containerFooter') containerFooter: HTMLElement | any;
  @ViewChild('inputGallery') inputGallery: ElementRef;
  @ViewChild('inputGalleryImage') inputGalleryImage: ElementRef;
  @ViewChild('inputGalleryAudio') inputGalleryAudio: ElementRef;
  /** refactor captura de videos */
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('recordedAudio') recordAudioElementRef: ElementRef;
  @Input() userId = '';
  @Input() wishRef: any = '';
  @Input() offertRef: any = '';
  @Input() billingRef: any = '';

  idRoom: string;
  messages: any[] = [];
  chatAvailableByWish = false;

  recordVideoElement: any;
  recordAudioElement: any;
  isPlayVideo = false;
  isPlayAudio = false;
  videoTemp: File;
  audioTemp: File;

  page = 0;
  size = 20;

  /**Usuario con el que has ingresado */
  myUser = {
    _id: '',
    name: '',
    imageProfile: '',
    totalMoney: 0,
  };
  /**Usuario al otro lado de la pantalla */
  userReceiver = {
    id: '',
    name: '',
    userName: '',
    imageProfile: '',
    incognito: false
  };

  // myTotalMoney = 0;
  showModal = false;
  detail = false;

  /**Cuenta del usuario al otro lado de la pantalla */
  idAccountClient = '';

  /**Cuenta del usuario con el que ingresas */
  idAccountSeller = '';
  adminChat = false;
  optionsModalAdmin = false;
  type = 0;

  pageLoaded = false;
  activeUsers: any = [];
  activeUserSubscription: Subscription;
  activeRouterSubscription: Subscription;

  online = '';
  idUserConection: any;
  isIconClose = true;

  imageForEdit: ArrayBuffer | string = '';
  isEditImage = false;
  loading: HTMLIonLoadingElement;

  addFile = false;
  scrollAvailable = false;
  scrollEvent: any;
  showLoader = false;
  isDeviceIOS = false;

  userStates: any = [];
  subscriptionViewAllStatusUser: Subscription;
  activeDeleteImageSubscription: Subscription;
  usersAllViewStates = [];
  subscriptionMessages: Subscription;
  height: any;
  fullScreen = false;


  userSubscriptions = [];
  userChatsubscriptions = [];
  listPaymentMethod = [];
  showPaymentMethodContent = false;
  showListPaymentMethod = false;
  contentPaymentMethods: any;
  subscriptionUser: Subscription;
  subscriptionChatUser: Subscription;
  isModalOpen = false;
  priceForChatSubscription = 100;
  myFunds = 0;
  messageSubscription: any = {};
  chatAvailable = false;
  onChatReasson = false;
  chatReasson = '';
  modalPaymentMethod = false;
  reassonByAdmin: any;
    imageDefault = '../../../../assets/images/profile.png';

  wishId: any;
  constructor(
    private socket: SocketService,
    private transactionsService: TransactionsService,
    private chatService: ChatsService,
    private accountsService: AccountsService,
    private layoutService: LayoutService,
    private authService: AuthServiceService,
    private userService: UsersService,
    private dateService: DateService,
    private router: Router,
    private publicationService: PublicationService,
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private menu: MenuController,
    private fileService: FileService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.height = this.calculateHeight();
    this.getPaymentMethods();
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const myUser = this.authService.getLocalStorage('user');
    const myUserParsed = JSON.parse(myUser);
    this.myUser._id = myUserParsed._id;
    this.activeUserSubscription = this.userService.getUsersActive().subscribe(user => {
      this.activeUsers = user;
    });
    setTimeout(() => {
      //if (this.messages.length) {
        this.pageLoaded = true;
      //}
    }, 500);

    const url = window.location.pathname;
    if (url.includes('meeting')) {
      this.isIconClose = false;
    } else {
      this.isIconClose = true;
    }

    this.activeRouterSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('meeting')) {
          this.isIconClose = false;
        } else {
          this.isIconClose = true;
        }
      }
    });

    this.subscriptionViewAllStatusUser = this.layoutService.getNewUserViewAllStates().subscribe((usersAllViewStates: string[]) => {
      this.usersAllViewStates = usersAllViewStates;
    });

    this.subscriptionUser = this.userService.getSubscriptions().subscribe((subs) => {
      this.userSubscriptions = subs;
    });

    this.subscriptionChatUser = this.userService.getChatSubscriptions().subscribe((subs) => {
      this.userChatsubscriptions = subs;
    });
  }

  ngOnDestroy(): void {
    if (this.activeUserSubscription) {
        this.activeUserSubscription.unsubscribe();
    }
    if (this.activeRouterSubscription) {
      this.activeRouterSubscription.unsubscribe();
    }
    if (this.subscriptionViewAllStatusUser) {
      this.subscriptionViewAllStatusUser.unsubscribe();
    }
    if (this.subscriptionMessages) {
      this.subscriptionMessages.unsubscribe();
    }
    if (this.subscriptionUser) {
      this.subscriptionUser.unsubscribe();
    }

    if (this.subscriptionChatUser) {
      this.subscriptionChatUser.unsubscribe();
    }

  }

  evaluateStates() {
    let state = null;
    if (this.userStates.length === 0 || this.userId === this.myUser._id) {
      state = 'noState';
    // eslint-disable-next-line @typescript-eslint/no-shadow, max-len
    } else if (this.userStates.every((state) => state.seen.includes(this.myUser._id)) || this.usersAllViewStates.includes(this.myUser._id)) {
      state = 'allView';
    }
    return state;
  }

  calculateHeight() {
    const height = window.innerHeight - 116;
    return 'height: ' + height +'px';
  }

  openStateModal2() {
    this.layoutService.setShowStateModal(this.userStates);
  }

  openWishdetail(wish) {
    this.menu.close('menu2');
    const component: DataComponent = {
      component: 'wish-detail',
      contextId: wish._id,
    };
      this.layoutService.setDataWishDetailModal(component);
        this.layoutService.setShowModalWishDetail(true);
  }

  ionViewWillLeave(): void {
     if (this.activeUserSubscription) {
        this.activeUserSubscription.unsubscribe();
    }
    if (this.activeRouterSubscription) {
      this.activeRouterSubscription.unsubscribe();
    }

  }

  onScroll(event) {
    const scrollTop = event.detail.scrollTop;
    if (scrollTop === 0 && this.scrollAvailable) {
      this.getMoreChat();
    }
  }


  isActive() {
     return this.activeUsers.find(userActive => userActive.user === this.userId)?.active;
  }
  lastConection() {
    const date = this.activeUsers.find(userActive => userActive.user === this.userId)?.lastInteraction;
    return  this.dateService.getDatelastConection(date);
  }

  close() {
    if (window.outerWidth > 992) {
      const component: DataComponent = {
        component: 'recomendation',
        contextId: '',
      };
      this.layoutService.setComponent(component);
    } else {
      this.menu.close('menu2');
    }

  }

  navigateTo(id: any) {
    if (this.type === 1) {
      this.router.navigate(['/profile/' + id]);
    this.menu.close('menu2');
    }
    if (this.type === 2) {
   this.layoutService.setShowModalClientInfo(true);
      this.layoutService.setShowModalDataClientInfo(id);
    }
  }

  formatImageByRoom(url) {
    return this.fileService.transformImageByChatRoom(url);
  }

  getGifByVideoUrl(url) {
    return this.fileService.getGifByVideoUrl(url);
  }

   playFullScreenIos(url) {
    const lastVideo = document.getElementById('last-video');

    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    video.src = this.fileService.transformUrlVideo(url);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id='last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
     video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

     };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change: any = changes;
    const myUser = this.authService.getLocalStorage('user');
    const myUserParsed = JSON.parse(myUser);
    this.myUser._id = myUserParsed._id;

    if (change?.userId?.currentValue) {
      this.chatAvailableByWish = change?.wishRef ? true : false;
      if (this.subscriptionMessages) {
        this.subscriptionMessages.unsubscribe();
      }
      this.page = 0;
      this.messages = [];
      this.scrollAvailable = false;
      this.userId = change?.userId?.currentValue;
      if (change.userId.currentValue === 'admin') {
        this.adminChat = true;
        this.optionsModalAdmin = true;
        this.getAdminMessages();
        this.getAdminChat();
        if (this.reassonByAdmin === undefined) {
          this.setReassonByAdmin(null);
        }
      } else {
        this.getMessages();
        this.adminChat = false;
        this.optionsModalAdmin = false;
        this.accountsService
          .getUserAccount(this.userId)
          .subscribe((res: any) => {
            this.idAccountSeller = res._id;
          });
        this.idAccountClient = myUserParsed.accountId;

        this.createChat(this.userId);

        this.userService.getUserStates(this.userId).subscribe(
          (res: any) => {
            this.userStates = res;
          }, err => console.log(err)
        );
        this.accountsService.getMyAccount().subscribe(
          (res: any) => this.myFunds = res.amount,
          (error) => console.error(error)
        );

        this.userService.getQuotizationChatSubscription(this.userId).subscribe(
          (res: any) => {
            this.priceForChatSubscription = res.subscriptionPrice;
          },
          (error) => console.error(error)
        );
      }
    }
  }


  getPaymentMethods() {
    this.accountsService.getPaymentMethods().subscribe((val: any) => {
      this.listPaymentMethod = val.map(el => {
        delete el.password;
        delete el.user;
        delete el.enabled;
        return el;
      });
  });
  }
  backPayment() {
    this.showListPaymentMethod = true;
    this.showPaymentMethodContent = false;
  }

  selectPaymentMethods(data) {
    this.showListPaymentMethod = false;
    this.showPaymentMethodContent = true;
    this.listPaymentMethod.forEach(el => {
      if (data === el.title) {
        this.contentPaymentMethods = el;
        }
      });
  }

  openModalNewWish() {
    const wishData = {
      userId: this.userId
    };
    this.layoutService.setDataWishComponentNewWish(wishData);
    this.layoutService.setShowComponentNewWish(true);
    this.layoutService.isBeforeChat = true;
    this.menu.close('menu2');
  }

  /**
   * Metodo para enviar al final del chat
  /**
   * Construye la estructura del mensaje y envia una notificación al usuario
   *
   * @param msg Contenido del mensaje a construir
   */
  send(msg: any): void {
     if (!this.chatIsAvailable()) {
       this.isOpenModalSuscribeToChat();
       return;
     }

    if (msg.value === ' ') {
       return;
     }
  if (msg.value !== '') {
       const newMessage: Message = {
         chatId: this.idRoom,
       message: msg.value,
         roomId: this.idRoom,
        userId: this.myUser._id,
         wishRef: this.wishRef?._id,
         offertRef: this.offertRef?._id,
         billingRef: this.billingRef?._id
       };
      msg.value = '';
       if (this.adminChat) {
         this.socket.sendMessageAdmin(newMessage);
       } else {
        this.socket.sendMessage(newMessage);
       }
      this.sendNotification('message');
     }
     this.wishRef = '';
     this.offertRef = '';
     this.billingRef = '';
  }

  blockSend(msg: any) {
  if (msg?.value?.trim()) {
  return false;
  }else {
  return true;
  }
  }

  viewAllMessages() {
    const messagesId = this.messages.filter(msg => !msg.view && msg.userId != this.myUser._id).map(msg => msg._id);
    this.chatService.viewChats(messagesId).subscribe((res) => {
      this.chatService.setChatViewId(this.idRoom);
    });
  }

  /**
   * Envia una notificación al usuario realizar una acción
   *
   * @param type Indica el tipo de la notificación (Chat, Deseo, Producto)
   */
  sendNotification(type: string): void {
    const notification = {
      userReceiver: this.userId,
      userSender: this.myUser._id,
      type,
    };
    this.socket.sendNotification(notification);
  }

  /**
   * Obtiene la cantidad de una cuenta
   *
   * @param user Los datos del usuario
   */
  getAmountAccount(user: MyUser): void {
    this.transactionsService
      .getNumberAccount(user._id)
      .subscribe((res: Account) => (this.myUser.totalMoney = res.frozenAmount));
  }

  /**
   * Obtiene la data del usuario
   *
   * @param users todos los usuarios
   * @param userId el id del usuario a buscar
   * @returns la data del usuario que coincida con userId
   */
  getDataUser(users: any[], userId: string): any {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const user = users.find((user: any) => user._id === userId);
    this.type = user.type.type;
    return {
      id: user._id,
      name: user.name,
      userName: user.userName,
      imageProfile: !user.imageProfile
        ? 'assets/icon/usuario-30.png'
        : user.imageProfile.imageUrl,
      incognito: user.incognito
    };
  }

  setReassonByAdmin(reason) {
    this.chatService.setReassonByAdmin(this.idRoom, reason).subscribe((res: any) => {
       this.optionsModalAdmin = false;
      this.reassonByAdmin = res?.reassonByAdmin;
      if (reason === 'WITHDRAWAL') {
        this.modalPaymentMethod = true;
        this.router.navigate(['/withdraw']);
        this.layoutService.setShowshowModalWithdraw(true);
      }
      if (reason === 'DEPOSIT') {
        this.modalPaymentMethod = true;
    this.router.navigate(['/deposit']);
    }
    });
  }

  transformDataText(text) {
    if (text === 'WITHDRAWAL') {
    return 'GENERAL.WITHDRAWAL';
    }  if (text === 'DEPOSIT') {
    return 'GENERAL.DEPOSIT';
    }
  }
  /**
   * Crea el chat, obtiene el monto del usuario actual y obtiene los datos del usuario a conversar
   *
   * @param userId el id del usuario para conversar
   */
  createChat(userId: string): void {
    this.showLoader = true;
    this.chatService.getChat(userId, this.page, this.size).subscribe((res: any) => {
      this.wishId = res.sextingWish;
      this.onChatReasson = res.onChatReasson;
      this.chatReasson = res.chatReasson;

      this.userReceiver = this.getDataUser(res.usersId, this.userId);
      this.myUser = res.usersId.find((user) => user._id !== userId);
      this.getAmountAccount(this.myUser);
      this.messages = this.organiceMessagesByDay(this.messages.concat(res.messages.reverse()));
      this.idRoom = res._id;
      this.socket.createRoomChat(this.idRoom);
      this.viewAllMessages();
      setTimeout(async () => {
        this.body.scrollToBottom(500);
        this.scrollAvailable = true;
        this.page++;
        this.showLoader = false;
        }, 1000);
    });
  }

  organiceMessagesByDay(messages) {
    const organizedMessages = [];
    const messagesByDay = new Map();

    messages.forEach((message) => {
      const date = new Date(message.createdAt).toDateString();
      const dayMessages = messagesByDay.get(date) || [];

      dayMessages.push(message);
      messagesByDay.set(date, dayMessages);
    });

    messagesByDay.forEach((messages, date) => {
      organizedMessages.push({
        _id: null,
        userId: 'system-date',
        message: this.translateService.instant(this.formatDateByMessage(date)),
        createdAt: null,
        __v: 0,
        view: true,
      });

      organizedMessages.push(...messages);
    });
    return organizedMessages;
  }

  //implementado formtado a fecha
  formatDateByMessage(date) {
    const data = this.dateService.formatDateToCustomString(date);
    return data;
  }

  // implement by scroll infinite to up
  getMoreChat() {

    if (this.userId !== 'admin') {
      this.chatService.getChat(this.userId, this.page, this.size).subscribe(async (res: any) => {
        if (res.messages.length > 0) {
          const oldHeight = (await this.body.getScrollElement()).scrollHeight;
          this.messages = res.messages.reverse().concat(this.messages);
          this.showLoaderAndPositionAfterGetMoreMessages(oldHeight);
          this.page++;
          this.viewAllMessages();
        } else {
          this.scrollAvailable = false;
        }
      });

    } else {
      this.chatService.getChatWithAdmin(this.myUser._id, this.page, this.size).subscribe(async (res: any) => {
        if (res.messages.length > 0) {
          const oldHeight = (await this.body.getScrollElement()).scrollHeight;
          this.messages = res.messages.reverse().concat(this.messages);
          this.showLoaderAndPositionAfterGetMoreMessages(oldHeight);
          this.page++;
          this.viewAllMessages();
        } else{
          this.scrollAvailable = false;
        }
      });
    }

  }

  showLoaderAndPositionAfterGetMoreMessages(oldHeight) {
    this.showLoader = true;
    setTimeout(async () => {
      const scroll = await this.body.getScrollElement();
      const margingMeesages = 10;
      const { offsetHeight, scrollHeight } = scroll;
      // eslint-disable-next-line max-len
      const heightHeaderAndFooter = this.containerHeader.nativeElement.offsetHeight + this.containerFooter.nativeElement.offsetHeight + margingMeesages;

      const scrollPoint = (scrollHeight + heightHeaderAndFooter) - (oldHeight + offsetHeight);
      this.body.scrollToPoint(0, scrollPoint, 0);
      this.showLoader = false;
    }, 1000);
  }

  formatDate(date) {
    return this.dateService.getDatePublish(date);
  }

  getAdminChat(): void {
    this.chatService.getChatWithAdmin(this.myUser._id).subscribe((res: any) => {

      this.reassonByAdmin = res.reassonByAdmin;
      this.userReceiver = {
        id: '',
        name: 'Admin',
        userName: 'Admin',
        imageProfile: '',
        incognito: false
      };
      this.messages = this.messages.concat(res.messages.map(el => {
        if (el.fromAdmin) {
         delete el.userId;
       }
        return el;
      }));
      this.viewAllMessages();
      // eslint-disable-next-line no-underscore-dangle
      this.idRoom = res._id;
      this.socket.createRoomChat(this.idRoom);
      setTimeout(() =>
        this.body.scrollToBottom(500), 500
      );
    });
  }

  /**
   * Obtiene todos los mensajes del chat
   */
  getMessages(): void {
    this.subscriptionMessages = this.socket.getMessage().subscribe((message: Message) => {
      if (message.stopSexting) {
        this.onChatReasson = false;
        this.chatReasson = '';
      const component: DataComponent = {
       component: 'wish-detail',
       contextId: this.wishId,
     };
     this.layoutService.setDataWishDetailModal(component);
        this.layoutService.setShowModalWishDetail(true);
        this.toastService.presentToast('TOAST.FINISH_SEXTING', 3000);
      } else {
        this.messages.push(message);
        setTimeout(() => {
          this.body.scrollToBottom(500);
        }, 500
        );
     }

    });
  }
  openStateModal(state) {
    const endDate = new Date(state.end);
    const todayDate = new Date();

    if (todayDate.getTime() < endDate.getTime()) {
      this.menu.close('menu2');
      this.layoutService.setShowStateModal([state]);
    }
  }
  openOffertModal(offert) {
    this.menu.close('menu2');
    this.layoutService.setDataNewOffer(offert);
    this.layoutService.setShowNewOffer(true);
  }
  openBillingModal(billing) {
    const component: DataComponent = {component: 'billing-detail', contextId: billing._id, reload: false};
    this.layoutService.setComponent(component);
  }


  getAdminMessages(): void {
    this.socket.getMessageAdmin().subscribe((message: any) => {
      this.messages.push({
        imageUrl: message.imageUrl,
        message: message.message,
        userId: message.fromAdmin ? null : message.userId,
      });
      setTimeout(() =>
        this.body.scrollToBottom(500), 500
      );
    });
  }

  getUserType() {

    return this.authService.getLocalStorage('userType');
  }

  takePicture() {
    const options: ImageOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      //webUseInput: true,
      source: CameraSource.Prompt,
      saveToGallery: false,
    };

    Camera.getPhoto(options).then((image) => {
      const imageUrl = image.base64String;
      const file = this.getFileFromBase64(imageUrl, 'test');
      /** read image load */
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageForEdit = reader.result;
        this.isEditImage = true;
      });
      reader.readAsDataURL(file);
      /** read image load */
    });
  }

  private getFileFromBase64(string64: string, fileName: string): File {
    const imageContent = atob(string64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  uploadImage(image: string) {
    const imageToUpload = image;
    this.showLoading(true);

    setTimeout(() => {
      this.loading.dismiss();
    }, 1000);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', file);

      this.isEditImage = false;
      this.addFile = false;

      this.publicationService.saveImage(formData).subscribe((res: any) => {
        const newMessage: Message = {
          chatId: this.idRoom,
          message: '',
          roomId: this.idRoom,
          userId: this.myUser._id,
          imageUrl: res.imageUrl
        };
        if (this.adminChat) {
          this.socket.sendMessageAdmin(newMessage);
        } else {
          this.socket.sendMessage(newMessage);
        }
        this.sendNotification('message');

      });
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  showLoading(isImage: any) {
    const options = {
      // eslint-disable-next-line max-len
      message: isImage ? this.translateService.instant('NOTIFICATION.LOADING_IMAGE') : this.translateService.instant('NOTIFICATION.LOADING_VIDEO'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getVideoFromGallery(event: any) {
    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.recordVideoElement.src = URL.createObjectURL(file);
      this.isPlayVideo = true;
      this.videoTemp = file;
      //this.videos.push(file);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  deleteVideo() {
    this.videoTemp = null;
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
    this.addFile = false;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  deleteImage() {
    this.isEditImage = false;
    this.addFile = false;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  uploadVideo() {
    const formData = new FormData();
    formData.append('file', this.videoTemp);
    this.showLoading(true);
    setTimeout(() => {
      this.loading.dismiss();
    }, 1000);
    this.deleteVideo();
    this.publicationService.saveVideo(formData).subscribe((res: any) => {
      const newMessage: Message = {
        chatId: this.idRoom,
        message: '',
        roomId: this.idRoom,
        userId: this.myUser._id,
        videoUrl: res.videoUrl
      };

      if (this.adminChat) {
        this.socket.sendMessageAdmin(newMessage);
      } else {
        this.socket.sendMessage(newMessage);
      }
      this.sendNotification('message');
    });

  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  clickInputGallery() {
       this.addFile = false;
    this.inputGallery.nativeElement.click();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  clickInputGalleryAudio() {
    this.addFile = false;
    this.inputGalleryAudio.nativeElement.click();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  clickInputGalleryImage() {
       this.addFile = false;
    this.inputGalleryImage.nativeElement.click();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getImageFromGallery(event: any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageForEdit = reader.result;
        this.isEditImage = true;
      });
      reader.readAsDataURL(file);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getAudioFromGallery(event: any) {
    this.recordAudioElement = this.recordAudioElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.recordAudioElement.src = URL.createObjectURL(file);
      this.isPlayAudio = true;
      this.audioTemp = file;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  deleteAudio() {
    this.audioTemp = null;
    this.recordAudioElement.src = '';
    this.isPlayAudio = false;
    this.addFile = false;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  uploadAudio() {
    const formData = new FormData();
    formData.append('file', this.audioTemp);
    this.showLoading(3);
    this.publicationService.saveAudio(formData).subscribe((res: any) => {
      const newMessage: Message = {
        chatId: this.idRoom,
        message: '',
        roomId: this.idRoom,
        userId: this.myUser._id,
        audioUrl: res.audioUrl
      };

      if (this.adminChat) {
        this.socket.sendMessageAdmin(newMessage);
      } else {
        this.socket.sendMessage(newMessage);
      }
      this.sendNotification('message');
      this.deleteAudio();
      this.loading.dismiss();
      this.addFile = false;
    },
        (err) => {
          this.loading.dismiss();
        });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  stopSexting() {

    this.chatService.stopSexting(this.idRoom).subscribe((res) => {

      const newMessage: Message = {
        chatId: this.idRoom,
        message: null,
        roomId: this.idRoom,
        userId: this.myUser._id,
        stopSexting: true
      };

      this.socket.sendMessage(newMessage);
      this.onChatReasson = false;
      this.chatReasson = '';
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  chatIsAvailable() {

    if (this.adminChat) {
      return true;
    }

    if (this.onChatReasson) {
      return true;
    }

    if (this.chatAvailableByWish) {
      return true;
    }

    if (this.authService.getLocalStorage('userType') !== '2') {
      return true;
    }

    if (this.userSubscriptions.find(sub => sub.userSellerId[0]._id === this.userId)) {
      return true;
    }

    if (this.userChatsubscriptions.find(sub => sub.userSellerId[0]._id === this.userId)) {
      return true;
    }

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isOpenModalSuscribeToChat() {
    this.isModalOpen = true;

    this.messageSubscription = {
      message: this.translateService.instant('MODAL.SUSCRIBE')
    };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  externalPayment() {
    const payload = {
      userId: this.userId,
      contextId: this.userId,
      title: 'suscription-chat',
      amount: this.priceForChatSubscription,
      typePayment: 'payment'
    };
    this.layoutService.setShowModalPaymentMethods(true);
    this.layoutService.setDataModalPaymentMethods(payload);
    this.close();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  subscribeToChat() {
    const transaction = {
      userClientId: this.myUser._id,
      userSellerId: this.userId,
      aprovedClient: true,
      aprovedSeller: true,
      amount: this.priceForChatSubscription,
      type: 1,
    };
    this.isModalOpen = false;
    this.transactionsService
      .registerTransactionForUserIds(transaction)
      .subscribe(
        (res: any) => {
          const subscription = {
            userClientId: this.myUser._id,
            userSellerId: this.userId,
            transaction: res._id,
            price: this.priceForChatSubscription,
            status: 1,
          };
          this.userService.subscribeUserChat(subscription).subscribe(() => {
            this.layoutService.setUpdateStatus();
            const req = {
              url: this.router.url.substring(0, 8).concat('/subscribe'),
              method: 'PUT',
            } as HttpRequest<string>;
            this.toastService.presentToastMiddleCheck(req);

          });
        },
        (error) => {
          console.error(error);
        }
      );

    /**/
  }
}
