import { Component, Input, OnInit, Renderer2, OnChanges,SimpleChanges, OnDestroy } from '@angular/core';
import { Publication } from 'src/app/core/models/publication.model';
import { User } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { LayoutService } from '../../services/layout/layout.service';
import { Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'app-searchbar-result',
  templateUrl: './searchbar-result.component.html',
  styleUrls: [
    './searchbar-result.component.scss',
    '../recomendations/recomendations.component.scss',
  ],
})
export class SearchbarResultComponent implements OnInit, OnChanges, OnDestroy {
  @Input() publications: Publication[] = [];
  @Input() users: User[] = [];

  imageSelected = '';
  isSearchBarEmpty = true;


  path = '/profile';

 defaultBackgroundImage =
    'assets/images/6bfbfd27378295.563642d27afb2.png';

  subscriptionViewAllStatusUser: Subscription;
  activeDeleteImageSubscription: Subscription;
  usersAllViewStates = [];

  myUser

  activeUsers: any = [];
  activeUserSubscription: Subscription;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthServiceService,
    private usersService: UsersService,
    private layoutService: LayoutService) { }

  ngOnInit() {
    this.myUser = JSON.parse(this.authService.getLocalStorage('user'));
    this.subscriptionViewAllStatusUser = this.layoutService.getNewUserViewAllStates().subscribe((usersAllViewStates: string[]) => {
      this.usersAllViewStates = usersAllViewStates;
    })
    this.activeUserSubscription = this.usersService.getUsersActive().subscribe(user => {
      this.activeUsers = user;
    });
   }

   ngOnDestroy (): void {
    if (this.subscriptionViewAllStatusUser) {
      this.subscriptionViewAllStatusUser.unsubscribe()
    }    
    if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  isActive(id: string) {
    return this.activeUsers.find(userActive => userActive.user === id)?.active;
  }

  evaluateStates(user) {
    let state = null;
    if (user?.states?.length == 0 || user._id == this.myUser._id) {
      state = 'noState';
    } else if (user?.states?.every((state) => state.seen.includes(this.myUser._id)) || this.usersAllViewStates.includes(this.myUser._id)) {
      state ='allView';
    }
    return state;
  }

  openStateModal(e: Event, states) {
    e.stopPropagation();
    this.layoutService.setShowStateModal(states);
  }

  navigateTo(user) {
     if (user.type.type === 1) {
      this.router.navigate(['/profile', user._id]);
    }
  }

  selectImage(image: any) {
    this.layoutService.setShowModalFrameDetail(true);
    this.layoutService.setDataFrameImage(image);
  }


  typeUsers(data) {
    const users = data.filter((item) => item.type.type === 1);
    return users;
  }
  // onIntersection(
  //   {
  //     target,
  //     visible,
  //   }: {
  //     target: HTMLElement;
  //     visible: boolean;
  //   },
  //   user?: any
  // ): void {
  //   if (visible) {
  //     let urlCover = target.dataset.src;
  //     if (user && user.imageCover) {
  //       urlCover = user.imageCover[0]?.imageUrl;
  //     }
  //     const backgroundImage = urlCover ? urlCover : this.defaultBackgroundImage;
  //     this.renderer.setStyle(
  //       target,
  //       'background-image',
  //       `url(${backgroundImage})`
  //     );
  //   } else {
  //     this.renderer.removeStyle(target, 'background-image');
  //   }
  // }
}
