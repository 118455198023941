import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PublicationService } from '../../services/publication/publication.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { LayoutService } from '../../services/layout/layout.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileService } from '../../services/file/file.service';

@Component({
  selector: 'app-plastt-gallery-profile',
  templateUrl: './plastt-gallery-profile.component.html',
  styleUrls: ['./plastt-gallery-profile.component.scss'],
})
export class PlasttGalleryProfileComponent implements OnInit {
@Input() images = [];
  @Output() imageSelected = new EventEmitter<any>();
  @Output() openCommentsComponent = new EventEmitter<boolean>(false);
  @Output() scrollDown = new EventEmitter<boolean>(false);
  @Input() idProfile = '';
  @ViewChild('comments') comments;
  @ViewChild('gallery') gallery: ElementRef;

  publicationToComment;
  openComments = false;
  myId = '';

  moreOptionOpen = false;
  confirmDeleteOpen = false;
  indexImg: null | number = null;
  optionOtherProfileModal = false;
  sharePublication = false;
  imgSelected: any = undefined;
  linkRedirect: string;
  copyActive = false;
  isDeviceIOS = false;
  fullScreen = false;

  typeReport = 'report-publication';
    isModalOpen = false;
  isModalReport = false;
  titleModal = '';
    aspectoRatio = 16 / 9;

    private env = environment;

  constructor(
    private publicationService: PublicationService,
    private authService: AuthServiceService,
    private layoutService: LayoutService,
    private toastService: ToastService,
    private usersService: UsersService,
     private sanitizer: DomSanitizer,
     private fileService: FileService
  ) { }

  onScroll() {
    this.scrollDown.emit(true);
  }

  selectImage(image: any) {
    if (image.videos.length > 0) {
      this.imageSelected.emit(image);
    }

    else if (image?.images?.length > 0) {
    this.imageSelected.emit(image);
    }
  }


  silenciarVideo(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    if (videoElement) {
      videoElement.muted = true;
    }
  }

  ngOnInit() {}

}
