import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { FormControl, Validators } from '@angular/forms';
import { ServiceOffertService } from '../../services/serviceOffert/service-offert.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { SocketService } from '../../services/socket/socket.service';
import { FileService } from '../../services/file/file.service';
@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss'],
})
export class NewOfferComponent implements OnInit, OnDestroy {



  subscription: Subscription;
  isEditAmount = true;

  inputAmount = new FormControl('10.00');
  inputComment = new FormControl('');

  data: any;
  myId = '';
  myUserName = '';
  isDeviceIOS = false;
   fullScreen = false;

    constructor(
    private layoutService: LayoutService,
    private serviceOffert: ServiceOffertService,
    private authService: AuthServiceService,
      private socket: SocketService,
       private fileService: FileService
  ) { }
  ngOnInit() {
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const myUser = JSON.parse(this.authService.getLocalStorage('user'));
    this.myId = myUser._id;
    this.myUserName = myUser?.userName;
    this.subscription = this.layoutService
      .getDataNewOffer()
      .subscribe((offerData: any) => {
        this.data = offerData;
        if (offerData.publication) {

        }
        if (this.data._id) {
          this.data.userClientId._id == this.myId ? this.isEditAmount = false : '';
          this.inputAmount.setValue(this.data.amount);
        }
      });

  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Eliminar caracteres no permitidos y mantener solo un punto
    inputValue = inputValue.replace(/[^\d.]/g, '');
    const parts = inputValue.split('.');
    if (parts.length > 1) {
      inputValue = parts[0] + '.' + parts.slice(1).join('');
    }

    // Asegurar que no haya ceros a la izquierda, excepto para el caso "0."
    if (inputValue.length > 1 && inputValue.startsWith('0')) {
      inputValue = inputValue.replace(/^0+/, '');
    }
    if (inputValue == '0') {
      inputValue = '1';
    }

    // Actualizar el valor del FormControl con el valor filtrado
    this.inputAmount.setValue(inputValue, { emitEvent: false });
  }


  close() {
    this.layoutService.setShowNewOffer(false);
  }



  bid() {
    const user = JSON.parse(this.authService.getLocalStorage('user'));
    const comment = {
      user: user.userName,
      comment: this.inputComment.value
    };
    const comments = [];
    if (this.inputComment.value) {
      comments.push(comment);
    }
    const offert = {
      userClientId: this.data.userClientId,
      userSellerId: this.data.userSellerId._id,
      amount: parseFloat(this.inputAmount.value),
      comments,
      publication: this.data.publication.id
    };

    // this.serviceOffert.createOffert(offert).subscribe(
    //   (res: any) => {
    //     this.close();
    //     this.sendNotification('NOTIFICATION.BID_YOUR_PUBLICATION', true, res._id);
    //     this.layoutService.setUpdateStatus();
    //   }
    // );
  }

   playFullScreenIos(url) {

    const lastVideo = document.getElementById('last-video');

    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    video.src = this.fileService.transformUrlVideo(url);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id='last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
     video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

     };
  }

  //  externalPay(){
  //   const payload = {
  //     userId : this.data.userSellerId._id,
  //     contextId: this.data.publication.id,
  //     title: 'offert',
  //     amount: parseFloat(this.inputAmount.value),
  //     typePayment: 'payment'
  //   };
  //    this.close();
  //   this.layoutService.setShowModalPaymentMethods(true);
  //   this.layoutService.setDataModalPaymentMethods(payload);
  // }

  // getImageDimentions() {
  //   return {
  //     height: (window.innerHeight * 0.65) + 'px',
  //     width: (window.innerWidth * 0.78) + 'px'
  //   };
  // }


  addComment() {
    const user = JSON.parse(this.authService.getLocalStorage('user'));
    const comment = {
      user: user.userName,
      comment: this.inputComment.value
    };
    const payload = {
      offertId: this.data._id,
      comment
    };
    // this.serviceOffert.addNewCommentOffert(payload).subscribe(
    //   (res: any) => {
    //     this.data.comments.unshift(comment);
    //     this.inputComment.setValue('');
    //   }
    // );
    // this.sendNotification('NOTIFICATION.HAS_COMMENTED_OFFER');
    this.layoutService.setUpdateStatus();
  }

  editAmount() {
    if (this.data.userClientId._id == this.myId) {
      this.isEditAmount = !this.isEditAmount;
    }
  }

  updatePrice() {
    const payload = {
      offertId: this.data._id,
      newPrice: parseFloat(this.inputAmount.value)
    };
    // this.serviceOffert.updatePriceOffert(payload).subscribe();
    // this.sendNotification('NOTIFICATION.CHANGED_PRICE_OFFER');
     this.layoutService.setUpdateStatus();
  }


  getDecimalPart(number) {
    return number % 1;
  }


  process(isSellerAccept) {
    const payload = {
      offertId: this.data._id,
      sell: isSellerAccept
    };
    // this.serviceOffert.processOffert(payload).subscribe((res: any) => {
    //   this.sendNotification(isSellerAccept ? 'NOTIFICATION.HAS_ACCEPTED_OFFER' : 'NOTIFICATION.HAS_REJECTED_OFFER');
    //   this.data.closed = true;

    // });

    this.layoutService.setUpdateStatus();
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  ionViewWillLeave(): void {
     if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  goToChat() {
    const component: DataComponent = {
      component: 'chat',
      contextId: this.data.userClientId._id == this.myId ? this.data.userSellerId._id : this.data.userClientId._id,
    };
    this.layoutService.setChatRoom(component);
  }

  sendNotification(message: string, newBid = false, offertId?: string): void {
    const isClient = this.data.userClientId._id == this.myId || newBid;
    const notification = {
      userReceiver: isClient ? this.data.userSellerId._id : this.data.userClientId,
      userSender: !isClient ? this.data.userSellerId._id : this.data.userClientId,
      type: 'offer',
      message,
      contextId: offertId ? offertId : this.data._id
    };
    this.socket.sendNotification(notification);
  }
}
