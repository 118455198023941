import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { PublicationService } from '../../services/publication/publication.service';
import { DateService } from '../../services/utils/date.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {

  @Output() laodingNewPublication = new EventEmitter();
  page = 0;
  size = 10;
  notifications: any[] = [];
  unreadNotification: Notification[] = [];
  typeUser: any;
  userIcon = 'assets/icon/usuario-30.png';
  loadingIcon= false;
  user: any;
  categories: [any];
  allImages: [any];
  isExplore: true;

  constructor(
    private authService: AuthServiceService,
    private router: Router,
    private notificationService: NotificationsService,
    private publicationService: PublicationService,
    private activeRouter: ActivatedRoute

  ) { }

  ngOnInit() {
    this.initApp();
    const user = JSON.parse(this.authService.getLocalStorage('user'));
    this.typeUser = user.type.type;
  }
  goToChatList() {
    this.router.navigate(['notifications']);
    this.readNotifications();
  }

  initApp() {
    this.getNotifications();
  }
  goto(data: string) {

    this.router.navigate([`${data}`]);

  }

  gotoNewPublication () {
    if (this.router.url === '/plastt') {
    this.router.navigate([`new-plastt-publication`]);
    } else {
     this.router.navigate([`new-publication`]);
    }
  }

   getNotifications() {
    this.notificationService
      .getNotifications(this.page, this.size)
      .subscribe((res: Notification[]) => {
        if (res.length === 0) {
     return;

    }
         const notifications = res.map((notification: any) =>
          this.formatNotification(notification),
         );
       this.notifications = this.notifications.concat(notifications);

        this.unreadNotification = this.notifications.filter(
          (notification: any) => !notification.read
        );

      });
  }

  readNotifications() {
    const updateNotifications = () => {
      this.notifications = this.notifications.map(
        (notification: Notification) => ({
          ...notification,
          read: true,
        })
      );
      const notificationUpdated = this.notifications.map(
        (notification) => notification._id
      );
      this.notificationService
        .updateNotification({ update: notificationUpdated })
        .subscribe(() => (this.unreadNotification = []));
    };

    setTimeout(updateNotifications, 1500);
  }


  formatNotification(notification: any) {
    const shortMessage = (message: string) => {
      const max = 40;
      return message.length > max
        ? message.concat('...')
        : message;
    };

    return {
      ...notification,
      createdAt: new Date(notification.createdAt).toDateString(),
      message: shortMessage(notification.message),
      userTransmitter: {
        ...notification.userTransmitter,
        imageProfile: {
          ...notification.userTransmitter?.imageProfile,
          imageUrl: this.formatImageProfile(
            notification.userTransmitter?.imageProfile?.imageUrl
          ),
        },
      },
    };
  }

  reLoadingPage() {
    this.loadingIcon= true;
    this.user = JSON.parse(this.authService.getLocalStorage('user'));
    this.publicationService
      .getPublicPublications([], this.page, '', true)
      .subscribe((res: any) => {
        try {
          this.authService.insertLocalStorage('pagelustlist',0);
          this.router.navigate([`lust-list`]);
          this.loadingIcon = false;
          this.laodingNewPublication.emit();

        } catch (error) {
            this.loadingIcon= false;
        }

      });

  }



  formatImageProfile(image) {
    return !image && !image?.length ? this.userIcon : image;
  }

}
