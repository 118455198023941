import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleInactivityService {
  public userInactive: Subject<void>;
  private timeoutId: any;
  private readonly timeoutPeriod: number = 2700000;

  constructor(private ngZone: NgZone) {
    this.userInactive = new Subject<void>();
    this.startMonitoring();
  }

  private resetTimer() {
    clearTimeout(this.timeoutId);
    this.startTimer();
  }

  private startTimer() {
    this.timeoutId = setTimeout(() => this.onInactive(), this.timeoutPeriod);
  }

  private onInactive() {
    this.userInactive.next();
  }

  private startMonitoring() {
    this.ngZone.runOutsideAngular(() => {
      ['mousemove', 'click', 'keydown', 'scroll', 'touchstart', 'touchmove'].forEach(event => {
        window.addEventListener(event, () => this.resetTimer(), { passive: true });
      });
    });
    this.startTimer();
  }
}
