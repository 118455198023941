import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss'],
})
export class EditImageComponent implements OnInit, OnChanges {
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  @Input() imageForEdit: ArrayBuffer | string = '';
  @Input() typeImage = '';
  @Input() fromLogin = false;
  @Input() insertWatermark = false;
  @Output() imageEdited = new EventEmitter<string>();
  @Output() cancelEditImage = new EventEmitter();

  config = {
    aspectRatio:4/3,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: true,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin: true,
    checkCrossOrigin: true,
  };

  imageCropped: string;
  button = false;

  constructor(private authService: AuthServiceService,) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.typeImage) {
      if (this.typeImage === String('wide')) {
        this.config.aspectRatio = 16 / 9;
      }

      if (this.typeImage === String('high')) {
        this.config.aspectRatio = 9 / 16;
      }

      if (this.typeImage === String('publication')) {
        this.config.aspectRatio = 9 / 12;
      }

      if (this.typeImage === String('square')) {
        this.config.aspectRatio = 1 / 1;
      }

      if (this.typeImage === String('video-image')) {
        this.config.aspectRatio = 3 / 2;
      }
    }
  }

  /**
   *  Method for cropping the image
   *
   * If the image type is 'wide', crop the image with respect to 16:9.
   * If the image type is 'square', crop the image with respect to 1:1.
   */
  async cropMe() {
    this.button = true;
    const canvas = this.angularCropper.cropper.getCroppedCanvas();

    if (this.insertWatermark) {
      const { userName } = JSON.parse(this.authService.getLocalStorage('user'));
      this.imageCropped = await this.addWatermarkToCanvas(canvas, userName);

    } else {
      this.imageCropped = canvas.toDataURL('image/jpeg');
    }
  }

  async addWatermarkToCanvas(croppedCanvas, userName): Promise<string> {

    return new Promise((resolve, reject) => {
      const context = croppedCanvas.getContext('2d');
      const watermark = new Image();
      watermark.src = 'assets/images/watermark.png';

      if (!context) {
        reject('No se pudo obtener el contexto del lienzo.');
        return;
      }

      watermark.onload = () => {
        const sizeRelation = Math.ceil(croppedCanvas.width / 10);
        const text = 'lust-list.com/model/' + userName;

        context.font = Math.ceil(sizeRelation / 2) + 'px Arial';
        const textWidth = context.measureText(text).width;
        context.fillStyle = 'rgba(255, 255, 255, 0.5)';
        context.textBaseline = 'bottom';
        context.fillText(text, croppedCanvas.width - textWidth - 20, croppedCanvas.height - 20);

        context.drawImage(watermark, 10, 10, sizeRelation, sizeRelation);

        const image = croppedCanvas.toDataURL('image/jpeg');
        resolve(image);
      };
     });
  }


  /**
   * Return to the previous state before cutting the image.
   */
  backCrop() {
    this.imageCropped = null;
  }

  /**
   * Emit the image cropped to the page
   */
  uploadImage(): void {
    this.imageEdited.emit(this.imageCropped);
  }

  /**
   * Cancels the crop edit
   */
  cancelEdit() {
    this.cancelEditImage.emit();
  }
}
