import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { ProductService } from '../../services/product/product.service';
import {
  Camera,
  CameraResultType,
  CameraSource,
  ImageOptions,
} from '@capacitor/camera';
import { CropperComponent } from 'angular-cropperjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-product',
  templateUrl: './add-edit-product.component.html',
  styleUrls: ['./add-edit-product.component.scss'],
})
export class AddEditProductComponent implements OnInit, OnChanges {
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  @Input() product = null;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose = new EventEmitter();

  productImg = '';
  loading: HTMLIonLoadingElement;

  productForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    description: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.maxLength(40)]),
    price: new FormControl('', Validators.required),
    stock: new FormControl(1, Validators.required),
    images: new FormControl([], Validators.required),
    enabled: new FormControl(true, Validators.required),
  });

  imageEdit = false;
  imgUrl = null;
  imageForEdit: ArrayBuffer | string;

  constructor(
    private loadingCtrl: LoadingController,
    private productService: ProductService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const product = changes.product.currentValue;
    if (product) {
      this.setProductForm(product);
    }
  }

  ngOnInit() {}

  setProductForm(product) {
    this.productForm.get('title').setValue(product.title);
    this.productForm.get('description').setValue(product.description);
    this.productForm.get('price').setValue(product.price);
    this.productForm.get('stock').setValue(product.stock);
    this.productForm.get('images').setValue([product.images[0]._id]);
    this.productForm.get('enabled').setValue(product.enabled);
    this.productImg = product.images[0].imageUrl;
  }

  takePicture() {
    const options: ImageOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      //webUseInput: true,
      source: CameraSource.Prompt,
      saveToGallery: false,
    };

    Camera.getPhoto(options).then((image) => {
      const imageUrl = image.base64String;
      const file = this.getFileFromBase64(imageUrl, 'test');

      /** read image load */
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.imageForEdit = reader.result;
        this.imageEdit = true;
      });
      reader.readAsDataURL(file);
      /** read image load */
    });
  }

  showLoading(isImage: boolean) {
    const options = {
      message: isImage ? this.translateService.instant('NOTIFICATION.LOADING_IMAGE') : this.translateService.instant('NOTIFICATION.LOADING_VIDEO'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

  createProduct() {
    this.productService.saveProduct(this.productForm.value).subscribe(
      () => this.close(true),
      (err) => console.error(err)
    );
  }

  updateProduct() {
    this.productService
      .editProduct(this.product._id, this.productForm.value)
      .subscribe(
        () => this.close(true),
        (err) => console.error(err)
      );
  }

  deleteProduct() {
    this.productService.deleteProduct(this.product._id).subscribe(
      () => this.close(true),
      (err) => console.error(err)
    );
  }

  close(val) {
    this.onClose.emit(val);
  }

  uploadImage(image: string) {
    const imageToUpload = image;
    this.showLoading(true);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', file);

      this.productService.saveImage(formData).subscribe((res: any) => {
        this.productForm.get('images').setValue([res._id]);
        this.productImg = res.imageUrl;
        this.loading.dismiss();
        this.imageEdit = false;
      },
        (err) => {
          this.loading.dismiss();
        });
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }

  private getFileFromBase64(string64: string, fileName: string): File {
    const imageContent = atob(string64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type,
    });
  }
}
