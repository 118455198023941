import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FolderService {
  private author = new BehaviorSubject<string>('');
  // eslint-disable-next-line @typescript-eslint/member-ordering
  author$ = this.author.asObservable();

  constructor() {}

  setAuthor(value: string) {
    this.author.next(value);
  }

  getAuthor() {
    return this.author$;
  }
}
