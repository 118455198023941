import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { FileService } from '../../services/file/file.service';
import { LayoutService } from '../../services/layout/layout.service';
import { MoviesService } from '../../services/movies/movies.service';
import { SocketService } from '../../services/socket/socket.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-add-videos',
  templateUrl: './add-videos.component.html',
  styleUrls: ['./add-videos.component.scss'],
})
export class AddVideosComponent implements OnInit, OnDestroy {

  @Input() userAuthorId;

  userId = '';
  myId = '';
  downloadUrl;

  movies = [];
  page = 0;
  size = 5;
  videoUrlPreview: any;
  isPlayVideo = false;
  isModalOpen = false;
  messageSubscription: any =  {
    message: this.translateService.instant('MODAL.BUY_VIDEO')
  };

  subscriptionNewMovie: Subscription;
  isDeviceIOS = false;
  fullScreen = false;

  movieSelect;
  myFunds = 0;
  maxDownloads=[];

  isMobile = false;
  downloading= false;

  constructor(
    private authService: AuthServiceService,
    private activatedRoute: ActivatedRoute,
    private moviesService: MoviesService,
    private translateService: TranslateService,
    private layoutService: LayoutService,
    private accountsService: AccountsService,
    private toastService: ToastService,
    private socketService: SocketService,
    private fileService: FileService
  ) { }

  ngOnDestroy(): void {
    if (this.subscriptionNewMovie) {
      this.subscriptionNewMovie.unsubscribe();
    }
  }

  ngOnInit() {
    const user = JSON.parse(this.authService.getLocalStorage('user'));
    this.isDeviceIOS = this.authService.isDeviceIOS;
    this.myId = user._id;

    this.activatedRoute.params.subscribe((val) => {
      this.userId = val.id;
    });
    if (window.outerWidth < 640) {
      this.isMobile = true;
    }

    this.getMovies();

    this.subscriptionNewMovie = this.layoutService.getNewMovie().subscribe((newMovie) => {
      if (newMovie) {
        this.movies = [];
        this.getMovies();
      }
    });

    this.accountsService.getMyAccount().subscribe(
      (res: any) => this.myFunds = res.amount,
      (error) => console.error(error)
    );
  }

  onDownload(event) {
    this.downloading = false;
    const payLoad = {
      open: false
    };
    this.layoutService.setDataNotificationDownloadMovie(payLoad);
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO_FINISH', 2500);
  }
  errorDownload(event) {
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO_ERROR', 2500);
  }

  downloadVideo(id) {
    this.downloading = true;
    this.moviesService.addDownloadsMovie(id).subscribe((data: any) => {});
    this.toastService.presentToast('TOAST.DOWNLOAD_VIDEO', 3500);
  }

  validateAccesMoview(movie: any) {
    return movie.usersWhitAccess.includes(this.myId) || this.myId == movie.userAuhthor || this.myId == movie.userAuhthor._id;
  }


    disabledBotton(id) {
    return this.maxDownloads.find(video => video.movieId === id);
    }

  modalMaxDownload(movie) {
    this.layoutService.setShowModalMaxDownloadPay(true);
    this.layoutService.setDataModalMaxDownloadPay(movie);
  }


  onScroll() {
    this.page++;
    this.getMovies();
  }

  getMovies() {

    if (this.userAuthorId == this.myId) {
      this.moviesService.getMoviesByAuthor(this.page, this.size).subscribe((res: any) => {
        const movies = res.map(mov => {
          mov.video.duration = this.secondsToTime(mov.video.duration);
          return mov;
        });
        this.movies = this.movies.concat(movies);
      });
    } else {
      this.moviesService.getMoviesByProfile(this.userAuthorId, this.page, this.size).subscribe((res: any) => {
        const movies = res.map(mov => {
          mov.video.duration = this.secondsToTime(mov.video.duration);
          return mov;
        });
        this.movies = this.movies.concat(movies);
      });
    }
     this.moviesService.getDownloadsMovie().subscribe((resp: any) => {

      resp.videosDownload.forEach(element => {

        if (element.downloads >= 3) {
          this.maxDownloads.push(element);
        }

      });
    });

  }

  myProfile(){
    return this.myId === this.userId;
  }

  async changeEnableMovie(movieId) {
    const movie = this.movies.find(mov => mov._id == movieId);
    movie.enabled = !movie.enabled;

    if (movie) {
      const res = await this.moviesService.updateMovie(movieId, movie.image._id, movie.price, movie.enabled, movie.title, movie.description).toPromise().catch((e) => {
        movie.enabled = !movie.enabled;
      });
    }

  }

  openModalBuyVideo(movie) {
    this.movieSelect = movie;
    this.isModalOpen = true;
    this.messageSubscription= {
      message: this.translateService.instant('MODAL.BUY_VIDEO')
    };
  }

  secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsAll = Math.ceil(seconds % 60);

    let time = `${secondsAll}s`;
    if (minutes) {
      time = `${minutes}min, ${secondsAll}s`;
    }

    if (hours) {
      time = `${hours}h, ${minutes}min, ${secondsAll} s`;
    }
    return time;
  }

  playFullScreen(url) {

    this.toastService.presentToast('TOAST.BUILDING_PREVIEW', 2500);

    const lastVideo = document.getElementById('last-video');
    if (lastVideo) {
      document.body.removeChild(lastVideo);
    }
    const video: any = document.createElement('video');
    //video.src = this.transforVideoInPreview(url);
    video.src = this.fileService.transformUrlVideo(url, true);
    video.type = 'video/mp4';
    video.controlsList = 'nodownload';
    video.controls = true;
    video.id = 'last-video';
    document.body.appendChild(video);

    // Verificar si el navegador admite el modo de pantalla completa
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari y Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
    if (this.isDeviceIOS) {
      video.play();
    }
    video.onfullscreenchange = (event) => {

      if (this.fullScreen) {
        this.fullScreen = false;
        document.body.removeChild(video);
      } else {
        this.fullScreen = true;
      }

    };
  }


  confirmBuy() {
    const movie = this.movies.find(mov => mov._id == this.movieSelect._id);
    this.moviesService.addNewUserAccess(movie._id, this.myId, movie.userAuhthor, movie.transferFee).subscribe((res: any) => {
      movie.usersWhitAccess = res?.usersWhitAccess;
      this.layoutService.setUpdateStatus();
      this.sendNotification(movie, 'NOTIFICATION.BUY_VIDEO');
    });
  }

    //external payment

  externalPayment() {
    const movie = this.movies.find(mov => mov._id === this.movieSelect._id);
    const payload = {
      userId: movie.userAuhthor || movie.userAuhthor._id,
      contextId: this.movieSelect._id,
      title: 'full-movie',
      amount: movie.price,
      typePayment: 'payment'
    };
    this.layoutService.setShowModalPaymentMethods(true);
    this.layoutService.setDataModalPaymentMethods(payload);
  }

  sendNotification(movie, message: string) {
    const notification = {
      userReceiver: movie.userAuhthor || movie.userAuhthor._id,
      userSender: this.myId,
      type: 'movie',
      message,
      contextId: movie._id
    };

    this.socketService.sendNotification(notification);
  }
}
