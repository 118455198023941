import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';

import { ProgressBarModule } from 'primeng/progressbar';

import { GalleryComponent } from './components/gallery/gallery.component';
import { FrameTotalScreenComponent } from './components/frame-total-screen/frame-total-screen.component';
import { GalleryUniformComponent } from './components/gallery-uniform/gallery-uniform.component';
import { NewPublicationComponent } from './components/new-publication/new-publication.component';
import { CreateProductComponent } from './components/create-product/create-product.component';
import { WishDetailComponent } from './components/wish-detail/wish-detail.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ModalComponent } from './components/modal/modal.component';
import { CommentsComponent } from './components/comments/comments.component';
import { RecomendationsComponent } from './components/recomendations/recomendations.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ChatRoomComponent } from './components/chat-room/chat-room.component';
import { FolderColumnComponent } from './components/folder-column/folder-column.component';
import { HeaderNotificationsComponent } from './components/header-notifications/header-notifications.component';
import { BillingDetailComponent } from './components/billing-detail/billing-detail.component';
import { InViewportModule } from 'ng-in-viewport';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SearchbarResultComponent } from './components/searchbar-result/searchbar-result.component';
import { MyProductsComponent } from './components/my-products/my-products.component';
import { AddEditProductComponent } from './components/add-edit-product/add-edit-product.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { NewWishComponent } from './components/new-wish/new-wish.component';
import { NewOfferComponent } from './components/new-offer/new-offer.component';
import { FormatAssetsPipe } from './pipes/pipe-format-assets/format-assets.pipe';
import { ProductListComponent } from './components/product-list/product-list.component';
import { EditImageComponent } from './components/edit-image/edit-image.component';
import { AttributesModalComponent } from './components/attributes-modal/attributes-modal.component';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { MenuPrincipalComponent } from './components/menu-principal/menu-principal.component';
import { NewStateComponent } from './components/new-state/new-state.component';
import { StateModalComponent } from './components/state-modal/state-modal.component';
import { ImagenDetailComponent } from './components/imagen-detail/imagen-detail.component';
import { CalendarModule } from 'primeng/calendar';
import { CalendarComponent } from './components/calendar/calendar.component';
import { VideoTrimmerComponent } from './components/video-trimmer/video-trimmer.component';
import { SliderRangeComponent } from './components/video-trimmer/components/slider-range/slider-range.component';
import { AddVideosComponent } from './components/add-videos/add-videos.component';
import { AddNewsVideosComponent } from './components/add-news-videos/add-news-videos.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { InformationModalComponent } from './components/information-modal/information-modal.component';

import { PaymentInformationComponent } from './components/payment-information/payment-information.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';


import { UploapLargeVideoCdComponent } from './components/uploap-large-video-cd/uploap-large-video-cd.component';
import { BillTicketDetailComponent } from './components/bill-ticket-detail/bill-ticket-detail.component';
import { ModalSubsFollowComponent } from './components/modal-subs-follow/modal-subs-follow.component';
// eslint-disable-next-line max-len
import { ModalPaymentSubscriptionPlataformComponent } from './components/modal-payment-subscription-plataform/modal-payment-subscription-plataform.component';
// eslint-disable-next-line max-len
import { ModalMaxdonwloadsMoviePaytmentComponent } from './components/modal-maxdonwloads-movie-paytment/modal-maxdonwloads-movie-paytment.component';
import { FileSaverModule } from 'ngx-filesaver';
import { VideoAdVastComponent } from './components/video-ad-vast/video-ad-vast.component';
import { MovieFrameDetailComponent } from './components/movie-frame-detail/movie-frame-detail.component';
import { RatingModule } from 'primeng/rating';
import { ChipsModule } from 'primeng/chips';
import { CapitalizeFirstLetterDirective } from './directives/capitalize-first-letter/capitalize-first-letter.directive';
import { ModalBlockAdsComponent } from './components/modal-block-ads/modal-block-ads.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ModalClientsInfoComponent } from './components/modal-clients-info/modal-clients-info.component';
import { ModalMetricsComponent } from './components/modal-metrics/modal-metrics.component';
import { AdsBannerTerrasComponent } from './components/ads-banner-terras/ads-banner-terras.component';
import { ModalOnlineComponent } from './components/modal-online/modal-online.component';
import { ModalBlockEyelashesComponent } from './components/modal-block-eyelashes/modal-block-eyelashes.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { HeaderNavigationBarComponent } from './components/header-navigation-bar/header-navigation-bar.component';
import { RedSocialModelComponent } from './components/red-social-model/red-social-model.component';



@NgModule({
  declarations: [
    GalleryComponent,
    GalleryUniformComponent,
    RecomendationsComponent,
    SidebarComponent,
    FrameTotalScreenComponent,
    CommentsComponent,
    NewPublicationComponent,
    CreateProductComponent,
    WishDetailComponent,
    ProductDetailComponent,
    ModalComponent,
    ChatRoomComponent,
    FolderColumnComponent,
    HeaderNotificationsComponent,
    BillingDetailComponent,
    SearchbarResultComponent,
    MyProductsComponent,
    AddEditProductComponent,
    NewWishComponent,
    NewOfferComponent,
    FormatAssetsPipe,
    ProductListComponent,
    EditImageComponent,
    AttributesModalComponent,
    ServiceModalComponent,
    MenuPrincipalComponent,
    NewStateComponent,
    StateModalComponent,
    ImagenDetailComponent,
    CalendarComponent,
    VideoTrimmerComponent,
    SliderRangeComponent,
    AddVideosComponent,
    AddNewsVideosComponent,
    InformationModalComponent,
    PaymentInformationComponent,
    FormatAssetsPipe,
    PaymentMethodComponent,
    UploapLargeVideoCdComponent,
    FormatAssetsPipe,
    BillTicketDetailComponent,
    ModalSubsFollowComponent,
    ModalPaymentSubscriptionPlataformComponent,
    ModalMaxdonwloadsMoviePaytmentComponent,
    VideoAdVastComponent,
    MovieFrameDetailComponent,
    CapitalizeFirstLetterDirective,
    ModalBlockAdsComponent,
    VideoPlayerComponent,
    ModalClientsInfoComponent,
    ModalMetricsComponent,
    AdsBannerTerrasComponent,
    ModalOnlineComponent,
    ModalBlockEyelashesComponent,
    NavigationBarComponent,
    RedSocialModelComponent,
    HeaderNavigationBarComponent
  ],
  imports: [
    CommonModule,
    ChipsModule,
    IonicModule,
    SwiperModule,
    ReactiveFormsModule,
    FormsModule,
    InViewportModule,
    TranslateModule,
    RouterModule,
    AngularCropperjsModule,
    CalendarModule,
    ProgressBarModule,
    InfiniteScrollModule,
    FileSaverModule,
    RatingModule,

  ],
  exports: [
    GalleryComponent,
    GalleryUniformComponent,
    SidebarComponent,
    RecomendationsComponent,
    FrameTotalScreenComponent,
    NewPublicationComponent,
    WishDetailComponent,
    SwiperModule,
    CreateProductComponent,
    ProductDetailComponent,
    ModalComponent,
    ChatRoomComponent,
    FolderColumnComponent,
    HeaderNotificationsComponent,
    BillingDetailComponent,
    TranslateModule,
    CommentsComponent,
    SearchbarResultComponent,
    MyProductsComponent,
    AddEditProductComponent,
    NewWishComponent,
    NewOfferComponent,
    ProductListComponent,
    EditImageComponent,
    AttributesModalComponent,
    ServiceModalComponent,
    MenuPrincipalComponent,
    NewStateComponent,
    StateModalComponent,
    ImagenDetailComponent,
    CalendarModule,
    ProgressBarModule,
    CalendarComponent,
    VideoTrimmerComponent,
    SliderRangeComponent,
    AddVideosComponent,
    AddNewsVideosComponent,
    InformationModalComponent,
    PaymentInformationComponent,
    FormatAssetsPipe,
    PaymentMethodComponent,
    UploapLargeVideoCdComponent,
    FormatAssetsPipe,
    FileSaverModule,
    BillTicketDetailComponent,
    ModalSubsFollowComponent,
    MovieFrameDetailComponent,
    ModalPaymentSubscriptionPlataformComponent,
    ModalMaxdonwloadsMoviePaytmentComponent,
    ModalBlockAdsComponent,
    VideoAdVastComponent,
    RatingModule,
    VideoPlayerComponent,
    ModalClientsInfoComponent,
    InfiniteScrollModule,
    ModalMetricsComponent,
    AdsBannerTerrasComponent,
    ModalOnlineComponent,
    ModalBlockEyelashesComponent,
    NavigationBarComponent,
    HeaderNavigationBarComponent,
    RedSocialModelComponent
  ],
})
export class SharedModule {}
