import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { TicketService } from '../../services/ticket/ticket.service';
import { MenuModelsService } from '../../services/menuModels/menu-models.service';
import { ToastService } from '../../services/toast/toast.service';
import { SocketService } from '../../services/socket/socket.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { TransactionsService } from '../../services/transactions/transactions.service';


@Component({
  selector: 'app-bill-ticket-detail',
  templateUrl: './bill-ticket-detail.component.html',
  styleUrls: ['./bill-ticket-detail.component.scss'],
})
export class BillTicketDetailComponent implements OnInit, OnDestroy{

  detailBillTicket: any;
  dataSub: Subscription;
  extraTicketSub: Subscription;

   extras = [
    {
      title: 'Squirt',
      image: '../../../../../assets/wishesicon/Squirt-02.svg',
      price: 40,
      available: true
    },
    {
      title: 'Dildo',
      image: '../../../../../assets/wishesicon/Dildo-02.svg',
      price: 60,
      available: true
    },
    {
      title: 'Vibrator',
      image: '../../../../../assets/wishesicon/Vibrator-02.svg',
      price: 80,
      available: true
    },
    {
      title: 'DirtyTalk',
      image: '../../../../../assets/wishesicon/DirtyTalk-02.svg',
      price: 100,
      available: true
    },
    {
      title: 'Spitting',
      image: '../../../../../assets/wishesicon/Spitting-02.svg',
      price: 120,
      available: true
    },
    {
      title: 'Feet',
      image: '../../../../../assets/wishesicon/Feet-02.svg',
      price: 140,
      available: true
    },
    {
      title: 'Worship',
      image: '../../../../../assets/wishesicon/Worship-02.svg',
      price: 160,
      available: true
    },
    {
      title: 'Spanking',
      image: '../../../../../assets/wishesicon/Spanking-02.svg',
      price: 180,
      available: true
    },
    {
      title: 'Submission',
      image: '../../../../../assets/wishesicon/Submission-02.svg',
      price: 200,
      available: true
    },
    {
      title: 'Roles-Play',
      image: '../../../../../assets/wishesicon/Roles-Play-02.svg',
      price: 220,
      available: true
    },
    {
      title: 'Popping',
      image: '../../../../../assets/wishesicon/Popping-02.svg',
      price: 240,
      available: true
    },
    {
      title: 'Peeing',
      image: '../../../../../assets/wishesicon/Peeing-02.svg',
      price: 260,
      available: true
    },
    {
      title: 'Anal',
      image: '../../../../../assets/wishesicon/Anal-02.svg',
      price: 280,
      available: true
    },
    {
      title: 'Cuckolding',
      image: '../../../../../assets/wishesicon/cuckolding.svg',
      price: 300,
      available: true
    },
    {
      title: 'SPH',
      image: '../../../../../assets/wishesicon/SPH-02.svg',
      price: 320,
      available: true
    },
    {
      title: 'JOI',
      image: '../../../../../assets/wishesicon/JOI-02.svg',
      price: 340,
      available: true
    },
  ];

  discount = new FormControl(0);
  myId = '';
  userSellerId = '';
  myUser: any;
  dataExtra: any;
  myFunds = 0;
  openModalInsufficientBalance = false;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthServiceService,
    private ticketService: TicketService,
    private toastService: ToastService,
    private menuModelsService: MenuModelsService,
    private socket: SocketService,
    private accountsService: AccountsService,
    private transactionsService: TransactionsService
  ) { }


  ngOnInit() {
      const user = this.authService.getLocalStorage('user');
      const userParsed = JSON.parse(user);
      this.myUser = userParsed;
      this.myId = userParsed._id;
    this.dataSub = this.layoutService.getDataModalBillTicketDetail().subscribe((val: any) => {

      this.detailBillTicket = val;


    });

    this.extraTicketSub = this.layoutService.getDataExtraTicket().subscribe((data: any) => {
      this.userSellerId = data?.userId;
      this.dataExtra = data;
      if (data.discount) {
        this.discount.setValue(data.discount);
      }

     if (this.dataExtra.status?.status === 3 && this.myUser?.type?.type === 2) {
       this.getFounds();
     }
    });
  }

  ngOnDestroy(): void {
    this.dataSub.unsubscribe();
    this.extraTicketSub.unsubscribe();
  }

  toDateString(dateString: string): string {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
  }


  getTotalInOrder() {
    return this.detailBillTicket.reduce((acumulator, service) => acumulator + service.price, 0);
  }

   addIconExtras(title: string) {
    let image;
    this.extras.forEach(val => {
      if (title === val.title) {
        image = val.image;
     }
    });
    return image;
  }

  close() {
    this.layoutService.setShowModalBillTicketDetail(false);
  }

  sendNotification(contextId: any, message: string): void {

    let userReceiver;

    if (this.dataExtra) {

      if (this.dataExtra?.userClientId?._id) {
        // eslint-disable-next-line max-len
        userReceiver = this.dataExtra?.userClientId?._id === this.myId ? this.dataExtra?.userSellerId?._id : this.dataExtra?.userClientId?._id;
      }else if (this.dataExtra?.userClientId) {
        userReceiver = this.dataExtra?.userClientId === this.myId ? this.dataExtra?.userSellerId : this.dataExtra?.userClientId;
      } else if (this.dataExtra.userId) {
        userReceiver = this.dataExtra.userId;
      }

    } else {
      userReceiver = this.userSellerId;
    }

    const notification = {
      userReceiver,
      userSender: this.myId,
      type: 'service-menu-models',
      contextId,
      message
    };

    this.socket.sendNotification(notification);
  }

  externalPayment() {
    const payload = {
      userId: this.dataExtra.userSellerId._id ? this.dataExtra.userSellerId._id : this.dataExtra.userSellerId,
      contextId: this.dataExtra._id,
      title: 'service-menu',
      amount: this.dataExtra.total,
      typePayment: 'payment'
    };
    this.close();
    this.layoutService.setShowModalPaymentMethods(true);
    this.layoutService.setDataModalPaymentMethods(payload);
  }

  getFounds() {
    this.accountsService.getMyAccount().subscribe(
      (res: any) => this.myFunds = res.amount,
      (error) => console.error(error)
    );
  }

  internalPay() {

    if (this.dataExtra.total > this.myFunds) {
      this.openModalInsufficientBalance = true;
      return;
    } else {
      this.ticketService.payTicket(this.dataExtra._id).subscribe((res: any) => {
        this.dataExtra = res;
        this.sendNotification(res._id, 'WISHES.TICKET.PAY');
        let discount = 0;
        if (this.discount.value > 0) {
          discount = this.discount.value;
        }
        const discountByItem = discount / this.detailBillTicket.length;
        for (const service of this.detailBillTicket) {
          service.price = service.price - discountByItem;

          if (service.type === 'Sexting' || service.type === 'GFE' || service.type === 'videoCalls') {
            this.requestService(service);
            this.toastService.presentToast('TOAST.YOUR_SERVICE_HAS_BEEN_PAID', 1500);
            this.sendNotification(res._id, 'WISHES.TICKET.PAY');
            this.layoutService.setShowModalBillTicketDetail(false);
            this.layoutService.setUpdateStatus();
          }
          if (service.type === 'videoCustom') {
            this.requestCustomVideo(service);
            this.toastService.presentToast('TOAST.YOUR_SERVICE_HAS_BEEN_PAID', 1500);
            this.sendNotification(res._id, 'WISHES.TICKET.PAY');
            this.layoutService.setShowModalBillTicketDetail(false);
            this.layoutService.setUpdateStatus();
          }

          if (service.type === 'pack') {
            this.requestCustomPack(service);
            this.toastService.presentToast('TOAST.YOUR_SERVICE_HAS_BEEN_PAID', 1500);
            this.sendNotification(res._id, 'WISHES.TICKET.PAY');
            this.layoutService.setShowModalBillTicketDetail(false);
            this.layoutService.setUpdateStatus();
          }
        }
      });
    }
  }

  isAvailableExternalPay() {
    return !this.detailBillTicket.find(service => (service.type === 'videoCustom' || service.type === 'pack'));
  }

  updateTicket() {
    // eslint-disable-next-line max-len
    this.ticketService.updateTicket(this.dataExtra._id, this.dataExtra.services, this.discount.value, this.getTotalInOrder() - this.discount.value).subscribe((res: any) => {
      /**TODO: mostrar toast y enviar notificacion */
      this.sendNotification(res._id, 'WISHES.TICKET.UPDATE');
      this.toastService.presentToast('TOAST.THE_SERVICE_HAS_BEEN_UPDATED', 1500);
      this.layoutService.setShowModalBillTicketDetail(false);
    });
  }


  removeItem(item) {
    const indexItem = this.detailBillTicket.findIndex(element => element.type === item.type);
    this.detailBillTicket.splice(indexItem, 1);
    if (this.detailBillTicket.length === 0) {
      this.close();
    }
  }


  finishTicket(accept: boolean) {

    this.ticketService.finish(this.dataExtra._id, accept).subscribe((res: any) => {
      this.sendNotification(res._id, 'WISHES.TICKET.FINISH');
      this.toastService.presentToast('TOAST.THE_SERVICE_HAS_BEEN_TERMINATED', 1500);
      this.layoutService.setShowModalBillTicketDetail(false);
      this.layoutService.setUpdateStatus();
    });
  }

  requestTicket() {
    const newTicket = {
      userClientId: this.myId,
      userSellerId: this.userSellerId,
      services: this.detailBillTicket,
      discount: this.discount.value,
      total: this.getTotalInOrder() - this.discount.value
    };

    const payload = {
        idSeller: this.userSellerId,
        idClient: this.myId,
        aprovedClient: true,
        aprovedSeller: true,
        amount: this.getTotalInOrder() - this.discount.value,
        product: {},
        type: 13,
      };

    // eslint-disable-next-line max-len
    this.ticketService.saveTicket(newTicket.userClientId, newTicket.userSellerId, newTicket.services, newTicket.discount, newTicket.total).subscribe((res: any) => {
      if (res === 'GENERAL.INSUFFICIENT_BALANCE') {
        this.toastService.presentToast('GENERAL.INSUFFICIENT_BALANCE', 1500);
        this.layoutService.setShowModalBillTicketDetail(false);
        const reset: any = {
          resetArray: [],
          reseteTotal: null
        };
        this.layoutService.setResetCalculatorServiceModel(reset);
      } else {
        this.transactionsService
          .registerTransactionFrozen(payload)
          .subscribe(() => {
            this.sendNotification(res._id, 'WISHES.TICKET.CREATE');
            /**TODO: mostrar toast y enviar notificacion */
            this.toastService.presentToast('TOAST.SERVICE_REQUEST_CREATED_FOR_YOU', 1500);
            this.layoutService.setShowModalBillTicketDetail(false);
            const reset = {
              resetArray: [],
              reseteTotal: null
            };
            this.layoutService.setResetCalculatorServiceModel(reset);
          });
      }
    });
  }

  requestService(service) {
    // eslint-disable-next-line max-len
    this.menuModelsService.setRequestServiceClient(service.type, service.type + '-' + service.time + (service.intensity ? '-' + service.intensity : '') , service.time, service.intensity, service.extras, service.userClientId, service.userSellerId, service.price, true).subscribe();
  }

  requestCustomPack(request) {

    // eslint-disable-next-line radix
    const quantityMinImages = parseInt(request.time.slice(0, 1));

    // eslint-disable-next-line max-len
    this.menuModelsService.setRequestCustomPack(request.userClientId, request.userSellerId, request.type, request.description, request.price, quantityMinImages, request.extras).subscribe(val => {


    });
  }

  requestCustomVideo(request) {
    // eslint-disable-next-line max-len
    this.menuModelsService.setRequestCustomVideo(request.userClientId, request.userSellerId, request.type, request.description, request.price, request.time, request.extras).subscribe(val => {

    });
  }
}
