import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Pagination } from 'swiper';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  env = environment;
  private url = this.env.API_SERVICE_LL_ADS;

  constructor(
    private httpClient: HttpClient,
    private layoutService: LayoutService,
    private toastService: ToastService) { }

  getAvailable() {
    this.detectAdBlock().then();
  }

  getAdsByPreferences() {
    return this.httpClient.get(this.url + '/ads/get-ads-preferences');
  }

  getAdBanner() {
    return this.httpClient.get(this.url + '/ads/get-ad-banner');
  }

  getAdds() {
    return this.httpClient.get(this.url + '/ads/get-ads');
  }

  viewAd(userId, adsId) {
    const payload = { userId, adsId };
    return this.httpClient.post(this.url + '/ads/register-view', payload);
  }

  clickAd(userId, adId) {
    const payload = { userId, adId };
    return this.httpClient.post(this.url + '/ads/register-click', payload);
  }


  getPublicationExt() {
    return this.httpClient.get(this.url + '/ads/get-external-ad');
  }

  getPublicationExtVast() {
    return this.httpClient.get(this.url + '/ads/get-ad-vast');
  }

  async  detectAdBlock() {
    let adBlockEnabled = false;
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    try {
      await fetch(new Request(googleAdUrl)).catch(_ => adBlockEnabled = true);
    } catch (e) {
      adBlockEnabled = true;
    } finally {
      if (adBlockEnabled) {
        this.layoutService.setShowModalBlockAds(true);
      }
    }
  }
}
