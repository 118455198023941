import { Component, OnInit } from '@angular/core';
import { concatWith, Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { UsersService } from 'src/app/shared/services/users/users.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-red-social-model',
  templateUrl: './red-social-model.component.html',
  styleUrls: ['./red-social-model.component.scss'],
})
export class RedSocialModelComponent implements OnInit {

    formLink = new FormGroup({
    link: new FormControl('', Validators.required),
  });

  redSocial = [];
  data: any = {};
  userId: any;
  myId: any;
  showLinksForDelete = false;
  createModalRedSocial = false;
  subDataRedSocial: Subscription;
  typeRedSocial: any;
  constructor(
    private layoutService: LayoutService,
    private authService: AuthServiceService,
    private userService: UsersService
  ) { }

  ngOnInit() {
    const user = this.authService.getLocalStorage('user');
    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    this.subDataRedSocial = this.layoutService.getDataModalRedSocial().subscribe((data: any) => {
      this.userId = data;
      if (data.deleteLink) {

        this.userId = data.userId;
        this.showLinksForDelete = data.deleteLink;
      }
    });
    this.userService.getDataRedSocial(this.userId).subscribe((data: any) => {
      this.data = data[0];
    });
  }

  myProfile() {
    return this.userId === this.myId;
  }
deleteLink(type: string) {
  const body = {
    userId: this.userId,
    redSocialType: type
  };

  this.userService.deleteDataRedSocial(body).subscribe(val => {
        this.data = val;
  });
}

  redirect(data) {
    window.open(data, '_blank');
  }

  close() {
   this.layoutService.setShowModalRedSocial(false);
  }

  creatLinkRedSocial(data) {
    this.typeRedSocial= data;
    this.createModalRedSocial= true;
  }

 subCreateRedSocial() {

    if (this.formLink.valid && this.typeRedSocial) {
      const linkValue = this.formLink.get('link').value;

      const body = {
        [this.typeRedSocial]: linkValue,
      };

      this.userService.updateDataRedSocial(body).subscribe({
        next: (response) => {
          this.close();
        },
        error: (error) => {
        }
      });
    }
 }
hasSocialLinks(): boolean {
  if (!this.data) {return false;}

  const socialLinks = ['reddit', 'telegram', 'snapchat', 'whatsapp', 'tiktok', 'instagram', 'x'];

  return socialLinks.some(link => this.data[link]);
}

}
