import { HttpRequest } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { DataComponent, LayoutService } from '../../services/layout/layout.service';
import { ProductService } from '../../services/product/product.service';
import { SocketService } from '../../services/socket/socket.service';
import { ToastService } from '../../services/toast/toast.service';
import { UsersService } from '../../services/users/users.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnChanges {
  @Input() image: any = {};
  @Output() closeFrame = new EventEmitter<boolean>();
  @Output() imageDeleted = new EventEmitter<string>();

  maxHeight = 0;
  openComments = false;
  items = [];
  productSelected;
  myProfile;

  moreOptionOpen = false;
  confirmDeleteOpen = false;
  myId: any;
  idAccountClient: any;
  myFunds: any;
  isModalOpen = false;
  idAccountSeller: any;
  dataModalPay: { title: string; message: string };
  // isMyUser = false;

  constructor(
    private router: Router,
    private productService: ProductService,
    private usersService: UsersService,
    private authService: AuthServiceService,
    private accountsService: AccountsService,
    private toastService: ToastService,
    private layoutService: LayoutService,
    private socket: SocketService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.productService.getProduct(this.image.id).subscribe(
      (res: any) => {
        this.productSelected = res;
        this.createComments(res.comments);
      },
      (error) => {
        console.error(error);
      }
    );
    this.usersService.getMyProfile().subscribe(
      (res: any) => {
        this.myProfile = res.dataUser;
      },
      (error) => {
        console.error(error);
      }
    );

    const user = this.authService.getLocalStorage('user');
    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    this.idAccountClient = userParsed.accountId;
    this.accountsService.getMyAccount().subscribe(
      (res: any) => (this.myFunds = res.amount),
      (error) => console.error(error)
    );
    // this.isMyUser = this.image.userId._id === this.myProfile._id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image.currentValue) {
      this.getMeta(this.image.url);
    }
  }

  createComments(comments: any[]) {
    comments.forEach((comment) => {
      const newComment = {
        img: comment.userImage,
        name: comment.nameUser,
        comment: comment.comment,
      };
      this.items.push(newComment);
    });
  }

  close() {
    this.closeFrame.emit(false);
  }


  getMeta(url: string) {
    const img = new Image();
    img.src = url;
    img.onload = () => (this.maxHeight = img.height);
  }

  publish(body: any, comment: any) {
    this.items.push({
      img: this.myProfile.imageProfile.imageUrl,
      name: this.myProfile.name,
      comment: comment.value,
    });
    const commentObject = { comment: comment.value };
    this.productService
      .addCommentProduct(commentObject, this.image.id)
      .subscribe(() => {
        comment.value = '';
      });
    body.scrollToBottom(500);
  }

  closeComments(status: any) {
    this.openComments = status;
  }

  /**
   * Abre el modal
   */
  onOpenModal() {
    this.moreOptionOpen = true;
  }

  /**
   * Abre el modal para confirmar si desea eliminar
   */
  onConfirmDelete() {
    this.moreOptionOpen = false;
    this.confirmDeleteOpen = true;
  }

  /**
   * Cierra completamente los modales para ver más opciones y confirmar el eliminar publicacion
   */
  onCancelDelete() {
    this.confirmDeleteOpen = false;
    this.moreOptionOpen = false;
  }

  /**
   * Elimina la publicacion y cierra el modal
   */
  deletePublication() {
    this.onCancelDelete();
    const id = this.image.id;
    const i = this.image.images.findIndex((image: any) => image.id === id);
    this.image.images.splice(i, 1);
    this.imageDeleted.emit(this.image.id);
  }




  buyProduct(product: any) {
    if (product.userId === this.myId) {
      return;
    }
    this.productSelected = product;
    this.isModalOpen = true;

    this.accountsService.getUserAccount(this.productSelected.userId
    ).subscribe(
      (res: any) => {


        this.idAccountSeller = res._id;
      },
      (error) => {
        console.error(error);
      }
    );

    this.dataModalPay = {
      title: 'GENERAL.BUY',
      message: this.translateService.instant('NOTIFICATION.THE_PRICE_PRODUCT')
    };
  }

  pay() {
    this.isModalOpen = false;
    const payload = {
      idAccountSeller: this.idAccountSeller,
      idAccountClient: this.idAccountClient,
      aprovedClient: true,
      aprovedSeller: true,
      amount: this.productSelected.price,
      product: this.productSelected._id,
      type: 3,
      quantity: this.productSelected.stock,
      sellerId: this.productSelected.userId,
    };

    const total = this.productSelected.price;

    if (total <= this.myFunds) {

      this.productService.buyProduct(this.productSelected._id, payload).subscribe(
        (res: any) => {
          const userSellerId = res.sellerId;
          const billingId = res._id;
          this.productService
            .removeQuantityFromStock(this.productSelected._id, {
              quantity: payload.quantity,
            })
            .subscribe(
              () => {
                const req = {
                  url: this.router.url.substring(0, 12),
                  method: 'POST',
                } as HttpRequest<string>;
                this.toastService.presentToastMiddleCheck(req);
                this.sendNotification(userSellerId, this.myId, billingId);
                this.isModalOpen = false;
                const component: DataComponent = {
                  component: 'my-products',
                  contextId: '',
                  reload: true,
                  action: 'close'
                };
                this.layoutService.setComponent(component);
              },
              (error: any) => console.error(error)
            );
        },
        (error) => console.error(error)
      );
    } else {
      this.toastService.presentToast('Saldo insuficiente', 3000);
    }

  }

  /**
   * Send a notification when ocurred and action
   *
   * @param userReceiver indicates the user to receive the notification
   * @param userSender indicates the user to send the notification
   */
  sendNotification(userReceiver: string, userSender: string, billingId: string) {
    const notification = {
      userReceiver,
      userSender,
      type: 'product',
      contextId: billingId,
      message: 'NOTIFICATION.YOU_PURCHASED_A_PRODUCT'
    };
    this.socket.sendNotification(notification);
  }
}
