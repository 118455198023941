import { Component, OnChanges, OnDestroy, OnInit, Renderer2,SimpleChanges} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PublicationService } from 'src/app/shared/services/publication/publication.service';
import { UsersService } from '../../services/users/users.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { LayoutService } from '../../services/layout/layout.service';
import { MenuController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import SwiperCore, {  Scrollbar} from 'swiper';
import { AdsService } from '../../services/ads/ads.service';
// install Swiper modules
SwiperCore.use([Scrollbar]);


@Component({
  selector: 'app-recomendations',
  templateUrl: './recomendations.component.html',
  styleUrls: ['./recomendations.component.scss'],
})
export class RecomendationsComponent implements OnInit, OnDestroy, OnChanges {

  userIcon = 'assets/images/image-profile-default.png';
  defaultBackgroundImage = 'assets/images/banner-profile-default.png';

  publicationsSearched = [];
  usersSearched = [];
  isSearchBarEmpty = true;

  users: any = [];

  pageLoaded = false;

  topModels = [];
  topPublications = [];

  subscriptionFollowers: Subscription;

  page=0;
  myUser: any;

  followings = [];
  subscriptionViewAllStatusUser: Subscription;
  activeDeleteImageSubscription: Subscription;
  usersAllViewStates = [];
  idCommentsPublication: any = null;
  isDeviceIOS = false;

  activeUsers: any = [];
  activeUserSubscription: Subscription;

  activeAdd = 0;
  ads: any[] = [];

  searchbarHidden = false;

  constructor(
    private publicationService: PublicationService,
    private usersService: UsersService,
    private renderer: Renderer2,
    private authService: AuthServiceService,
    private layoutService: LayoutService,
    private menuController: MenuController,
    private router: Router,
    private adsService: AdsService,
  ) {}

  ngOnInit() {

    if (this.router.url.includes('/explorer')) {
      this.searchbarHidden = true;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/explorer')) {
          this.searchbarHidden = true;
        } else {
          this.searchbarHidden = false;
        }

      }
    });

    this.getAds();
    this.isDeviceIOS = this.authService.isDeviceIOS;
    this.myUser = JSON.parse(this.authService.getLocalStorage('user'));
    //this.getTops();
    this.subscriptionViewAllStatusUser = this.layoutService.getNewUserViewAllStates().subscribe((usersAllViewStates: string[]) => {
      this.usersAllViewStates = usersAllViewStates;
    });


    //  this.subscriptionFollowers =this.usersService.getMyFollowing().subscribe((res: any) => {
    //   this.followings = res;
    //  });
    //  this.layoutService
    //    .getShowModalCommentFrame()
    //    .subscribe((val) => {

    //      if (this.idCommentsPublication) {
    //        if (val == false) {
    // eslint-disable-next-line max-len
    //          const index = this.topPublications.findIndex(img => (img._id == this.idCommentsPublication || img.id == this.idCommentsPublication));

    //          if (this.layoutService.quantityComments != this.topPublications[index].quantityComments) {
    //            this.topPublications[index].quantityComments = this.layoutService.quantityComments;
    //          }
    //          this.idCommentsPublication = null;
    //        } else {
    // eslint-disable-next-line max-len
    //          const index = this.topPublications.findIndex(img => (img._id == this.idCommentsPublication || img.id == this.idCommentsPublication));

    //          if (this.layoutService?.quantityComments != this.topPublications[index].quantityComments) {
    //            this.topPublications[index].quantityComments = this.layoutService.quantityComments;
    //          }
    //        }

    //      }
    //    });

      //  this.activeUserSubscription = this.usersService.getUsersActive().subscribe(user => {
      //   this.activeUsers = user;
      // });

  }


  getAds() {
    //verificar la publicidad
    //getAdsByPreferences
    this.adsService.getAdsByPreferences().subscribe( (ads: any[]) => {
      if (ads.length > 0) {
         this.ads = ads;
      } if (ads.length === 0) {
        this.adsService.getAdds().subscribe((adds: any[]) => {
          this.ads = adds;
        });
      }
      this.changeAdd();
      const adsId = ads.map(ad => ad._id);
      // this.registerView(adsId);
    });
  }


  openModalSubPlataform() {
    if (this.getUserType() === '1') {

      const data = {
        type: 'model',
        paySub: true
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
    }

    if (this.getUserType() === '2') {

      const data = {
        type: 'client',
        paySub: true
      };
      this.layoutService.setDataModalPaymentSubsPlataform(data);
    }
    this.layoutService.setShowModalPaymentSubsPlataform(true);
  }

  changeAdd() {
    setInterval(() => {
      if (this.activeAdd == (this.ads.length - 1)) {
        this.activeAdd = 0;
      } else {
        this.activeAdd++;
      }
      //const ad = this.ads[this.activeAdd];
    }, 8000);
  }

  // registerView(adsId) {
  //   this.adsService.viewAd(this.myUser._id, adsId).toPromise();
  // }

  registerClick(adId) {
    this.adsService.clickAd(this.myUser._id, adId).subscribe();
  }


  isActive(id: string) {
    return this.activeUsers.find(userActive => userActive.user === id)?.active;
  }

  ngOnDestroy(): void {
    if (this.subscriptionViewAllStatusUser) {
      this.subscriptionViewAllStatusUser.unsubscribe();
    }
    if (this.subscriptionFollowers) {
      this.subscriptionFollowers.unsubscribe();
    }
    if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
  }

   ngOnChanges(changes: SimpleChanges): void {
    if (changes.topPublications.currentValue) {
      this.topPublications.map(img => img.quantityComments = img.comments.length);
    }
  }

//   showFollowers(id) {
//   this.usersService.getSellerFollowers(id).subscribe(
//         (res: any) => {
//       const payload = {
//       userId: id,
//       data: res,
//       type: 'followers'
//     };
//     this.layoutService.setShowModalSubsFollow(true);
//     this.layoutService.setDataModalSubsFollow(payload);
//         },
//         (err) => console.error(err)
//       );
// }

  // getLengthComment() {
  //       this.layoutService
  //     .getShowModalCommentFrame()
  //         .subscribe((val) => {
  //       if (val === false && this.idCommentsPublication) {
  //         const index = this.topPublications.findIndex(img => img.id === this.idCommentsPublication);
  //         if (this.layoutService.quantityComments !== this.topPublications[index].quantityComments) {
  //           this.topPublications[index].quantityComments = this.layoutService.quantityComments;
  //         }
  //         this.idCommentsPublication = null;
  //       }
  //     });
  // }


  // evaluateStates(user) {
  //   let state = null;
  //   if (user?.states?.length == 0 || user._id == this.myUser._id) {
  //     state = 'noState';
  //   } else if (user?.states?.every((state) => state.seen.includes(this.myUser._id)) || this.usersAllViewStates.includes(this.myUser._id)) {
  //     state ='allView';
  //   }
  //   return state;
  // }

  openStateModal(e: Event, states) {
    this.menuController.close();
    e.stopPropagation();
    this.layoutService.setShowStateModal(states);
  }

  // unfollow(publication) {
  //   const userSellerId = publication._id;
  //   this.usersService.unfollow({ userSellerId }).subscribe(
  //     (res) => {
  //       this.topModels.forEach((image: any) => {
  //         if (publication._id === image._id) {
  //           image.followingSeller = false;
  //           image.followers -= 1;
  //         }
  //       });
  //     },
  //     (err) => console.log(err)
  //   );
  // }

  // openModalWish(userAuthorId) {
  //   this.menuController.close();
  //   const wishData = {
  //     userId: userAuthorId._id ? userAuthorId._id : userAuthorId
  //   };
  //   this.layoutService.setDataWishComponentNewWish(wishData);
  //   this.layoutService.setShowComponentNewWish(true);
  // }


  // follow(publication) {
  //   const userSellerId = publication._id;
  //   this.usersService.follow({ userSellerId }).subscribe(
  //     (res) => {
  //       this.topModels.forEach((image: any) => {
  //         if (publication._id === image._id) {
  //           image.followingSeller = true;
  //           image.followers += 1;
  //         }
  //       });
  //     },
  //     (err) => console.log(err)
  //   );
  // }

  navigateGo(id) {
    this.menuController.close();
    this.router.navigate(['profile', id]);
  }
  ionViewDidEnter() {

   // this.getTops();
    this.usersService
      .getUsersGenerators(0, this.myUser.preferences)
      .pipe(debounceTime(500))
      .subscribe((res: any[]) => {
        this.users = res;
        this.loadPage();
      });
  }



  openModalOffert(publication) {
   this.menuController.close();
    const offerData = {
      userClientId: this.myUser._id,
      userSellerId: publication?._id,
      publication,
      amount: 0,
      comments: []
    };
    this.layoutService.setDataNewOffer(offerData);
    this.layoutService.setShowNewOffer(true);
  }
  // getTops() {
  //   // const width = window.innerWidth;
  //   this.usersService.getTops().subscribe((res: any) => {
  //       this.idCommentsPublication = res.id;
  //     if (res.publication && res.users) {
  //       this.pageLoaded = true;
  //     }
  //     // if(width < 800){
  //     this.topPublications = res.publications.map(pub => {
  //       pub.publicationLikes = pub?.publicationLikes[0]?.usersId;
  //       pub.quantityComments = pub?.omments?.length;
  //       return pub;
  //     });
  //     // } else if(width > 825) {
  //     //  const data = res.publications.map(pub => {
  //     //   pub.publicationLikes = pub.publicationLikes[0].usersId;
  //     //   pub.quantityComments = pub.comments.length;
  //     //   return pub;
  //     //  });

  //       // this.topPublications = data.slice(0, 2);
  //       // }
  //     this.topModels = res.users;
  //   });

  //   this.getLengthComment();
  // }





    snapIndexChange(event: any) {
    const activeIndex = event[0].snapIndex;

    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];

      const assetElement = activeSlide?.querySelector('audio') ? activeSlide.querySelector('audio') : activeSlide?.querySelector('video');

      if (assetElement) {

        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
    }

  // openModalComments(publication) {

  //   publication.userId = publication.userAuhthorId[0];
  //   this.idCommentsPublication = publication.id ? publication.id : publication._id;

  //   this.layoutService.setShowModalCommentFrame(true, publication.quantityComments);
  //   this.layoutService.setDataCommentFrame(publication);

  //   this.close();

  //   }

  convertirArchivo(url) {
    // Comprobar si la URL termina en ".mp4" para identificar un archivo de video
    if (url.endsWith('.mp4') || url.endsWith('.mov')) {
    // Convertir la URL del video en una URL de conversión a GIF
    const transformation = 'ar_16:9,c_fill,q_auto,eo_5,so_0';
    const parts = url.split('/upload/');

    if (parts.length === 2) {
       url = `${parts[0]}/upload/${transformation}/${parts[1]}`;
    }
    const gifUrl = url.replace('.mp4', '.gif').replace('.mov', '.gif');
    return gifUrl;
  } else {
    // Si no es un archivo de video, devolver la URL original
    return url;
  }
  }



  // changeFavoriteStatus(image) {
  //   const idPublication = image._id ? image._id : image.id;
  //   if (image.publicationLikes.includes(this.myUser._id)) {
  //     this.publicationService
  //       .dislike(idPublication)
  //       .subscribe((res: any) => {
  //         if (res.likes) {
  //           image.likes = res?.likes;
  //           image.publicationLikes = res.publicationLikes.usersId;
  //           const index = this.topPublications.findIndex(img => img._id === idPublication);
  //           this.topPublications[index].likes = res?.likes;
  //         }
  //       });
  //   } else {
  //     this.publicationService
  //       .like(idPublication)
  //       .subscribe((res: any) => {
  //         if(res.likes){
  //         image.likes = res?.likes;
  //         image.publicationLikes = res.publicationLikes.usersId;
  //         const index = this.topPublications.findIndex(img => img._id === idPublication);
  //           this.topPublications[index].likes = res?.likes;
  //           }
  //       });
  //   }
  // }

  imagePublication(publication) {
    if (publication.images[0]?.imageUrl) {
      return publication.images[0]?.imageUrl;
    }

    if (publication.videos[0]) {
      return this.getImagePreviewOfVideo(publication.videos[0]?.videoUrl);
    }

    if (publication.audios[0]) {
      return 'assets/icon/Icons-Lust - microphone-01.svg';
    }
  }

  getImagePreviewOfVideo(videoUrl) {
    return videoUrl.replace(/\.[^/.]+$/, '.webp');
  }
  close() {
    this.menuController.close('menu2');
  }

  toFrameDetailImage(publication) {
    this.menuController.close('menu2');
   const image = {
        id: publication._id,
        url: this.imagePublication(publication),
        title: publication.title,
        description: publication.description,
        images: publication.images,
        videos: publication.videos,
        audios: publication.audios,
        userId: publication?.userAuhthorId[0],
        imageProfile: publication?.imageProfile[0],
        likes: publication.likes,
        comments: publication.comments,
        publicationLikes: publication?.userAuhthorId[0]?._id
      };
   this.layoutService.setShowModalFrameDetail(true);
   this.layoutService.setDataFrameImage(image);
  }

  loadData(event){
    this.page++;
    this.usersService
      .getUsersGenerators(this.page, this.myUser.preferences)
      .pipe(debounceTime(500))
      .subscribe((res: any[]) => {
        this.topModels = [...this.topModels, ...res];
        event.target.complete();
      });
  }


  onClearSearch() {
    this.publicationsSearched = [];
    this.usersSearched = [];
    this.isSearchBarEmpty = true;
  }

  onSearch(event: any) {
    const value = event.detail.value;
    const keycode = event.keyCode || event.which;
    if (keycode == 13) {
      this.goToExplorer(value);
      this.onClearSearch();
      return;
    }

    if (value) {
      this.searchItem(value);
    } else {
      this.onClearSearch();
    }
  }

  goToExplorer(parameter: any) {
    this.layoutService.setParameterSearch(parameter);
    this.router.navigate(['explorer']);
  }

  searchItem(item) {

    this.isSearchBarEmpty = false;
    this.publicationService
      .searchPublication(item)
      .pipe(debounceTime(500))
      .subscribe((res: any) => {
        this.searchPublication(res);
        this.searchUser(res);
      });
  }

  searchPublication(publications) {

    this.publicationsSearched = publications

      .filter(
        (publication) =>
          publication.hasOwnProperty('title') && publication.images?.length
      )
      .map((publication) => ({
        ...publication,
        imageUrl: publication.images[0].imageUrl,
        userId: {
          ...publication.userAuhthorId,
          name: publication.userAuhthorId.userName,
        },
      }));
  }

  searchUser(users) {
    this.usersSearched = users
      .filter((user) => user.hasOwnProperty('name'))
      .map((user) => ({
        ...user,
        url: !user.imageProfile ? [this.userIcon] : user.imageProfile.imageUrl,
      }));
  }
    getUserType() {
    return this.authService.getLocalStorage('userType');
  }



  loadPage() {
    this.pageLoaded = true;
  }

  onIntersection(
    {
      target,
      visible,
    }: {
      target: HTMLElement;
      visible: boolean;
    },
    user?: any
  ): void {
    if (visible) {
      let urlCover = target.dataset.src;
      if (user && user.imageCover) {
        urlCover = user.imageCover[0]?.imageUrl;
      }
      const backgroundImage = urlCover ?? this.defaultBackgroundImage;
      this.renderer.setStyle(
        target,
        'background-image',
        `url(${backgroundImage})`
      );
    } else {
      this.renderer.removeStyle(target, 'background-image');
    }
  }
}
