import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { FileService } from '../../services/file/file.service';

@Component({
  selector: 'video-trimmer',
  templateUrl: './video-trimmer.component.html',
  styleUrls: ['./video-trimmer.component.scss']
})
export class VideoTrimmerComponent implements OnInit, OnChanges {

  private stream;
  private mediaRecorder;
  private recordedBlobs;
  private sourceBuffer;
  private superBuffer;

  initTime = 0;
  lastTime = 4;

  enableRecord = false;

  @ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;

  @Input('source') source: string = "assets/video/Typing_example.ogv";
  @Input('file') file: any;
  @Input('maxDuration') maxDuration: any;


  @Output('fileReturn') fileReturn: EventEmitter<any> = new EventEmitter();
  @Output('closeTrimVideo') closeTrimVideo: EventEmitter<any> = new EventEmitter();
  @Output('videoTrimId') videoTrimId: EventEmitter<any> = new EventEmitter();

  duration = 0;

  isPlay = false;
  isDeviceIOS = false

  constructor(
    private authService: AuthServiceService,
    private fileService:FileService
  ) { }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.file.currentValue) {
      this.enableRecord = false;
      this.video.nativeElement.src = URL.createObjectURL(changes.file.currentValue);
    }
  }

  close () {
    this.closeTrimVideo.emit();
  }

  ngOnInit () {
    this.isDeviceIOS = this.authService.isDeviceIOS
    this.video.nativeElement.ontimeupdate = () => {
      if (this.lastTime && this.video.nativeElement.currentTime >= this.lastTime) {
        this.video.nativeElement.pause();
        if (this.enableRecord) {
          this.stopRecording();
          //this.cdr.detectChanges();
        }
      }
    };
    window['video'] = this.video;
    this.video.nativeElement.onloadeddata = () => {
      this.duration = Math.floor(this.video.nativeElement.duration);
      this.lastTime = this.duration;
    };
  }

  play () {
    this.video.nativeElement.currentTime = this.initTime;
    this.video.nativeElement.play();
    this.isPlay = true;
  }

  stop () {
    this.video.nativeElement.pause();
    this.isPlay = false;
  }

  trimVideo () {

    this.enableRecord = true;
    const formData = new FormData();
    formData.append('file', this.file);
    if (this.lastTime > this.duration) {
      this.lastTime = this.duration
    }
    this.fileService.cropVideo(formData, this.initTime, this.lastTime).subscribe((res: any) => {
      this.enableRecord = false;
      this.videoTrimId.emit(res._id)
    }, (err) => {
      this.closeTrimVideo.emit()
    })

  }

  setTimeInit (value) {
    if (this.video && this.video.nativeElement.duration) {
      this.initTime = value;
      this.video.nativeElement.currentTime = this.initTime;
    }
  }

  setTimeLast (value) {
    let timeFinish = value;
    this.lastTime = timeFinish;
  }

  handleDataAvailable (event) {
    if (event.data && event.data.size > 0) {
      this.recordedBlobs.push(event.data);
    }
  }

  handleStop (event) {

    this.superBuffer = new Blob(this.recordedBlobs, { type: 'video/webm' });

    const videoFile = new File([this.superBuffer], 'video.mp4', { type: 'video/mp4', lastModified: Date.now() });
    this.enableRecord = false;
    this.fileReturn.emit(videoFile);
  }

  stopRecording () {
    this.mediaRecorder.stop();
  }

}
