import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { environment } from 'src/environments/environment';
import { UsersService } from '../../services/users/users.service';
import { PublicationService } from '../../services/publication/publication.service';
import { LayoutService } from '../../services/layout/layout.service';
import { ToastService } from '../../services/toast/toast.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../services/file/file.service';

@Component({
  selector: 'app-plastt-frame-total-publication',
  templateUrl: './plastt-frame-total-publication.component.html',
  styleUrls: ['./plastt-frame-total-publication.component.scss'],
})
export class PlasttFrameTotalPublicationComponent implements OnInit ,OnChanges{
  @Input() isWishDetail = false;
  @Output() closeFrame = new EventEmitter<any>();
  @Output() likeStatus = new EventEmitter<any>();
  @Output() openComments = new EventEmitter<any>();
  @Output() imageDeleted = new EventEmitter<string>();
  @Output() openEditPublication = new EventEmitter<any>();
  @ViewChild('gallery') gallery: ElementRef;
   image: any = {};


  detailImage = false;

  maxHeight = 0;

  myId = '';
  userId = '';
  priceForSubscription = 0;
  sharedSubscription = 0;

  frameExplorer = false;

  moreOptionOpen = false;
  confirmDeleteOpen = false;
  showPub = false;
  modalOptionsImage = false;
  messageSubscription = {};

  isModalOpen = false;
  isModalReport = false;
  typeReport = '';
  indexImg: null | number = null;
  linkRedirect: string;
  copyActive = false;
  sharePublication = false;

  wishDetailImageProfile = '';
  wishDetailUserName = '';
   isDeviceIOS = false;
  fullScreen = false;
  activeUsers: any = [];
  activeUserSubscription: Subscription;
  susbscriptionDataImageFrame: Subscription;
  aspectoRatio = 16 / 9;
  widthMax = 300;

  private env = environment;

  constructor(
    private router: Router,
    private authService: AuthServiceService,
    private usersService: UsersService,
    private publicationService: PublicationService,
    private layoutService: LayoutService,
    private toastService: ToastService,
    private sanitizer: DomSanitizer,
    private fileService: FileService

  ) {}

  ngOnInit() {

      this.isDeviceIOS = this.authService.isDeviceIOS;
    const user = this.authService.getLocalStorage('user');
    if (window.innerWidth > 1020) {
      this.widthMax = 265;
    }

    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    if (userParsed.contentManagerId) {
      this.myId = userParsed.contentManagerId;
    }
    this.layoutService.getDataFrameImagePlastt().subscribe(val => {
      this.image = val;
      this.userId = val.userId._id;
    });
  }

   navigateTo() {
    this.router.navigate(['/profile/', this.userId]);
    this.layoutService.setShowModalFrameDetail(false);
    if (this.image.userId !== this.myId) {
       this.usersService.registerViewProfileByPublication(this.myId, this.userId, this.image._id).subscribe();
    }
   }
   showComment() {
    this.layoutService.setShowModalCommentFramePlastt(true);
    this.layoutService.setDataCommentFramePlastt(this.image);

   }


    ngOnChanges(changes: SimpleChanges): void {
      if (changes.image.currentValue) {
        this.getMeta(this.image.url);
        if (this.image.userId) {
          this.userId = this.image.userId._id ? this.image.userId._id : this.image.userId;
        }
      }
    }

  getMeta(url: string) {
    const img = new Image();
    img.src = url;
    img.onload = () => (this.maxHeight = img.height);
  }

  showModalCopy() {
    this.modalOptionsImage=false;
    this.sharePublication = true;
    this.linkRedirect = this.env.urlFront + 'preview/' + this.image.id;
  }

  openModalMetrics(publication) {
    // this.publicationService.getMetricsPublication(publication.id).subscribe((val: any) => {
    //   const data = {
    //     from: 'gallery-uniform',
    //     data: val
    //   };
    //   this.layoutService.setShowModalMetrics(true);
    //   this.layoutService.setDataModalMetrics(data);
    // });
  }

    deletePublication() {
    this.closeModal();
    this.deletePublications(this.image.id);
    }

    deletePublications(id: string) {
      this.publicationService.deletePublicationPlastt(id).subscribe(() => {
        this.layoutService.setActiveDeleteImage(true);
        this.layoutService.setShowModalFrameDetailPlasttt(false);
      });
    }

   onOpenModal() {
    this.moreOptionOpen = true;
  }

    closeModal() {
    this.confirmDeleteOpen = false;
    this.moreOptionOpen = false;
    }

  openModalOptions() {
    this.modalOptionsImage = !this.modalOptionsImage;
  }

   isOneImage(image) {

    return image?.images?.length === 1 && image?.videos?.length === 0;
   }

   snapIndexChange(event: any) {
    const activeIndex = event[0].snapIndex;

    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];

      const assetElement = activeSlide?.querySelector('audio') ? activeSlide.querySelector('audio') : activeSlide?.querySelector('video');

      if (assetElement) {

        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
   }


   confirmModal() {
    if (this.isModalReport)  {
      switch (this.typeReport) {
        case 'report-publication':
          this.reportPublication();
          break;
        case 'hidden-publication':
          this.hiddenPublication();
          break;

        default:
          break;
      }
    }
   }

  reportPublication() {
    this.usersService.report(this.userId, 'undefined', this.image.id).subscribe((res) => {

      this.toastService.presentToast('NOTIFICATION.REPORT_PUBLICATION', 2000);

      this.moreOptionOpen = false;
      this.isModalOpen = false;
      setTimeout(() => {
        this.openModalHiddenPublication();
      }, 500);
    });
    }


 onConfirmDelete() {
  this.moreOptionOpen = false;
   this.confirmDeleteOpen = true;
  }

  openModalHiddenPublication() {
    this.modalOptionsImage=false;
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'hidden-publication';

  }

   openModalReportPublication() {
    this.modalOptionsImage=false;
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'report-publication';
   }

     closeSharedPublicationModal() {
    this.sharePublication = false;
    this.moreOptionOpen = false;
  }

      copySharedProfile(value) {
    this.copyActive = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
     this.toastService.presentToast('NOTIFICATION.COPIED_CLIPBOARD', 2000);
  }

     getSafeLink(url): SafeUrl {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  hiddenPublication() {
    this.usersService.excludePublication(this.image.id).subscribe((res) => {

      this.toastService.presentToast('NOTIFICATION.HIDDEN_PUBLICATION', 2000);

      this.moreOptionOpen = false;
      this.isModalOpen = false;
      this.isModalReport = false;
    });
  }

  changeFavoriteStatus(image: any) {
    if (image.plasttpublicationLikes.usersId.includes(this.myId)) {
      this.publicationService.dislikePlastt(image.id).subscribe((res: any) => {
        image.likes = res?.likes;
       image.plasttpublicationLikes = res?.plasttpublicationLikes;
      });
    } else {
      this.publicationService.likePlastt(image.id).subscribe((res: any) => {
  
        image.likes = res?.likes;
        image.plasttpublicationLikes = res?.plasttpublicationLikes;
      });
    }
}

  getLikeIcon(image: any): string {
  return image?.plasttpublicationLikes?.usersId?.includes(this.myId)
    ? 'assets/icon/likeonpro2024.svg'
    : 'assets/icon/likeoffpro2024.svg';
}


    close() {
    this.image = null;
    this.layoutService.setShowModalFrameDetailPlasttt(false);
  }

}
