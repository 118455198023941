import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';

import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FileService {

  urlServiceFile = environment.API_SERVICE_FILE_LL;

  constructor(private httpClient: HttpClient, private authService: AuthServiceService, ) {}



 transformImageByChatRoom(value) {
    return value;  }


  getGifByVideoUrl(url) {
    if (url.endsWith('.mp4') || url.endsWith('.mov')) {

      const transformation = 'ar_16:9,c_fill,q_auto,eo_5,so_0';
      const parts = url.split('/upload/');

      if (parts.length === 2) {
        url = `${parts[0]}/upload/${transformation}/${parts[1]}`;
      }
      const gifUrl = url.replace('.mp4', '.gif').replace('.mov', '.gif');
      return gifUrl;
    } else {

      return url;
    }
  }


  transformUrlVideo(value, isPreview?) {
    if (value.endsWith('.mp4') || value.endsWith('.webm') || value.endsWith('.ogg')) {

      if (this.authService.isDeviceIOS) {
        if (value.includes('.webm')) {
          return value.replace('.webm', '.mp4');
        }
        return value;
      } else {
        return value.replace('.mp4', '.webm').replace('.ogg', '.webm');
      }
    } else {

      return value;
    }
  }


  convertBase64ToVideo(base64Video: string) {
    return this.base64ToBlob(base64Video, 'video/mp4');
  }

  private base64ToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }




}
