import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { PublicationService } from '../../services/publication/publication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';

import {  SafeUrl } from '@angular/platform-browser';
import { UsersService } from '../../services/users/users.service';
import { LayoutService } from '../../services/layout/layout.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import {
  Publication,
  PublicationExtends,
} from 'src/app/core/models/publication.model';
// eslint-disable-next-line @typescript-eslint/naming-convention


import { User } from 'src/app/core/models/user.model';
import { ToastService } from '../../services/toast/toast.service';
import { HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { SocketService } from '../../services/socket/socket.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { WishService } from '../../services/wish/wish.service';



@Component({
  selector: 'app-new-publication',
  templateUrl: './new-publication.component.html',
  styleUrls: ['./new-publication.component.scss'],
})
export class NewPublicationComponent
  implements OnInit, OnDestroy, OnChanges
{

  @ViewChild('inputGallery') inputGallery: ElementRef;
  @ViewChild('inputGalleryImage') inputGalleryImage: ElementRef;
  @ViewChild('inputGalleryAudio') inputGalleryAudio: ElementRef;
  /** refactor captura de videos */
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('recordedAudio') recordAudioElementRef: ElementRef;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() open = new EventEmitter();

  @Input() ownerId: PublicationExtends | null = null;
  @Input() wishId: any = null;
  @Input() quantityMinImages: any = null;
  @Input() publication: any = null;
  @Input() isRegisterAddData = false;

  title = '';

  tempTopken = null;

  etiquetas: any;
  mediaRecorder: any;
  videoPlayer: any;
  isRecording = false;
  videos = [];
  safeUrl: SafeUrl;

  imagesLoad: any[] = [];
  videosLoad: any[] = [];
  audiosLoad: any[] = [];



  stream: MediaStream = null;
  videoSelect: any = null;

  videoElement: HTMLVideoElement;
  recordVideoElement: any;
  recordAudioElement: any;
  recordedBlobs: Blob[];
  downloadUrl: string;

  isPlayVideo = false;
  isPlayAudio = false;
  isEditPublication = false;
  /** refactor captura de videos */

  formPublication = new FormGroup({
    description: new FormControl('', [
      Validators.required,
      Validators.maxLength(280),
    ]),
    images: new FormControl([]),
    videos: new FormControl([]),
    audios: new FormControl([]),
    categories: new FormControl([], Validators.required),
    isPublic: new FormControl(true, Validators.required),
  });
  loading: HTMLIonLoadingElement;

  videosFromGallery: any[] = [];

  isOpenOptionsVideo = false;
  showUploadOptions = false;

  categories = [];

  // eslint-disable-next-line @typescript-eslint/quotes
  objCategories = "";

  myProfile: User;

  isEditImage = false;
  imgUrl = null;

  imageForEdit: ArrayBuffer | string = '';

  videoTemp: File;
  audioTemp: File;
  disabledButton = false;
  imageProfile: any;

  subsTitle: Subscription;
  dataEditPublication: Subscription;
  editPublicationBoolean= false;
  isDeviceIOS = false;
  selectActionToggle = true;
  separator = ',';
  tempTag = '';

  constructor(
    private publicationService: PublicationService,
    private loadingCtrl: LoadingController,
    private usersService: UsersService,
    private layoutService: LayoutService,
    private authService: AuthServiceService,
    private toastService: ToastService,
    private router: Router,
    private socketService: SocketService,
    private translateService: TranslateService,
    private wishService: WishService,
  ) {}


  get formPublicationImages() {
    return this.formPublication.get('images').value;
  }
  get formPublicationVideos() {
    return this.formPublication.get('videos').value;
  }
  get formPublicationAudios() {
    return this.formPublication.get('audios').value;
  }

  ngOnInit(): void {
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const imgProfile = JSON.parse(this.authService.getLocalStorage('user'));
    this.imageProfile = imgProfile.imageProfile.imageUrl;

    // this.publicationService.getCategories().subscribe((categories: any) => {
    //   this.categories = categories;
    // });
    if (!this.isRegisterAddData) {
      this.usersService.getMyProfile().subscribe((res: any) => {
        this.myProfile = res.dataUser;
        if (this.myProfile.privateProfile) {
          this.formPublication.get('isPublic').setValue(false);
        }
      });
    } else {
      this.tempTopken = this.authService.getLocalStorage('token-temp');
    }
    this.subsTitle= this.layoutService
      .getDataTitleAddPublication()
      .subscribe(val => {
        this.title = val;
      });
    this.dataEditPublication = this.layoutService
      .getDataEditPublication().subscribe(res => {
        if (res === 'edit') {
          this.editPublicationBoolean = true;
        } else {
          this.editPublicationBoolean = false;
        }

    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    const editPublication = changes.publication?.currentValue;
    if (editPublication) {
      this.isEditPublication = true;
      this.onEditPublication(editPublication);
    }
  }

  atLeastOneFile() {
    // eslint-disable-next-line max-len
    return (this.formPublication.get('images').value.length > 0 || this.formPublication.get('videos').value.length > 0 || this.formPublication.get('audios').value.length > 0);
  }

  categoryTranslate(user: string) {
    let response: string;
    switch (user) {
      case 'Latinas':
        response = 'ATTRIBUTES_MODAL.LATIN';
        break;
      case 'Principiantes':
        response = 'ATTRIBUTES_MODAL.AMATEUR';
        break;
      case 'Rubias':
        response = 'ATTRIBUTES_MODAL.BLONDE';
        break;
       case 'Naturales':
        response = 'ATTRIBUTES_MODAL.NATURAL';
        break;
       case 'nuevas':
        response = 'ATTRIBUTES_MODAL.NEW';
        break;

    }
    return response;
  }


  ngOnDestroy(): void {
    this.subsTitle.unsubscribe();
    this.dataEditPublication.unsubscribe();
  }

  ionViewWillLeave(): void {
    //this.deleteAllVideos();
  }

  uploadImage(image: string) {
    const imageToUpload = image;
    this.showLoading(1);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', file);

      this.publicationService.saveImageRegister(formData).subscribe((res: any) => {
        const newImage = {
          id: res._id,
          url: res.imageUrl,
        };
        const images = this.formPublication.get('images').value;
        images.push(newImage.id);
        this.formPublication.get('images').setValue(images);
        this.imagesLoad.push(newImage);
        this.loading.dismiss();
        this.isEditImage = false;
      },
        (err) => {
          this.loading.dismiss();
        });
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }


  /**
   * Edit the publication
   *
   * @param publication
   */
  onEditPublication(publicationEdit: PublicationExtends): void {
    this.publicationService
      .getPublication(publicationEdit.id)
      .subscribe((publication: Publication) => {
        const publicationSelected = publication[0];


        this.setFormPublicationDescription(publicationSelected?.description);
        this.setFormPublicationCategories(publicationSelected?.categories);
        this.imagesLoad = publicationSelected.images.map((pub) => ({
          url: pub?.imageUrl,
        }));
        this.videosLoad = publicationSelected.videos.map((pub) => ({
          url: pub?.videoUrl,
        }));
        this.audiosLoad = publicationSelected.audios.map((pub) => ({
          url: pub?.audioUrl,
        }));
        this.setFormPublicationImages(publicationSelected?.images);
        this.setFormPublicationVideos(publicationSelected?.videos);
        this.setFormPublicationAudios(publicationSelected?.audios);
        this.setFormPublicationIsPublic(publicationSelected?.isPublic);
      });
  }

  /**
   * Crea la publicación con su información
   */
  createPublication(): void {
    const publication: Publication = this.formPublication.value;
    const req = {
      url: this.router.url.substring(0, 8).concat('/publication'),
      method: 'POST',
    } as HttpRequest<string>;

    this.disabledButton = true;
    if (this.ownerId && this.wishId) {
      publication.userOwnerId = this.ownerId;
      publication.isPublic = false;
      this.publicationService
        .savePublicationForWish(publication, this.wishId)
        .subscribe( async () => {
          await this.acceptWish();
          this.disabledButton = false;
          this.sendNotification(this.ownerId._id, 'NOTIFICATION.THEY_ACCEPTED_YOUR_WISH');

          this.toastService.presentToast('PUBLICATION.TOAS_SAVE_PUBLICATION', 2000);
          this.layoutService.setNewPublication();
          const dataSavePublicationSave = JSON.parse(
            this.authService.getLocalStorage('publicationForWish')
          );
          if (dataSavePublicationSave) {
            this.authService.removeLocalStorage('publicationForWish');
          }
          this.layoutService.refreshComponent();
          this.close(true);
        },
         (err) => {
            this.disabledButton = false;
          });
    } else {
      this.publicationService
        .savePublication(publication, this.tempTopken)
        .subscribe((res: any) => {
          this.disabledButton = false;
          if (res.isPublic) {
            this.sendMultipleNotifications(res?._id);
          } else {
            const publicationId = res._id;
            this.usersService.getMySubscribers().subscribe((users: any[]) => {
              const userReceivers = users.map((item) => item.userClientId);
              const noti = {
                userReceivers,
                userSender: this.myProfile._id,
                type: 'post',
                contextId: publicationId,
                linkRedirect: '/profile/' + this.myProfile._id,
              };
              this.socketService.sendNotificationMultiple(noti);
            });
          }
          if (this.isRegisterAddData) {
            this.continueNextStep();
          }
          this.close(true);
          this.toastService.presentToast('PUBLICATION.TOAS_SAVE_PUBLICATION', 2000);
          this.layoutService.setNewPublication();
        }, (err) => {
          this.disabledButton = false;
        });
    }
  }

  async acceptWish() {
    const wish: any = await this.wishService.getWish(this.wishId).toPromise();
    const payload = {
      wishId: this.wishId,
      accept: true,
      comment: '',
      isSexting: wish.title == 'Sexting'
    };
    await this.wishService.acceptOrRejectWish(payload).toPromise();
    this.layoutService.setUpdateListwish();
    this.layoutService.setUpdateStatus();
    this.layoutService.setShowModalWishDetail(false);
  }

  sendMultipleNotifications(publicationId: string) {
    if (!this.myProfile) {
      return;
    }
    Promise.all([
      this.usersService.getSellerFollowersList(this.myProfile._id).toPromise(),
      this.usersService.getMySubscribers().toPromise(),
    ])
      .then((results) => {
        let followers: any = results[0];
        let subscribers: any = results[1];
        followers = followers.map((item) => item.userClientId);
        subscribers = subscribers.map((item) => item.userClientId);
        const userReceivers = this.mergeNoDuplicates(followers, subscribers);

        const noti = {
          userReceivers,
          userSender: this.myProfile._id,
          type: 'post',
          contextId: publicationId,
          linkRedirect: '/profile/' + this.myProfile._id,
        };
        this.socketService.sendNotificationMultiple(noti);
      })
      .catch((error) => {
        console.error('At least one promise rejected:', error);
      });
  }

  sendNotification(userReceiver,  message: string) {
    const notification = {
      userReceiver,
      userSender: this.myProfile._id,
      type: 'wish',
      message,
      contextId: this.wishId
    };
    this.socketService.sendNotification(notification);
  }

  mergeNoDuplicates(arr1, arr2) {
    const mergedArray = [...arr1, ...arr2];
    const mergedUnique = mergedArray.reduce((noDuplicates, item) => {
      if (noDuplicates.includes(item)) {
        return noDuplicates;
      } else {
        return [...noDuplicates, item];
      }
    }, []);

    return mergedUnique;
  }

  /**
   * Elimina la imagen o video seleccionada luego de que se cargo
   */
  onDeleteMedia(mediaSelected: PublicationExtends): void {
    const index = this.imagesLoad.findIndex(
      (media) => media.id === mediaSelected.id
    );
    this.imagesLoad.splice(index, 1);
    this.formPublicationImages.splice(index, 1);
    this.deleteMedia(mediaSelected.id, 'image');
  }

  onDeleteMediaVideo(mediaSelected: PublicationExtends): void {
    const index = this.videosLoad.findIndex(
      (media) => media.id === mediaSelected.id
    );
    this.videosLoad.splice(index, 1);
    this.formPublicationVideos.splice(index, 1);
    this.deleteMedia(mediaSelected.id, 'video');
  }

  onDeleteMediaAudio(mediaSelected: PublicationExtends): void {
    const index = this.audiosLoad.findIndex(
      (media) => media.id === mediaSelected.id
    );
    this.audiosLoad.splice(index, 1);
    this.formPublicationAudios.splice(index, 1);
    this.deleteMedia(mediaSelected.id, 'audio');
  }

  /**
   * Verifica si la publicación es de creación o edición para publicarla.
   */
  onSubmitPublish(): void {
     if (!this.isEditPublication) {
       this.createPublication();
     } else {
       this.saveEditPublication();
     }
  }

  /**
   * Guarda los cambios de la publicación editada
   */
  saveEditPublication(): void {
    if (this.imagesLoad.length) {
      this.disabledButton = true;
      const { id } = this.publication;
      const payload = this.formPublication.value;
       this.publicationService.saveEditPublication(id, payload).subscribe((res) => {
         this.disabledButton = false;
         const req = {
           url: this.router.url.substring(0, 8).concat('/publication'),
           method: 'PUT',
         } as HttpRequest<string>;
        this.toastService.presentToastMiddleCheck(req);
         this.close(true);

       });
    }
  }

  close(force?) {
    if (this.ownerId && !force) {
      return;
    }
    if (!this.isRegisterAddData) {
      this.layoutService.setShowComponentNewPublication(false);
      this.layoutService.setDataWishComponentNewPublication({});
    } else {
      this.authService.setShowComponentAddPublication(false);
    }

    this.ownerId = null;
    this.wishId =null;
    this.publication = null;
    this.isRegisterAddData = false;
    this.title = 'PUBLICATION.NEW_PUBLICATION';

    this.open.emit(false);
  }

  getVideoFromGallery(event: any) {
    this.recordVideoElement = this.recordVideoElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const inputElement = event.target as HTMLInputElement;
      const file = event.target.files[0];
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 50 * 1024 * 1024;
      if (fileSizeInBytes > maxSizeInBytes) {
        this.toastService.presentToast('PUBLICATION.TOAS_MAX_HEIGHT_VIDEO', 3000);
        inputElement.value = '';
      } else {
        this.recordVideoElement.src = URL.createObjectURL(file);
        this.recordVideoElement.onloadedmetadata = () => {
          const durationInSeconds = this.recordVideoElement.duration;
          const maxDurationInSeconds = 600;

          if (durationInSeconds > maxDurationInSeconds) {
            this.toastService.presentToast('PUBLICATION.TOAS_MAX_LARGE_VIDEO', 3000);

            inputElement.value = '';
          } else {
            this.isPlayVideo = true;
            this.videoTemp = file;
          }
        };

      }

    }
  }
  getAudioFromGallery(event: any) {
    this.recordAudioElement = this.recordAudioElementRef.nativeElement;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.recordAudioElement.src = URL.createObjectURL(file);
      this.isPlayAudio = true;
      this.audioTemp = file;
    }
  }

  getImageFromGallery(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const inputElement = event.target as HTMLInputElement;
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 15 * 1024 * 1024;
      if (fileSizeInBytes > maxSizeInBytes) {
        this.toastService.presentToast('PUBLICATION.TOAS_MAX_HEIGHT_IMAGE', 3000);
        inputElement.value = '';
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.imageForEdit = reader.result;
          this.isEditImage = true;
        });
        reader.readAsDataURL(file);
      }

    }
  }


  togglePublication(event) {
    const value = event.detail.checked;
    if (value ===  undefined || value === true) {
    this.selectActionToggle = true;
    } if (value === false) {
    this.selectActionToggle = false;
    }
  }

  playVideo(video: File) {
    this.recordVideoElement.src = URL.createObjectURL(video);
    this.isPlayVideo = true;
  }

  closePlayVideo() {
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
  }

  deleteVideo() {
    this.videoTemp = null;
    this.recordVideoElement.src = '';
    this.isPlayVideo = false;
  }

  deleteAudio() {
    this.audioTemp = null;
    this.recordAudioElement.src = '';
    this.isPlayAudio = false;
  }

  uploadVideo() {
    const formData = new FormData();
    formData.append('file', this.videoTemp);
    this.showLoading(2);
    this.publicationService.saveVideoRegister(formData).subscribe((res: any) => {
      const newVideo = {
        id: res?._id,
        title: res?.title,
        url: res?.videoUrl,
      };
      this.videosLoad.push(newVideo);
      const videos = this.formPublication.get('videos').value;
      videos.push(newVideo.id);
      this.formPublication.get('videos').setValue(videos);
      this.deleteVideo();
      this.loading.dismiss();
    },
      (err) => {
        this.loading.dismiss();
      });
  }

  uploadAudio() {
    const formData = new FormData();
    formData.append('file', this.audioTemp);
    this.showLoading(3);
    this.publicationService.saveAudio(formData).subscribe((res: any) => {
      const newAudio = {
        id: res?._id,
        title: res?.title,
        url: res?.audioUrl,
      };
      this.audiosLoad.push(newAudio);
      const audios = this.formPublication.get('audios').value;
      audios.push(newAudio.id);
      this.formPublication.get('audios').setValue(audios);
      this.deleteAudio();
      this.loading.dismiss();
    },
      (err) => {
        this.loading.dismiss();
      });
  }

  showOptionsVideo() {
    this.isOpenOptionsVideo = !this.isOpenOptionsVideo;
  }


  clickInputGallery() {
    this.inputGallery.nativeElement.value = '';
    this.isOpenOptionsVideo = false;
    this.inputGallery.nativeElement.click();
    this.toastService.presentToast('NOTIFICATION.ONLY_FORMAT_MP4', 3000);
  }

  clickInputGalleryAudio() {
    this.inputGalleryAudio.nativeElement.click();
  }

  clickInputGalleryImage() {
      this.inputGalleryImage.nativeElement.value = '';
    this.inputGalleryImage.nativeElement.click();
  }


  continueNextStep() {
    const stepsAddDataProfile = [
      'IMAGE_PROFILE',
      'IMAGE_COVER',
      'PRICE_SUBSCRIPTION',
      'PUBLICATIONS_FREE',
    ];

    this.authService.setRegisterProfile(stepsAddDataProfile);
  }

  showLoading(type: number) {
    let message;
    switch (type) {
      case 1:
        message = this.translateService.instant('NOTIFICATION.LOADING_IMAGE');
        break;

      case 2:
        message = this.translateService.instant('NOTIFICATION.LOADING_VIDEO');
        break;

      case 3:
        message = this.translateService.instant('NOTIFICATION.LOADING_AUDIO');
        break;

      default:
        message = this.translateService.instant('NOTIFICATION.LOADING_IMAGE');
        break;
    }
    const options = {
      message,
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

//   hideIcons(data, type: any) {
//     if (type === 1) {
//       if (data.length > 0) {
//         this.iconAudio = false;
//         this.iconVideo = false;
//       }
//     }
//     if (type === 2) {
//       if (data.length > 0) {
//         this.iconAudio = false;
//         this.iconCam = false;
//       }
//     }
//     if (type === 3) {
//       if (data.length > 0) {
//         this.iconCam = false;
//         this.iconVideo = false;
//       }
//     }

// return data;
//   }


  isRenderCheckIsPublic() {
    let show = true;


    if (this.ownerId) {
      show = false;
    }

    if (this.myProfile?.privateProfile) {
      show = false;
    }

    if (this.isRegisterAddData) {
      show = false;
    }

    return show;
  }

  /** Codigo para  grabar audio (NO PROBADO) */

  recordAudio() {
    /*navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        // Crear un objeto MediaStreamSource a partir del flujo de audio
        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream);

        // Conectar la fuente de audio a un nodo de procesamiento (opcional)
        // const processor = audioContext.createScriptProcessor();
        // source.connect(processor);
        // ...

        // Reproducir el audio capturado
        const bufferSource = audioContext.createBufferSource();
        bufferSource.buffer = source //audioBuffer; // reemplazar con los datos de audio capturados
        bufferSource.connect(audioContext.destination);
        bufferSource.start();
      })
      .catch((error) => {
        console.error('Error al obtener acceso al micrófono', error);
      });*/

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.start();

      mediaRecorder.stop();
      mediaRecorder.addEventListener('dataavailable', (event) => {
        const audioBlob = new Blob([event.data], { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(audioBlob);
      });
    });
  }



  private setFormPublicationDescription(description: string) {
    this.formPublication.get('description').setValue(description);
  }

  private setFormPublicationCategories(categories: any[]) {
    this.formPublication.get('categories').setValue(categories);
  }

  private setFormPublicationImages(images: any[]) {
    this.formPublication.get('images').setValue(images);
  }

  private setFormPublicationVideos(videos: any[]) {
    this.formPublication.get('videos').setValue(videos);
  }

  private setFormPublicationAudios(audios: any[]) {
    this.formPublication.get('audios').setValue(audios);
  }

  private setFormPublicationIsPublic(isPublic: boolean) {
    this.formPublication.get('isPublic').setValue(isPublic);
  }

  private deleteMedia(mediaId, type) {
    this.publicationService.deleteMedia(mediaId, type).subscribe((res) => {
      this.inputGalleryImage.nativeElement.value = '';
      this.inputGallery.nativeElement.value = '';
    });
  }
}
