import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCapitalizeFirstLetter]'
})
export class CapitalizeFirstLetterDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event.target.value'])
  onInput (value: string) {
    this.el.nativeElement.value = this.capitalizeFirstLetter(value);
  }

  capitalizeFirstLetter (value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
