import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatsService {
  private env = environment;
  private url: string = this.env.API_SERVICE_LL_CHAT + '/chat/';

  private viewMessagesChat = new BehaviorSubject<string>(null);
  private viewMessagesChat$ =
    this.viewMessagesChat.asObservable();

  constructor(private httpClient: HttpClient) { }

  getChats(page) {
    return this.httpClient.get(this.url+'?page='+page);
  }

  getChat(id: string, page, size) {
    const params = {
      page, size
    };

    return this.httpClient.get(this.url + id, { params });
  }

  getChatWithAdmin(userId: string, page?: number, size?: number) {
     const params = {
      page, size
    };
    return this.httpClient.get(this.url + 'chat-with-admin-app/' + userId,{params}
    );
  }

  viewChats(messagesId) {
    return this.httpClient.put(this.url + 'update-messages-view', { messagesId });
  }

  setReassonByAdmin(chatId, reassonByAdmin) {
    return this.httpClient.put(this.url + 'set-reasson-by-admin', { chatId, reassonByAdmin });
  }

  stopSexting(idRoom) {
    return this.httpClient.put(this.url + 'stop-sexting/', { idRoom });
  }

  setChatViewId(chatId: string) {
    this.viewMessagesChat.next(chatId);
  }

  getChatViewId() {
    return this.viewMessagesChat$;
  }

}
