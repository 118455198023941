import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout/layout.service';
import { MoviesService } from '../../services/movies/movies.service';
import { ToastService } from '../../services/toast/toast.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UsersService } from '../../services/users/users.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { SocketService } from '../../services/socket/socket.service';
import { WishService } from '../../services/wish/wish.service';
@Component({
  selector: 'app-add-news-videos',
  templateUrl: './add-news-videos.component.html',
  styleUrls: ['./add-news-videos.component.scss'],
})
export class AddNewsVideosComponent implements OnInit {

  @Input() ownerId: any | null = null;
  @Input() wishId: any = null;
  @Input() time: any = null;

  @ViewChild('inputGalleryImage') inputGalleryImage: ElementRef;
  imageForEdit: ArrayBuffer | string = '';
  imageLoad = '';
  videoLoad: any = '';
  myId = '';
  isEditImage = false;
  loading: HTMLIonLoadingElement;
  isPlayVideo = false;
  formMovie = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.maxLength(25)]),
    description: new FormControl('', [
      Validators.required,
      Validators.maxLength(280),
    ]),
    image: new FormControl('', Validators.required),
    video: new FormControl('', Validators.required),
    enabled: new FormControl(true),
    price: new FormControl(10, Validators.min(5))
  });


  constructor(
    private layoutService: LayoutService,
    private toastService: ToastService,
    private moviesService: MoviesService,
    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private sanitizer: DomSanitizer,
    private usersService: UsersService,
    private authService: AuthServiceService,
    private socketService: SocketService,
    private wishService: WishService,
  ) { }

  ngOnInit() {
    const user = JSON.parse(this.authService.getLocalStorage('user'));
    this.myId = user._id;
  }


   close() {
    this.layoutService.setDataShowModalAddNewVideos(false);
  }

  clickInputGalleryImage() {
    this.inputGalleryImage.nativeElement.click();
  }

  getImageFromGallery(event: any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const inputElement = event.target as HTMLInputElement;
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 15 * 1024 * 1024;
      if (fileSizeInBytes > maxSizeInBytes) {
        this.toastService.presentToast('PUBLICATION.TOAS_MAX_HEIGHT_IMAGE', 3000);
        inputElement.value = '';
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.imageForEdit = reader.result;
          this.isEditImage = true;
        });
        reader.readAsDataURL(file);
      }

    }
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // Eliminar caracteres no permitidos y mantener solo un punto
    inputValue = inputValue.replace(/[^\d.]/g, '');
    const parts = inputValue.split('.');
    if (parts.length > 1) {
      inputValue = parts[0] + '.' + parts.slice(1).join('');
    }

    // Asegurar que no haya ceros a la izquierda, excepto para el caso "0."
    if (inputValue.length > 1 && inputValue.startsWith('0')) {
      inputValue = inputValue.replace(/^0+/, '');
    }
    if (inputValue === '0') {
      inputValue = '1';
    }
  }

  setVideoPreview(video) {

    this.videoLoad = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(video));
  }

  uploadImage(image: string) {
    const imageToUpload = image;
    this.showLoading(1);

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const file = new File([fileReader.result], 'image.jpeg', {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', file);

      this.moviesService.saveImage(formData).subscribe((res: any) => {
        const newImage = {
          id: res._id,
          url: res.imageUrl,
        };

        this.formMovie.get('image').setValue(newImage.id);
        this.imageLoad = res.imageUrl;

        this.loading.dismiss();
        this.isEditImage = false;
      },
        (err) => {
          if (this.loading) {
            this.loading.dismiss();
          }

        });
    };

    fileReader.readAsArrayBuffer(
      new Blob([imageToUpload], { type: 'image/jpeg' })
    );
  }

  insertMovieSaved(video) {
    const newVideo = {
      id: video._id,
      url: video.videoUrl,
    };

    this.formMovie.get('video').setValue(newVideo.id);
  }


  saveMovie() {
    if (this.formMovie.invalid) {
      return;
    }

    const movie = this.formMovie.value;
    this.moviesService.saveMovie(movie.title, movie.description, movie.enabled, movie.price, movie.image, movie.video, this.ownerId, this.wishId).subscribe(async (res) => {
      this.layoutService.setDataShowModalAddNewVideos(false);
      this.layoutService.setNewMovie();
      if (this.ownerId) {
        this.authService.removeLocalStorage('movieForWish');
        this.layoutService.setDataWishComponentNewMovie(null);
        await this.acceptWish();
      } else {
        this.sendMultipleNotifications(movie._id);
      }
    });
  }

  async acceptWish() {
    const wish: any = this.wishService.getWish(this.wishId).toPromise();
    const payload = {
      wishId: this.wishId,
      accept: true,
      comment: '',
      isSexting: wish.title == 'Sexting'
    };

    await this.wishService.acceptOrRejectWish(payload).toPromise();
    this.layoutService.setUpdateStatus();
    this.layoutService.setShowModalWishDetail(false);
  }

  mergeNoDuplicates(arr1, arr2) {
    const mergedArray = [...arr1, ...arr2];
    const mergedUnique = mergedArray.reduce((noDuplicates, item) => {
      if (noDuplicates.includes(item)) {
        return noDuplicates;
      } else {
        return [...noDuplicates, item];
      }
    }, []);

    return mergedUnique;
  }

  sendMultipleNotifications(movieId: string) {

    Promise.all([
      this.usersService.getSellerFollowersList(this.myId).toPromise(),
      this.usersService.getMySubscribers().toPromise(),
    ])
      .then((results) => {
        let followers: any = results[0];
        let subscribers: any = results[1];
        followers = followers.map((item) => item.userClientId);
        subscribers = subscribers.map((item) => item.userClientId);
        const userReceivers = this.mergeNoDuplicates(followers, subscribers);

        const noti = {
          userReceivers,
          userSender: this.myId,
          type: 'post',
          contextId: movieId,
          linkRedirect: '/profile/' + this.myId,
        };
        this.socketService.sendNotificationMultiple(noti);
      })
      .catch((error) => {
        console.error('At least one promise rejected:', error);
      });
  }

  showLoading(type: number) {
    let message;
    switch (type) {
      case 1:
        message = this.translateService.instant('NOTIFICATION.LOADING_IMAGE');
        break;

      case 2:
        message = this.translateService.instant('NOTIFICATION.LOADING_VIDEO');
        break;

      case 3:
        message = this.translateService.instant('NOTIFICATION.LOADING_AUDIO');
        break;

      default:
        message = this.translateService.instant('NOTIFICATION.LOADING_IMAGE');
        break;
    }
    const options = {
      message,
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }

}
