// eslint-disable-next-line max-len
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsersService } from '../../services/users/users.service';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { PublicationService } from '../../services/publication/publication.service';
import { LayoutService } from '../../services/layout/layout.service';
import { AccountsService } from '../../services/accounts/accounts.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { SocketService } from '../../services/socket/socket.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from '../../services/file/file.service';

@Component({
  selector: 'app-plastt-gallery',
  templateUrl: './plastt-gallery.component.html',
  styleUrls: ['./plastt-gallery.component.scss'],
})
export class PlasttGalleryComponent implements OnInit ,OnDestroy,OnChanges,AfterViewInit{
  @ViewChild('gallery') gallery: ElementRef;
  @ViewChildren('snapBox') snapBoxes!: QueryList<ElementRef>;
  @ViewChildren('snapBox') snapBoxElements!: QueryList<ElementRef>;
  @Input() images = [];
  @Output() imageSelected = new EventEmitter<any>();
  @Output() windowComment = new EventEmitter<any>();
  @Output() requestMoreData = new EventEmitter<void>();
  @Output() toggleStateContainer = new EventEmitter<boolean>();


    observer: IntersectionObserver;
    intersectionObserver: IntersectionObserver;
    activeIndex = 0;
    username = '';
    isModalOpen = false;
    isModalReport = false;
    typeReport ='report-publication';
    myId = '';
    userId = '';
    priceForSubscription = 0;
    sharedSubscription = 0;
    loading: HTMLIonLoadingElement;
    messageSubscription = {};


    moreOptionOpen = false;
    closeOption = null;
    imgSelected: any = undefined;

    followings = [];
    subscribers = [];
    userType = false;

    indexImg: null | number = null;
    reasonReportData ='';

    myCash = 0;
    sharePublication = false;
    copyActive = false;
    linkRedirect: string;
    titleModal = '';
    authorId = '';
    videoPlay = true;
    showModalUnsucribe = false;
    currentVideoIndex: any;
    idCommentsPublication: any = null;

    subscriptionViewAllStatusUser: Subscription;
    subscriptionListenerNewStates: Subscription;
    usersAllViewStates = [];
    fullScreen = false;


    subscriptionFollowers: Subscription;
    subscriptionSubscribers: Subscription;

    isDeviceIOS = false;

    activeUsers: any = [];
    visibilityStatus: boolean[] = [];
    elementVisibilityStatus: boolean[] = [];
    activeUserSubscription: Subscription;

    aspectoRatio = 16 / 9;

    currentSlideIndex = 0;
    showStateContainer= false;
    private env = environment;

 constructor(
    private usersService: UsersService,
    private authService: AuthServiceService,
    private publicationService: PublicationService,
    private layoutService: LayoutService,

    private translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private router: Router,
    private toastService: ToastService,
    private socketService: SocketService,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef

  ) {
    this.intersectionObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      const elementIndex = this.snapBoxElements.toArray().findIndex(
        (element) => element.nativeElement === entry.target
      );

      const isElementVisible = entry.isIntersecting;

      // Si la visibilidad del índice cambia, actualizamos `currentVideoIndex`
      if (this.elementVisibilityStatus[elementIndex] !== isElementVisible) {
        this.elementVisibilityStatus[elementIndex] = isElementVisible;

        if (isElementVisible) {
          this.currentVideoIndex = elementIndex;
          this.showStateContainer = elementIndex === 0;

          this.toggleStateContainer.emit(this.showStateContainer);

          // // Logs para verificar el comportamiento
          //  console.log(`Imagen en el índice ${elementIndex} es visible.`);
          //  console.log(`Contenedor de estados ${this.showStateContainer ? 'visible' : 'oculto'}.`);
        } else if (this.currentVideoIndex === elementIndex) {
          this.currentVideoIndex = null;
          //  console.log(`Imagen en el índice ${elementIndex} ya no es visible.`);
        }
      }
    });
  },
  { threshold: 0.5 }
);
  }

   ngOnDestroy(): void {
    if (this.intersectionObserver ) {
       this.intersectionObserver.disconnect();
    }
     if (this.observer) {
      this.observer.disconnect();
    }
    if (this.subscriptionViewAllStatusUser) {
      this.subscriptionViewAllStatusUser.unsubscribe();
    }
    if (this.subscriptionListenerNewStates) {
      this.subscriptionListenerNewStates.unsubscribe();
    }
    if (this.subscriptionFollowers) {
      this.subscriptionFollowers.unsubscribe();
    }
    if (this.subscriptionSubscribers) {
      this.subscriptionSubscribers.unsubscribe();
    }
    if (this.activeUserSubscription) {
      this.activeUserSubscription.unsubscribe();
    }
    if (this.gallery) {
      this.renderer.listen(this.gallery.nativeElement, 'scroll', null);
    }
   }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.images.currentValue) {
        this.images.map(img => img.quantityComments = img.comments.length);
      }
       if (changes.images && this.gallery) {
        this.scrollToImage(this.activeIndex);
      }
    }

    getWidthGallery(): number {
      return this.gallery?.nativeElement?.offsetWidth ? this.gallery?.nativeElement?.offsetWidth : 300;
    }


   setupObservers() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = this.snapBoxes.toArray().findIndex(
            (snapBox) => snapBox.nativeElement === entry.target
          );

          const isVisible = entry.isIntersecting;
          if (this.visibilityStatus[index] !== isVisible) {
            this.visibilityStatus[index] = isVisible;
          }
        });
      },
      { threshold: 0.5 }
    );

    this.snapBoxes.forEach((snapBox, index) => {
      this.visibilityStatus[index] = false;
      observer.observe(snapBox.nativeElement);
    });
  }

  ngOnInit() {
       this.initIntersectionObserver();
    this.isDeviceIOS = this.authService.isDeviceIOS;
    const user = this.authService.getLocalStorage('user');
    const userParsed = JSON.parse(user);
    this.myId = userParsed._id;
    if (userParsed.contentManagerId) {
      this.myId = userParsed.contentManagerId;
    }

    this.subscriptionFollowers =this.usersService.getMyFollowing().subscribe((res: any) => {
      this.followings = res;
    });
    this.subscriptionSubscribers = this.usersService.getSubscriptions().subscribe((res: any) => {
      this.subscribers = res.map(subs => {
        if (subs.userSellerId[0]?._id) {
          return subs.userSellerId[0]._id;
        } else {
          return subs.userSellerId;
        }
      });
    });
    this.layoutService
      .getShowModalCommentFrame()
      .subscribe((val) => {
        if (val === false && this.idCommentsPublication) {
          const index = this.images.findIndex(img => img.id === this.idCommentsPublication);
          if (this.layoutService.quantityComments !== this.images[index].quantityComments) {
            this.images[index].quantityComments = this.layoutService.quantityComments;
          }
          this.idCommentsPublication = null;
        }
      });



    this.subscriptionViewAllStatusUser = this.layoutService.getNewUserViewAllStates().subscribe((usersAllViewStates: string[]) => {
      this.usersAllViewStates = usersAllViewStates;
    });

    this.subscriptionListenerNewStates = this.socketService.listenerNewStatesMySubscriptions().subscribe((state: any) => {
      const publicationsByUser = this.images.filter(pub => pub.authorId
        === state.userId);

      for (const pub of publicationsByUser) {
        const indexPub = this.images.findIndex(img => img.id === pub.id);
        this.images[indexPub].states.push(state);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.activeUserSubscription = this.usersService.getUsersActive().subscribe((user: any) => {
      this.activeUsers = user;
    });
  }

  getClassByNumber(value: number): string {
    if (value >= 3 && value < 4) {
      return 'yellow';
    } else if (value >= 4 && value < 5) {
      return 'orange';
    } else if (value >= 5 && value < 6) {
      return 'red';
    } else if (value === 6) {
      return 'green';
    }
    return '';
  }
  formatImageProfile(image) {
    return !image?.imageProfile?.imageUrl
      ? '../../../../assets/icon/usuario-30.png'
      : image.imageProfile.imageUrl;
  }

  initIntersectionObserver() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.checkIndex();
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );
    const snapBoxes = this.elRef.nativeElement.querySelectorAll('.snap-box');
    snapBoxes.forEach((box: HTMLElement) => this.observer.observe(box));
  }
  ngAfterViewInit(): void {
    this.observeSnapBoxElements();
     this.cdr.detectChanges(); // Forzar la detección de cambios

    this.snapBoxElements.changes.subscribe(() => {
      this.observeSnapBoxElements();
    });

 this.observeLastBox();
    this.snapBoxes.changes.subscribe(() => {
      this.observeLastBox();
    });
  }
   observeSnapBoxElements() {
     this.snapBoxElements.forEach((element, index) => {
      if (!this.elementVisibilityStatus[index]) {
        this.elementVisibilityStatus[index] = false;
        this.intersectionObserver.observe(element.nativeElement);
      }
    });
   }

  unfollow(publication) {
  const userSellerId = publication.userId._id;
  // Actualización optimista de `followings`
  this.followings = this.followings.filter(id => id !== userSellerId);

  this.usersService.unfollow({ userSellerId }).subscribe(
    () => {
    },
    (err) => {
      console.error(err);
      // Revertir la eliminación si hay un error
      this.followings.push(userSellerId);
    }
  );
}


follow(publication) {
  const userSellerId = publication.userId._id;
  // Actualización optimista de `followings`
  this.followings.push(userSellerId);

  this.usersService.follow({ userSellerId }).subscribe(
    () => {
      this.usersService.registerFollowedByPublication(this.myId, publication.userId._id, publication.id).subscribe();
    },
    (err) => {
      console.error(err);
      // Revertir si hay un error
      this.followings = this.followings.filter(id => id !== userSellerId);
    }
  );
}

  closeModal(i: number) {
    if (i) {
      this.indexImg = i;
    }
    this.moreOptionOpen = false;
  }

   openModalReportPublication() {
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'report-publication';
    this.moreOptionOpen = false;
  }

   reasonReport(data) {
    this.reasonReportData = data;
  }
  confirmModal() {


      switch (this.typeReport) {
        case 'report-profile':
          this.reportProfile();
          break;
        case 'report-publication':
          this.reportPublication();
          break;
        case 'hidden-publication':
          this.hiddenPublication();
          break;
        case 'unsuscribe':
          this.unSuscribe();
          break;
        default:
          break;
      }

  }

   showLoading() {
    const options = {
      message: this.translateService.instant('NOTIFICATION.LOADING'),
      cssClass: 'custom-loading',
    };
    this.loadingCtrl.create(options).then((res: HTMLIonLoadingElement) => {
      this.loading = res;
      this.loading.present();
    });
  }
   unSuscribe() {
     this.showLoading();
      this.usersService.unsubscribe(this.authorId).subscribe(() => {
      this.loading.dismiss();
      this.isModalOpen = false;
      this.showModalUnsucribe = true;
    },
      (err) => {
        this.loading.dismiss();
      });
  }


    hiddenPublication() {
    this.usersService.excludePublication(this.imgSelected.id).subscribe((res) => {
      this.images = this.images.filter(image => image.id !== this.imgSelected.id);
      this.toastService.presentToast('NOTIFICATION.HIDDEN_PUBLICATION', 2000);
      this.closeModal(this.indexImg);
      this.moreOptionOpen = false;
      this.isModalOpen = false;
      this.isModalReport = false;

    });
  }

  reportPublication() {
    this.usersService.report(this.imgSelected.authorId,this.reasonReportData, this.imgSelected.id).subscribe((res) => {

      this.toastService.presentToast('NOTIFICATION.REPORT_PUBLICATION', 2000);
      this.closeModal(this.indexImg);
      this.moreOptionOpen = false;
      this.isModalOpen = false;
      setTimeout(() => {
        this.openModalHiddenPublication();
      }, 500);
    });
  }

  reportProfile() {
    this.usersService.report(this.imgSelected.authorId,this.reasonReportData).subscribe((res) => {
      this.toastService.presentToast('NOTIFICATION.REPORT_PROFILE', 2000);
      this.closeModal(this.indexImg);
      this.moreOptionOpen = false;
      this.isModalOpen = false;
      setTimeout(() => {
        this.openModalHiddenPublication();
      }, 500);
    });
  }


  openModalReportProfile() {
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'report-profile';
    this.moreOptionOpen = false;
  }

  openModalHiddenPublication() {
    this.isModalOpen = true;
    this.isModalReport = true;
    this.typeReport = 'hidden-publication';
    this.moreOptionOpen = false;
  }
  onOpenModal(i: number) {
    this.indexImg = i;
    this.imgSelected = this.images[i];
    this.moreOptionOpen = true;
    this.copyActive = false;
  }

  openModalComments(publication) {
    this.layoutService.setShowModalCommentFramePlastt(true);
    this.layoutService.setDataCommentFramePlastt(publication);
    this.idCommentsPublication = publication.id;
    }

  showModalCopy(i: any) {
    this.imgSelected = this.images[i];
    this.sharePublication = true;
    this.linkRedirect = this.env.urlFront + 'preview/' + this.imgSelected.id;
  }

  changeLikesStatus(image) {
    const action = image.plasttpublicationLikes.includes(this.myId) ? 'dislikePlastt' : 'likePlastt';
  this.publicationService[action](image.id).subscribe((res: any) => {
    image.likes = res.likes;
    image.plasttpublicationLikes = res.plasttpublicationLikes.usersId;

    const index = this.images.findIndex(img => img.id === image.id);
    if (index !== -1) {
      this.images[index].likes = res.likes;
      this.images[index].plasttpublicationLikes = res.plasttpublicationLikes.usersId;
    }
  });
}
  checkIndex() {
  this.requestMoreData.emit();
  }
  observeLastBox() {
     if (this.snapBoxes.length > 0) {
      const boxesArray = this.snapBoxes.toArray();
      const lastBox = boxesArray[boxesArray.length - 1].nativeElement;
      this.observer.disconnect();
      this.observer.observe(lastBox);
    }
   }


  initScrollSnapping() {
    this.renderer.listen(this.gallery.nativeElement, 'scroll', () => {
      const container = this.gallery.nativeElement;
      const scrollTop = container.scrollTop;
      const containerHeight = container.offsetHeight;
      const newIndex = Math.round(scrollTop / containerHeight);
      this.activeIndex = Math.max(0, Math.min(newIndex, this.images.length - 1));
      this.scrollToImage(this.activeIndex);
    });
  }

   scrollToImage(index: number) {
    const container = this.gallery.nativeElement;
    const containerHeight = container.offsetHeight;

    const targetScrollTop = index * containerHeight;

    container.scrollTo({
      top: targetScrollTop,
      behavior: 'smooth',
    });
  }

  closeSharedPublicationModal() {
    this.sharePublication = false;
    this.moreOptionOpen = false;
  }

  copySharedProfile(value) {
    this.copyActive = true;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
     this.toastService.presentToast('NOTIFICATION.COPIED_CLIPBOARD', 2000);
  }

   getSafeLink(url: any): SafeUrl {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    snapIndexChange(event: any) {
    this.currentVideoIndex = event[0].snapIndex;
    const activeIndex = event[0].snapIndex;
    this.currentSlideIndex = event.snapIndex;
    if (activeIndex >= 0) {
      const activeSlide: HTMLElement = event[0].slides[activeIndex];
      const assetElement = activeSlide.querySelector('audio') ? activeSlide.querySelector('audio') : '';

      if (assetElement) {
        setTimeout(() => {
          assetElement.removeAttribute('hidden');
        }, 500);
      }
    }
    }

  navigateTo(data) {
    if (data.userType === 1) {
        this.router.navigate(['/profile', data.userId._id]);
        this.usersService.registerViewProfileByPublication(this.myId, data.userId._id, data.id).subscribe();
        return; // Detenemos la ejecución aquí
    }

    if (data.userType === 2) {
        if (data.userId._id === this.myId) {
            this.router.navigate(['/settings']);
            return; // Detenemos la ejecución aquí
        } else {
            this.toastService.presentToast('GENERAL.PROFILE_NOT_PUBLIC', 2000);
            return; // Detenemos la ejecución aquí
        }
    }
}
}
