import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceOffertService {
  private env = environment;


  constructor(private httpClient: HttpClient) { }

  // getOfferts() {
  //   return this.httpClient.get(this.url);
  // }

  // getOffertsByFolder(page?: number, size?: number) {
  //   const params = {
  //     page,
  //     size
  //   };
  //   return this.httpClient.get(this.url + '/folder', { params });
  // }

  // getOffertDetail(offertId: string) {
  //   return this.httpClient.get(this.url +'/' + offertId);
  // }

  // createOffert(offert) {
  //   return this.httpClient.post(this.url, offert);
  // }

  // addNewCommentOffert(data) {
  //   return this.httpClient.put(this.url+'/add-comment', data);
  // }

  // updatePriceOffert(data) {
  //   return this.httpClient.put(this.url+'/update-price', data);
  // }

  // processOffert(data) {
  //   return this.httpClient.put(this.url + '/process', data);
  // }
}
