import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../service/auth-service.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class VerifyGuardGuard implements CanLoad {
  constructor(
    private router: Router,
    private authService: AuthServiceService
  ) {}

  canLoad (): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isVerify() || window.location.href.includes('/auth/login/add-data-profile/') || window.location.href.includes('profile-preview')) {
      return true;
    } else {
      this.router.navigate(['/auth/verify']);
      return false;
    }
  }
}
