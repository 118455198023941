import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from 'src/app/features/auth/service/auth-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicationService {
  private env = environment;
  private url = `${this.env.apiAuth}/publications`;
  // private urlCategory = this.env.API_SERVICE_LL_CATEGORY;
  private urlMetrics = this.env.API_SERVICE_LL_METRICS;
  private urlPublication = this.env.API_SERVICE_LL_PUBLICATION;

  constructor(private httpClient: HttpClient, private authService: AuthServiceService) {}

  getPublicPublications(categories: string[], page: number, preferences, isExplore: boolean) {
    const queryCategories =
      categories.length > 0
        ? '?categories=' + categories.toString() + '&page=' + page+'&size=' +5
        : '?page=' + page + '&size=' + 5 ;
    const queryPreferences = preferences ? '&preferences=' + JSON.stringify(preferences) : '';
    // eslint-disable-next-line max-len
    return this.httpClient.get(`${this.urlPublication}/publication/public` + queryCategories + queryPreferences + '&isExplore=' + isExplore);
  }

  getPublication(id) {
    return this.httpClient.get(`${this.urlPublication}/publication/${id}`);
  }

  getAvailableNewPubllicaction() {
    const id =  this.authService.getLocalStorage('userId');
    return this.httpClient.get(this.urlPublication +'/publication/is-avaliable-by-new-publication/' + id);
  }

  searchPublication(id) {
    return this.httpClient.get(`${this.urlPublication}/publication/search/${id}`);
  }

  saveImage(payload) {
    return this.httpClient.post(`${this.env.urlServiceFile}/upload-image`, payload);
  }
   saveImageRegister(payload) {
    return this.httpClient.post(`${this.env.serviceFile}/upload-image`, payload);
  }


  saveAudio(payload) {
    return this.httpClient.post(`${this.env.urlServiceFile}/upload-audio`, payload);
  }

  saveVideo(payload) {
    return this.httpClient.post(`${this.env.urlServiceFile}/upload-video`, payload);
  }
   saveVideoRegister(payload) {
    return this.httpClient.post(`${this.env.serviceFile}/upload-video`, payload);
  }

  deleteMedia(mediaId, type) {
    return this.httpClient.delete(`${this.urlPublication}/publication/delete/` + mediaId);
  }

  savePublication(publication, token?) {
    let params = {};
    if (token) {
      params = { token };
    }

    return this.httpClient.post(`${this.urlPublication}/publication/add`, publication, { params });
  }

  saveTextPublication(publication) {
    return this.httpClient.post(`${this.urlPublication}/publication/text-publication`, publication);
  }

  savePublicationForWish(publication, wishId: string) {
    return this.httpClient.post(
      this.env.API_SERVICE_LL_WISH + '/wish/create-publication-of-wish/' + wishId,
      publication
    );
  }

  addCommentPublication(comment, id) {

    return this.httpClient.post(`${this.urlPublication}/comment/add-comment/` + id, comment);
  }

  deleteCommentPublication(idPublication, idComment) {
    return this.httpClient.delete(`${this.urlPublication}/comment/delete/${idPublication}/${idComment}`);
  }

  editComment(id, comment) {
    return this.httpClient.put(`${this.urlPublication}/comment/update/` + id, comment);
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  getPublicationsUser(id: string, userId?, page?, size?) {
    let params: any = {};
    if (userId) {
      params ={ user: userId};
    }

    if (page !== undefined && size) {
      params.page = page;
      params.size = size;
    }

    return this.httpClient.get(`${this.urlPublication}/publication/user-publications/` + id, { params });
  }


  getPublicationsPrivateUser(id: string, userId?, page?, size?) {
    let params: any = {};
    if (userId) {
      params = { user: userId };
    }
    if (page !== undefined && size) {
      params.page = page;
      params.size = size;
    }
    return this.httpClient.get(`${this.urlPublication}/publication/user-publications-private/${id}`, { params });
  }

  // getCategories(page?: number, size?: number) {
  //   return this.httpClient.get(this.urlCategory + '/category');
  // }

  getMyGallery(page) {
    return this.httpClient.get(`${this.urlPublication}/publication/my-gallery?page=${page}`);
  }

  like(id: string) {
    return this.httpClient.put(`${this.urlPublication}/publication-like/like/${id}`, {});
  }

  dislike(id: string) {
    return this.httpClient.put(`${this.urlPublication}/publication-like/dislike/${id}`, {});
  }

  /**
   * Guarda los cambios de una publicación editada
   *
   * @param id El id del la publicacion a guardar el cambio
   * @param payload
   * @returns
   */
  saveEditPublication(id: string, payload: any) {
    return this.httpClient.put(`${this.urlPublication}/publication/edit/${id}`, payload);
  }

  /**
   * Elimina un deseo
   *
   * @param id del deseo a eliminar
   * @returns un array de deseos sin el deseo eliminado
   */
  deletePublication(id: string) {
    return this.httpClient.delete(`${this.urlPublication}/publication/delete/${id}`);
  }

  getMetricsPublication(publicationId: string) {
    return this.httpClient.get(`${this.urlMetrics}/metrics/metrics-publication/${publicationId}`);
  }

  getCommentsPublication(publicationId: string) {
    return this.httpClient.get(`${this.urlPublication}/publication/comments/${publicationId}`);
  }

  getPublicationsByOwnerId(page, size) {
    return this.httpClient.get(`${this.urlPublication}/publication/owner`);
  }
}
