import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WishService {
  private env = environment;
  private urlWish = this.env.API_SERVICE_LL_WISH;

  constructor(private httpClient: HttpClient) {}

  createWish(payload) {
    return this.httpClient.post(this.urlWish + '/wish/create', payload);
  }

  addReviewWish(userClientId, userSellerId, rating, comentary, wish) {
    return this.httpClient.post(this.urlWish + '/wish/add-review', { userClientId, userSellerId, rating, comentary, wish });
  }

  getWish(id) {
    return this.httpClient.get(this.urlWish + '/wish/wish/' + id);
  }

  getModelMetrics(userId) {
    return this.httpClient.get(this.urlWish + '/wish/model-metrics/' + userId);
  }

  getAllMetricsByClient(userId) {
    return this.httpClient.get(this.urlWish + '/wish/client-metrics/' + userId);
  }

  getReviewByWish(id) {
    return this.httpClient.get(this.urlWish + '/wish/wish-review/' + id);
  }

  getWishList(page, size, asClient?: boolean, status?: number) {
    const params = {
      page, size, asClient, status
    };
    return this.httpClient.get(this.urlWish + '/wish/list', { params });
  }

  getWishListFolder(page, size) {
    const params = {
      page,
      size
    };
    return this.httpClient.get(this.urlWish + '/wish/list-folder', { params });
  }

  acceptOrRejectWish(payload) {
    return this.httpClient.put(
      this.urlWish + '/wish/wish-response',
      payload
    );
  }

  accepOrRejectForClientWish(payload) {
    return this.httpClient.put(
      this.urlWish + '/wish/wish-response-client',
      payload
    );
  }

  openSextingByWish(wishId) {
    return this.httpClient.put(
      this.urlWish + '/wish/open-sexting-by-wish',
      { wishId }
    );
  }

  /**
   * Elimina un deseo
   *
   * @param id del deseo a eliminar
   * @returns un array de deseos sin el deseo eliminado
   */
  deleteWish(id: string) {
    return this.httpClient.delete(`${this.urlWish}/wish/${id}`);
  }
}
