import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'slider-range',
  templateUrl: './slider-range.component.html',
  styleUrls: ['./slider-range.component.scss']
})
export class SliderRangeComponent implements OnChanges {

  @Input('min') min:number = 0;
  @Input('max') max:number = 100;
  @Input('step') step:number = 1;

  @Input('valueMin') valueMin:any = 0;
  @Output('valueMinChange') valueMinChange:EventEmitter<number> = new EventEmitter();

  @Input('valueMax') valueMax:any = 100;
  @Output('valueMaxChange') valueMaxChange: EventEmitter<number> = new EventEmitter();  
  @Input('maxDuration') maxDuration: any;

  slide1 = 0;
  slide2 = 0;

  change = '';

  constructor() { }
  ngOnChanges (changes: SimpleChanges): void {
    if (changes.max.currentValue) {
      this.slide2 = this.max
    }
  }

  ngAfterViewInit(): void {
    var sliderSections = document.getElementsByClassName("range-slider");
    for( var x = 0; x < sliderSections.length; x++ ){
      var sliders = sliderSections[x].getElementsByTagName("input");
      for( var y = 0; y < sliders.length; y++ ){
        if( sliders[y].type === "range" ){
          let self = this;
          sliders[y].oninput = function(){
            self.getVals(this);
          };
          // Manually trigger event first time to display values
          sliders[y].oninput(null);
        }
      }
    }
  }

  onSliderChangeMin () {
    this.change ="min";
  }
  onSliderChangeMax () {
    this.change = "max";
  }


  getVals(self) {
    // Get slider values    
    var parent = self.parentNode;
    var slides = parent.getElementsByTagName("input");
    var slide1 = parseFloat(slides[0].value);
    var slide2 = parseFloat(slides[1].value);
    this.valueMax = slide2;
    this.valueMin = slide1;

    if (this.valueMax - this.valueMin > this.maxDuration) {
      if (this.change == "max") {
        this.valueMin = this.valueMax - this.maxDuration;
      }
      
      if (this.change == "min") {
        this.valueMax = this.valueMin + this.maxDuration;
      }
      
    }
    // Neither slider will clip the other, so make sure we determine which is larger
    if( slide1 > slide2 ){ var tmp = slide2; slide2 = slide1; slide1 = tmp; }
        this.valueMinChange.emit(slide1);
        this.valueMaxChange.emit(slide2);
        this.slide1 = slide1;
        this.slide2 = slide2;
  }
}
